import React from 'react';
//import { useParams } from 'react-router-dom';

//import { Link } from "react-router-dom";
import { Typography, Stack } from '@mui/material';
//import { Backdrop } from '@mui/material';

import TravelExploreIcon from '@mui/icons-material/TravelExplore';


//import CustomCountdown from './CustomCountdown';

const NotFoundCompo = (props) => {

    return (
        <header className="App-header">
            <Stack direction="row" alignItems="center" gap={1}>
                <TravelExploreIcon sx={{ fontSize: 200 }}/>
                <Typography variant="h5" align="left">
                    Oops... You don't have access to this page!
                </Typography>
            </Stack>
        </header>
    );
};

export default NotFoundCompo;