import React, { PureComponent } from 'react';
//import { useParams } from 'react-router-dom';

import { Box, Typography, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

import StarIcon from '@mui/icons-material/Star';
import CircleIcon from '@mui/icons-material/Circle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FastForwardIcon from '@mui/icons-material/FastForward';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import BarChartIcon from '@mui/icons-material/BarChart';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import BlockIcon from '@mui/icons-material/Block';
import LoopIcon from '@mui/icons-material/Loop';

import {
    ScatterChart,
    Scatter,
    XAxis,
    YAxis,
    ZAxis,
    CartesianGrid,
    ResponsiveContainer,
} from 'recharts';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 50,
    borderRadius: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 400 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#292929' : '#308fe8',
    },
}));

const AdminHome = (props) => {

    const { sameNetwork, revealOption, presaleOption, saleOption, txLoading, reservedAmount, presaleIsActive, presaleIsOver, saleIsActive, saleIsOver, revealIsOver, totalSupply, pricePerToken, maxSupply, mintData, chain_name, chain_img, network } = props;

    const RenderNoShape = (props) => {
        return null;
    }

    function formatXAxis(value) {
        //console.log(value*24*3600)
        var date = new Date(value * 24 * 3600 * 1000)
        //console.log(date)
        return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    }

    class CustomizedAxisTick extends PureComponent {
        render() {
            const { x, y, payload } = this.props;

            var val = formatXAxis(payload.value)

            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={0} y={0} dy={16} fontSize={10} textAnchor="end" fill="#666" transform="rotate(-35)">
                        {val}
                    </text>
                </g>
            );
        }
    }

    return (
        <div>
            <Box>

                {sameNetwork ?
                    <></>
                    :
                    <Box sx={{
                        borderRadius: '5px',
                        boxShadow: 0,
                        paddingTop: 1,
                        paddingBottom: 1,
                        paddingRight: 1,
                        paddingLeft: 1,
                        margin: 3,
                        backgroundColor: '#000',
                        color: "#fff"
                        }}>
                            <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
                                <LoopIcon style={{ color: "#fff" }} />
                                <Typography style={{ color: "#fff" }} variant="button">switch network to {chain_name[network]}</Typography>
                            </Stack>
                        </Box>
                }



                <Box sx={{
                    borderRadius: '5px',
                    boxShadow: 0,
                    paddingTop: 1,
                    paddingBottom: 1,
                    paddingRight: 1,
                    paddingLeft: 1,
                    margin: 3,
                    backgroundColor: '#F5F5F5'
                }}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <FastForwardIcon style={{ color: "#000" }} />
                        <Typography style={{ color: "#000" }} variant="button">mint steps</Typography>
                    </Stack>

                    <Grid sx={{ marginTop: 1, marginBottom: 1, paddingLeft: 3, paddingRight: 3 }} container direction="row" justifyContent="center" alignItems="center" spacing={1} columns={3}>

                        {presaleOption ?
                            <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={3}>

                                <Grid sx={{ marginLeft: 1, marginRight: 1, border: 2, borderRadius: 0, borderColor: '#666' }} container direction="row" justifyContent="center" alignItems="center" spacing={1} columns={9}>
                                    <Grid sx={{ borderRight: 1, borderColor: 'grey.600', backgroundColor: "grey.600" }} item md={5} xs={9}>
                                        <Stack justifyContent="center" direction="row" alignItems="center" gap={1}>
                                            <StarIcon style={{ color: "#fff" }} />
                                            <Typography style={{ color: "#fff" }} variant="h6">PRESALE</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={4} xs={9}>
                                        {presaleIsActive ?
                                            <Stack direction="row" justifyContent="center" alignItems="center" gap={0}>
                                                <PlayArrowIcon style={{ color: "#000" }} />
                                                <Typography style={{ color: "#000" }} variant="h6">ON</Typography>
                                            </Stack>
                                            :
                                            <>
                                                {presaleIsOver ?
                                                    <Stack direction="row" justifyContent="center" alignItems="center" gap={0}>
                                                        <BlockIcon style={{ color: "#888" }} />
                                                        <Typography style={{ color: "#888" }} variant="h6">OVER</Typography>
                                                    </Stack>
                                                    :
                                                    <Stack direction="row" justifyContent="center" alignItems="center" gap={0}>
                                                        <NavigateNextIcon style={{ color: "#000" }} />
                                                        <Typography align="center" style={{ color: "#000" }} variant="h6">NEXT</Typography>
                                                    </Stack>
                                                }
                                            </>
                                        }
                                    </Grid>
                                </Grid>

                            </Grid>
                            :
                            <></>
                        }

                        {saleOption ?
                            <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={3}>
                                <Grid sx={{ marginLeft: 1, marginRight: 1, border: 2, borderRadius: 0, borderColor: '#666' }} container direction="row" justifyContent="center" alignItems="center" spacing={1} columns={9}>
                                    <Grid sx={{ borderRight: 1, borderColor: 'grey.600', backgroundColor: "grey.600" }} item md={5} xs={9}>
                                        <Stack justifyContent="center" direction="row" alignItems="center" gap={1}>
                                            <CircleIcon style={{ color: "#fff" }} />
                                            <Typography style={{ color: "#fff" }} variant="h6">SALE</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={4} xs={9}>
                                        {saleIsActive ?
                                            <Stack direction="row" justifyContent="center" alignItems="center" gap={0}>
                                                <PlayArrowIcon style={{ color: "#000" }} />
                                                <Typography style={{ color: "#000" }} variant="h6">ON</Typography>
                                            </Stack>
                                            :
                                            <>
                                                {saleIsOver ?
                                                    <Stack direction="row" justifyContent="center" alignItems="center" gap={0}>
                                                        <BlockIcon style={{ color: "#888" }} />
                                                        <Typography style={{ color: "#888" }} variant="h6">OVER</Typography>
                                                    </Stack>
                                                    :
                                                    <>
                                                        {presaleIsOver ?
                                                            <Stack direction="row" justifyContent="center" alignItems="center" gap={0}>
                                                                <NavigateNextIcon style={{ color: "#000" }} />
                                                                <Typography style={{ color: "#000" }} variant="h6">NEXT</Typography>
                                                            </Stack>
                                                            :
                                                            <Stack direction="row" justifyContent="center" alignItems="center" gap={0}>
                                                                <KeyboardDoubleArrowRightIcon style={{ color: "#888" }} />
                                                                <Typography style={{ color: "#888" }} variant="h6">SOON</Typography>
                                                            </Stack>
                                                        }
                                                    </>
                                                }
                                            </>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            :
                            <></>
                        }

                        {revealOption ?
                            <Grid sx={{ borderColor: 'grey.600' }} item md={1} xs={3}>

                                <Grid sx={{ marginLeft: 1, marginRight: 1, border: 2, borderRadius: 0, borderColor: '#666' }} container direction="row" justifyContent="center" alignItems="center" spacing={1} columns={9}>
                                    <Grid sx={{ borderRight: 1, borderColor: 'grey.600', backgroundColor: "grey.600" }} item md={5} xs={9}>
                                        <Stack justifyContent="center" direction="row" alignItems="center" gap={1}>
                                            <VisibilityIcon style={{ color: "#fff" }} />
                                            <Typography style={{ color: "#fff" }} variant="h6">REVEAL</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={4} xs={9}>
                                        {revealIsOver ?
                                            <Stack direction="row" justifyContent="center" alignItems="center" gap={0}>
                                                <BlockIcon style={{ color: "#888" }} />
                                                <Typography style={{ color: "#888" }} variant="h6">OVER</Typography>
                                            </Stack>
                                            :
                                            <>
                                                {saleIsOver && presaleIsOver ?
                                                    <Stack direction="row" justifyContent="center" alignItems="center" gap={0}>
                                                        <NavigateNextIcon style={{ color: "#000" }} />
                                                        <Typography style={{ color: "#000" }} variant="h6">NEXT</Typography>
                                                    </Stack>
                                                    :
                                                    <Stack direction="row" justifyContent="center" alignItems="center" gap={0}>
                                                        <KeyboardDoubleArrowRightIcon style={{ color: "#888" }} />
                                                        <Typography style={{ color: "#888" }} variant="h6">SOON</Typography>
                                                    </Stack>
                                                }
                                            </>
                                        }
                                    </Grid>
                                </Grid>

                            </Grid>
                            :
                            <></>
                        }
                    </Grid>
                </Box>






                <Box sx={{
                    borderRadius: '5px',
                    boxShadow: 0,
                    paddingTop: 1,
                    paddingBottom: 1,
                    paddingRight: 1,
                    paddingLeft: 1,
                    margin: 3,
                    backgroundColor: '#F5F5F5'
                }}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <SportsScoreIcon style={{ color: "#000" }} />
                        <Typography style={{ color: "#000" }} variant="button">objective</Typography>
                    </Stack>

                    <Box>
                        {txLoading ?
                            <Typography sx={{ marginTop: 0 }} variant="h3" color="text.primary">loading...</Typography>
                            :
                            <Typography sx={{ marginTop: 0 }} variant="h3" color="text.primary">{(totalSupply / maxSupply * 100).toFixed(2)} % minted</Typography>
                        }
                        <BorderLinearProgress variant="determinate" value={totalSupply / maxSupply * 100} />
                    </Box>

                    <Grid sx={{ marginTop: 0 }} container direction="row" alignItems="center" spacing={1} columns={2}>
                        <Grid sx={{ borderRight: 1, borderColor: 'grey.600' }} item md={1} xs={2}>
                            <Typography align="center">
                                <span className='stats-variables'>total raised</span>
                                <br />
                                {txLoading ?
                                    <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
                                        <img alt="logo" width={30} height={30} src={chain_img[network]} />
                                        <span className='stats-values' >loading...</span>
                                    </Stack>
                                    :
                                    <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
                                        <img alt="logo" width={30} height={30} src={chain_img[network]} />
                                        <span className='stats-values' >{((totalSupply - reservedAmount) * pricePerToken).toFixed(3)}</span>
                                    </Stack>
                                }
                            </Typography>
                        </Grid>
                        <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={2}>
                            <Typography align="center">
                                <span className='stats-variables'>total possible</span>
                                <br />
                                {txLoading ?
                                    <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
                                        <img alt="logo" width={30} height={30} src={chain_img[network]} />
                                        <span className='stats-values' >loading...</span>
                                    </Stack>
                                    :
                                    <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
                                        <img alt="logo" width={30} height={30} src={chain_img[network]} />
                                        <span className='stats-values' >{(maxSupply * pricePerToken).toFixed(3)}</span>
                                    </Stack>
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>




                <Box sx={{
                    borderRadius: '5px',
                    boxShadow: 0,
                    paddingTop: 1,
                    paddingBottom: 1,
                    paddingRight: 1,
                    paddingLeft: 1,
                    margin: 3,
                    backgroundColor: '#F5F5F5'
                }}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <BarChartIcon style={{ color: "#000" }} />
                        <Typography style={{ color: "#000" }} variant="button">mint stats</Typography>
                    </Stack>

                    <ResponsiveContainer width='100%' aspect={4.0 / 1.0}>
                        <ScatterChart
                            width={500}
                            height={400}
                            margin={{
                                top: 20,
                                right: 20,
                                bottom: 20,
                                left: 20,
                            }}
                        >
                            <CartesianGrid />
                            <XAxis type="number" dataKey="x" name="stature" domain={['auto', 'auto']} tick={<CustomizedAxisTick />} tickFormatter={formatXAxis} />
                            <YAxis type="number" dataKey="y" name="weight" tick={{ fontSize: 10 }} />
                            <ZAxis type="number" range={[100]} />
                            <Scatter name="A school" data={mintData} fill="#000" lineJointType='monotoneX' line shape={<RenderNoShape />} />
                        </ScatterChart>
                    </ResponsiveContainer>
                </Box>

            </Box>
        </div >
    );
};

export default AdminHome;