import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

import axios from 'axios';

import Avatar from "boring-avatars";

//import { useLocation } from 'react-router-dom';

import ArtworkBox from '../components/ArtworkBox';
import PremintBox from '../components/PremintBox';
import MintBox from '../components/MintBox';
import RevealBox from '../components/RevealBox';
import NotFoundCompo from '../components/NotFoundCompo';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import IconButton from '@mui/material/IconButton';
import LanguageIcon from '@mui/icons-material/Language';
import StorefrontIcon from '@mui/icons-material/Storefront';

import { UserContext } from '../App';

function Mint() {

    //const rout = useLocation();

    let { projectId } = useParams();

    //const { isMMInstalled, isConnected, account, accountNetwork } = React.useContext(UserContext);
    const { isConnected, account, chain_img, chain_name, chain_etherscan_name, api_url } = React.useContext(UserContext);

    //const opensea_svg = "https://drive.google.com/uc?export=view&id=14_0QPinCm7X6f-PsJkLDq9i9wgc2QyWq";
    const opensea_black_svg = "https://drive.google.com/uc?export=view&id=1lJKKJ5kSBg0_cJDBLykx5sVxHhuWGIZL";
    //const etherscan_svg = "https://drive.google.com/uc?export=view&id=1_fOqtQex3XmkX3s1R9RR7kwM2WIIZACH";
    const etherscan_black_svg = "https://drive.google.com/uc?export=view&id=1JfTp9LRtb_4Mq-465WLyofRlsFfWP9jo";
    //const twitter_svg = "https://drive.google.com/uc?export=view&id=1nii-vp0cCO3QV5HTdm-MyR7rak8Bp4v0";
    const twitter_black_svg = "https://drive.google.com/uc?export=view&id=1cX-Xjr_nM7zES-xy01bEtU11XuHRrkEh";
    //const insta_svg = "https://drive.google.com/uc?export=view&id=1S3qc5_emCr-5-fAwuHFFiw8cN3tQPNou";
    const insta_black_svg = "https://drive.google.com/uc?export=view&id=1kATG84GKz6xIEgw1MyL8goI9BelFAa8_";
    //const ethereum_svg = "https://drive.google.com/uc?export=view&id=1wxhyC6DcGaP8QcMmxsTj3t70E_qJrEXl";
    //const rinkeby_svg = "https://drive.google.com/uc?export=view&id=13LO-fowcktxlhnGv5ri6sZUQRs17-oZQ";
    //const rinkeby1_svg = "https://drive.google.com/uc?export=view&id=1YXZhrqb8tZwj5bf3RWH5xLNY2n2vksWe";

    const [access, setAccess] = useState(false);

    //const [userId, setuserId] = useState(null);
    const [artwork_img, setartwork_img] = useState("");
    const [banner_img, setBanner_img] = useState("");
    const [artwork_title, setartwork_title] = useState("");
    const [artwork_presentation, setartwork_presentation] = useState("");
    const [artist_name, setartist_name] = useState("");
    const [artist_presentation, setartist_presentation] = useState("");
    const [artist_wallet, setartist_wallet] = useState("");
    const [artist_img, setArtist_img] = useState("");
    const [contract_address, setcontract_address] = useState("");
    const [network, setnetwork] = useState(0);
    const [abiId, setAbiId] = useState(null);
    const [abi, setabi] = useState(0);
    const [revealOption, setRevealOption] = useState(true);
    const [presaleOption, setPresaleOption] = useState(true);
    const [saleOption, setSaleOption] = useState(true);
    const [creationDate, setCreationDate] = useState(0);
    const [crossmintId, setCrossmintId] = useState("")

    const [maxPublicMint, setmaxPublicMint] = useState(0);
    const [maxSupply, setmaxSupply] = useState(0);
    const [pricePerToken, setpricePerToken] = useState(0);
    const [presaleIsActive, setpresaleIsActive] = useState(false);
    //const [isValid, setisValid] = useState(null);
    const [numAvailableToMint, setNumAvailableToMint] = useState(0);
    const [alreadyPublicMinted, setAlreadyPublicMinted] = useState(0);
    //const [owner, setowner] = useState("");
    const [saleIsActive, setsaleIsActive] = useState(false);
    const [totalSupply, settotalSupply] = useState(0)
    const [presaleIsOver, setPresaleIsOver] = useState(false);
    const [saleIsOver, setSaleIsOver] = useState(false);
    const [revealIsOver, setRevealIsOver] = useState(false);

    const [etherscanUrl, setetherscanUrl] = useState("");
    const [openseaUrl, setopenseaUrl] = useState("");
    const [secondaryUrl, setSecondaryUrl] = useState("");
    const [twitterUrl, setTwitterUrl] = useState("");
    const [instagramUrl, setInstagramUrl] = useState("");
    const [websiteUrl, setWebsiteUrl] = useState("");

    const [onThePreList, setOnThePreList] = useState(false);

    const [txLoading, setTxLoading] = useState(false)

    const [firstCharg, setFirstCharg] = useState(true)

    useEffect(() => {
        //console.log("RELOAD MINT PAGE")
        axios.get(api_url + "/projects/" + projectId, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
            if (response.data.status === "fail") {
                setAccess(false)
                //console.log("ACCESS DENIED")
                setFirstCharg(false)
            } else {
                setAccess(true)
                //console.log("ACCESS OK")
                console.log("projects :", response)
                //console.log("abi :", response.data.project.abiId)
                //setuserId(response.data.project.userId)
                setartwork_img(response.data.project.imgUrl)
                setBanner_img(response.data.project.bannerUrl)
                setartwork_title(response.data.project.title)
                setartwork_presentation(response.data.project.presentation)
                setcontract_address(response.data.project.smartContractAddress)
                setnetwork(response.data.project.network)
                setSaleIsOver(response.data.project.saleIsOver)
                setPresaleIsOver(response.data.project.presaleIsOver)
                //console.log(response.data.project.network)
                setopenseaUrl(response.data.project.openseaUrl)
                setSecondaryUrl(response.data.project.secondaryUrl)
                setWebsiteUrl(response.data.project.websiteUrl)
                setTwitterUrl(response.data.project.twitterUrl)
                setInstagramUrl(response.data.project.instagramUrl)
                setAbiId(response.data.project.abiId)
                setPresaleOption(response.data.project.presaleOption)
                setSaleOption(response.data.project.saleOption)
                setRevealOption(response.data.project.revealOption)
                setetherscanUrl("https://" + chain_etherscan_name[response.data.project.network] + "etherscan.io/address/" + response.data.project.smartContractAddress)
                setCreationDate(response.data.project.creationDate)
                setmaxSupply(response.data.project.maxSupply)
                setpricePerToken(response.data.project.unitPrice)
                setmaxPublicMint(response.data.project.maxPublicMint)
                setsaleIsActive(response.data.project.saleIsActive)
                setpresaleIsActive(response.data.project.presaleIsActive)
                setRevealIsOver(response.data.project.isRevealed)
                setCrossmintId(response.data.project.crossmintId)
                console.log("crossmintId :", response.data.project.crossmintId)
                axios.get(api_url + "/users/" + response.data.project.userId, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response1) => {
                    //console.log("users :", response1)
                    setartist_name(response1.data.user.name)
                    setartist_wallet(response1.data.user.id)
                    setartist_presentation(response1.data.user.presentation)
                    setArtist_img(response1.data.user.imgUrl)
                });
                if (response.data.project.presaleOption) {
                    if (!response.data.project.presaleIsOver) {
                        if (isConnected) {
                            axios.get(api_url + "/onWhitelist/" + projectId + "/" + String(account)).then((response3) => {
                                setOnThePreList(response3.data.whitelisted)
                                if (response3.data.whitelisted) {
                                    axios.get(api_url + "/getNumAvailableToMint/" + projectId + "/" + String(account)).then((response4) => {
                                        setNumAvailableToMint(response4.data.data.numAvailableToMint)
                                    });
                                }
                            });
                        } 
                    }
                }
                if (response.data.project.saleOption) {
                    if (response.data.project.presaleIsOver) {
                        if (!response.data.project.saleIsOver) {
                            axios.get(api_url + "/getAlreadyPublicMinted/" + projectId + "/" + String(account)).then((response5) => {
                                setAlreadyPublicMinted(response5.data.data.alreadyPublicMinted)
                            });
                        }
                    }
                }
                axios.get(api_url + "/getTotalSupply/" + projectId).then((response6) => {
                    settotalSupply(response6.data.data.totalSupply)
                    setFirstCharg(false)
                });
                axios.get(api_url + "/abis/" + response.data.project.abiId).then((response7) => {
                    //console.log("abis :", response3)
                    setabi(response7.data.abi.abiJson)
                });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    return (
        <div className="App-body">
            {access ?
                <>
                    <br /><br />
                    <div class="banner">
                        {banner_img === "" ?
                            <img alt="logo" src="https://res.cloudinary.com/drx6j77zq/image/upload/v1666954663/projects/placeholders/banner.png" />
                            :
                            <img alt="banner" src={banner_img} />
                        }
                    </div>
                    <ArtworkBox isMint = {true} firstCharg={firstCharg} projectId={projectId} chain_img={chain_img} chain_name={chain_name} artwork_img={artwork_img} artwork_title={artwork_title} artist_name={artist_name} creationDate={creationDate} pricePerToken={pricePerToken} network={network} totalSupply={totalSupply}  maxSupply={maxSupply} />
                    {presaleOption ?
                        <PremintBox settotalSupply={settotalSupply} projectId={projectId} api_url={api_url} setNumAvailableToMint={setNumAvailableToMint} abiId={abiId} txLoading={txLoading} setTxLoading={setTxLoading} presaleIsOver={presaleIsOver} chain_img={chain_img} chain_name={chain_name} contract_address={contract_address} abi={abi} presaleIsActive={presaleIsActive} pricePerToken={pricePerToken} network={network} numAvailableToMint={numAvailableToMint} onThePreList={onThePreList} />
                        :
                        <></>
                    }
                    {saleOption ?
                        <MintBox crossmintId={crossmintId} abiId={abiId} projectId={projectId} api_url={api_url} txLoading={txLoading} setTxLoading={setTxLoading} settotalSupply={settotalSupply} presaleIsOver={presaleIsOver} saleIsOver={saleIsOver} chain_img={chain_img} chain_name={chain_name} contract_address={contract_address} abi={abi} saleIsActive={saleIsActive} pricePerToken={pricePerToken} network={network} maxPublicMint={maxPublicMint} alreadyPublicMinted={alreadyPublicMinted} setAlreadyPublicMinted={setAlreadyPublicMinted} />
                        :
                        <></>
                    }
                    {revealOption ?
                        <RevealBox presaleIsOver={presaleIsOver} saleIsOver={saleIsOver} revealIsOver={revealIsOver} />
                        :
                        <></>
                    }
                    <br />
                    <Box
                        sx={{
                            width: '75vw',
                            borderRadius: '10px',
                            boxShadow: 0,
                            paddingLeft: 1,
                            paddingRight: 1,
                            paddingTop: 0,
                            paddingBottom: 0,
                            marginTop: 2,
                            backgroundColor: '#EEEEEE'
                        }}>
                        <Typography sx={{ padding: "20px" }} align="left">
                            <h2 className="artwork-title"><span className="by-artist">about</span> {artwork_title}</h2>
                            <IconButton aria-label="delete" href={websiteUrl} target="_blank">
                                <LanguageIcon sx={{ color: 'grey.900' }} />
                            </IconButton>
                            {openseaUrl ?
                                <IconButton aria-label="opensea" href={openseaUrl} target="_blank">
                                    <img alt="logo" width={25} height={25} src={opensea_black_svg} />
                                </IconButton>
                                :
                                <></>
                            }
                            {secondaryUrl ?
                                <IconButton aria-label="secondary" href={secondaryUrl} target="_blank">
                                    <StorefrontIcon sx={{ color: 'grey.900' }} />
                                </IconButton>
                                :<></>}
                            
                            <IconButton aria-label="etherscan" href={etherscanUrl} target="_blank">
                                <img alt="logo" width={25} height={25} src={etherscan_black_svg} />
                            </IconButton>
                            <IconButton aria-label="twitter" href={twitterUrl} target="_blank">
                                <img alt="logo" width={25} height={25} src={twitter_black_svg} />
                            </IconButton>
                            <IconButton aria-label="insta" href={instagramUrl} target="_blank">
                                <img alt="logo" width={25} height={25} src={insta_black_svg} />
                            </IconButton>
                            <br /><br />
                            <span className="presentation-text">{artwork_presentation}</span>
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            width: '75vw',
                            borderRadius: '10px',
                            boxShadow: 0,
                            paddingLeft: 1,
                            paddingRight: 1,
                            paddingTop: 0,
                            paddingBottom: 0,
                            marginTop: 2,
                            backgroundColor: '#EEEEEE'
                        }}>
                        <Typography sx={{ padding: "20px" }} align="left">
                            <h2 className="artwork-title"><span className="by-artist">about</span> <Link to={"/profile/" + artist_wallet} className="small-menu-link">{artist_name}</Link></h2>
                            {artist_img ?
                                <img alt="logo" width={40} height={40} src={artist_img} />
                                :
                                <Avatar
                                    size={40}
                                    name={artist_wallet}
                                    square
                                    variant="marble"
                                    colors={["#92A1C6", "#146A7C", "#F0AB3D", "#C271B4", "#C20D90"]}
                                />
                            }
                            
                            <br />
                            <span className="presentation-text">{artist_presentation}</span>
                        </Typography>
                    </Box>
                </>
                :
                <NotFoundCompo />
            }
        </div>
    )
}
export default Mint;