import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import Avatar from "boring-avatars";

//import { useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import LinkMui from '@mui/material/Link';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TuneIcon from '@mui/icons-material/Tune';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import CloseIcon from '@mui/icons-material/Close';
import LockIcon from '@mui/icons-material/Lock';
import VerifiedIcon from '@mui/icons-material/Verified';
import LanguageIcon from '@mui/icons-material/Language';
import CheckIcon from '@mui/icons-material/Check';
import PersonIcon from '@mui/icons-material/Person';
import ConstructionIcon from '@mui/icons-material/Construction';

import { UserContext } from '../App';
import { ButtonGroup } from '@mui/material';

const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70vw',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'scroll',
    height: '90%'
};

function Profile() {

    //const rout = useLocation();

    let { userId } = useParams();

    //const { isMMInstalled, isConnected, account, accountNetwork } = React.useContext(UserContext);
    const { isSigned, account, api_url } = React.useContext(UserContext);


    const twitter_black_svg = "https://drive.google.com/uc?export=view&id=1cX-Xjr_nM7zES-xy01bEtU11XuHRrkEh";
    const insta_black_svg = "https://drive.google.com/uc?export=view&id=1kATG84GKz6xIEgw1MyL8goI9BelFAa8_";
    const etherscan_black_svg = "https://drive.google.com/uc?export=view&id=1JfTp9LRtb_4Mq-465WLyofRlsFfWP9jo";

    const [isAdmin, setIsAdmin] = useState(false)
    const [name, setName] = useState("")
    const [presentation, setPresentation] = useState("")
    const [etherscanUrl, setEtherscanUrl] = useState("")
    const [imgUrl, setImgUrl] = useState("")
    const [isCreator, setIsCreator] = useState(false)
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [userProjectsMainnet, setUserProjectsMainnet] = useState([])
    const [userProjectsSepolia, setUserProjectsSepolia] = useState([])
    const [userProjectsGoerli, setUserProjectsGoerli] = useState([])
    const [userIsConnected, setUserIsConnected] = useState(false)
    const [userFollowings, setUserFollowings] = useState([])

    const [openModal, setOpenModal] = useState(false);

    const [nameTemp, setNameTemp] = useState("")
    const [nameChanging, setNameChanging] = useState(false)

    const [presentationTemp, setPresentationTemp] = useState("")
    const [presentationChanging, setPresentationChanging] = useState(false)

    const [realName, setRealName] = useState("")
    const [emailVerif, setEmailVerif] = useState("")
    const [creatorDemand, setCreatorDemand] = useState("")

    const [address1, setAddress1] = useState("")
    const [address2, setAddress2] = useState("")
    const [address3, setAddress3] = useState("")
    const [city, setCity] = useState("")
    const [country, setCountry] = useState("")
    const [state, setState] = useState("")
    const [zipCode, setZipCode] = useState("")


    const [selectedImageFile, setSelectedImageFile] = useState();
    const [isImageFilePicked, setIsImageFilePicked] = useState(false);
    const [imageFormatNotOk, setImageFormatNotOk] = useState(false);

    const [txLoading] = useState(false);

    const [websiteUrl, setWebsiteUrl] = useState("");
    const [websiteUrlTemp, setWebsiteUrlTemp] = useState("")
    const [websiteUrlChanging, setWebsiteUrlChanging] = useState(false)

    const [twitterUrl, setTwitterUrl] = useState("");
    const [twitterUrlTemp, setTwitterUrlTemp] = useState("")
    const [twitterUrlChanging, setTwitterUrlChanging] = useState(false)

    const [instagramUrl, setInstagramUrl] = useState("");
    const [instagramUrlTemp, setInstagramUrlTemp] = useState("")
    const [instagramUrlChanging, setInstagramUrlChanging] = useState(false)

    const [maxProjects, setMaxProjects] = useState(0)
    const [nbProjects, setNbProjects] = useState(0)
    const [nbProjectsMainnet, setNbProjectsMainnet] = useState(0)
    const [nbProjectsSepolia, setNbProjectsSepolia] = useState(0)
    const [nbProjectsGoerli, setNbProjectsGoerli] = useState(0)


    const handleOpenModal = () => {
        setOpenModal(true);
    }
    const handleCloseModal = () => {
        setOpenModal(false);
        setNameTemp("")
        setNameChanging(false)
        setPresentationTemp("")
        setPresentationChanging(false)
        setSelectedImageFile("")
        setIsImageFilePicked(false)
        setImageFormatNotOk(false)
    }

    function consoleVariables() {
        console.log(maxProjects, nbProjects, nbProjectsMainnet, nbProjectsSepolia, nbProjectsGoerli)
    }

    async function handleSubmitName(event) {
        try {
            event.preventDefault();
            //console.log('name: ', nameTemp);
            if (nameTemp === "") {
                axios.put(api_url + "/users/" + userId, { name: userId.substring(0, 5) + "..." + userId.substring(userId.length - 5) }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                    setName(userId.substring(0, 5) + "..." + userId.substring(userId.length - 5))
                    setNameChanging(false)
                });
            } else {
                axios.put(api_url + "/users/" + userId, { name: nameTemp }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                    setName(nameTemp)
                    setNameChanging(false)
                });
            }


        } catch (error) {
            //console.log(error)
        }
    }

    async function handleSubmitPresentation(event) {
        try {
            event.preventDefault();
            //console.log('presentation: ', presentationTemp);
            if (presentationTemp === "") {
                axios.put(api_url + "/users/" + userId, { presentation: "gm crmblrs, this is me!" }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                    setPresentation("gm crmblrs, this is me!")
                    setPresentationChanging(false)
                });
            } else {
                axios.put(api_url + "/users/" + userId, { presentation: presentationTemp }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                    setPresentation(presentationTemp)
                    setPresentationChanging(false)
                });
            }


        } catch (error) {
            //console.log(error)
        }
    }

    const changeImageHandler = (event) => {
        //console.log(event.target.files[0].type.substring(event.target.files[0].type.length - 3))
        if (event.target.files[0].type.substring(event.target.files[0].type.length - 4) === "jpeg" || event.target.files[0].type.substring(event.target.files[0].type.length - 3) === "jpg" || event.target.files[0].type.substring(event.target.files[0].type.length - 3) === "png") {
            setSelectedImageFile(event.target.files[0]);
            setIsImageFilePicked(true);
            setImageFormatNotOk(false);
        } else {
            setSelectedImageFile(null);
            setIsImageFilePicked(false);
            setImageFormatNotOk(true)
            //console.log("choose a jpg or png")
        }
    };



    const handleImageSubmission = () => {
        const formData = new FormData();
        //console.log(selectedImageFile)
        formData.append('file', selectedImageFile);

        try {
            axios.put(api_url + "/users/setImg/" + userId, formData, { headers: { 'Content-Type': selectedImageFile.type, Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                //console.log(response)
                setImgUrl(response.data.data['url'])
                setSelectedImageFile(null);
                setIsImageFilePicked(false);
                setImageFormatNotOk(false)
            });

        } catch (error) {
            //console.log(error)
        }

    }

    const handleImageDelete = () => {
        //console.log(localStorage.getItem('token'))
        try {
            axios.put(api_url + "/users/deleteImg/" + userId, {}, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                //console.log(response)
                setImgUrl("")
                setSelectedImageFile(null);
                setIsImageFilePicked(false);
                setImageFormatNotOk(false)
            });

        } catch (error) {
            //console.log(error)
        }
    }

    async function handleSubmitWebsiteUrl(event) {
        try {
            event.preventDefault();
            //console.log('websiteUrl: ', websiteUrlTemp.substring(0, 4));
            if (websiteUrlTemp.substring(0, 4) !== "http") {
                axios.put(api_url + "/users/" + userId, { websiteUrl: 'https://' + websiteUrlTemp }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                    setWebsiteUrl('https://' + websiteUrlTemp)
                    setWebsiteUrlChanging(false)
                });
            } else {
                axios.put(api_url + "/users/" + userId, { websiteUrl: websiteUrlTemp }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                    setWebsiteUrl(websiteUrlTemp)
                    setWebsiteUrlChanging(false)
                });
            }


        } catch (error) {
            //console.log(error)
        }
    }

    async function handleSubmitInstagramUrl(event) {
        try {
            event.preventDefault();
            //console.log('instagramUrl: ', instagramUrlTemp.substring(0, 4));
            if (instagramUrlTemp.substring(0, 4) !== "http") {
                axios.put(api_url + "/users/" + userId, { instagramUrl: 'https://' + instagramUrlTemp }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                    setInstagramUrl('https://' + instagramUrlTemp)
                    setInstagramUrlChanging(false)
                });
            } else {
                axios.put(api_url + "/users/" + userId, { instagramUrl: instagramUrlTemp }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                    setInstagramUrl(instagramUrlTemp)
                    setInstagramUrlChanging(false)
                });
            }


        } catch (error) {
            //console.log(error)
        }
    }

    async function handleSubmitTwitterUrl(event) {
        try {
            event.preventDefault();
            //console.log('twitterUrl: ', twitterUrlTemp.substring(0, 4));
            if (twitterUrlTemp.substring(0, 4) !== "http") {
                axios.put(api_url + "/users/" + userId, { twitterUrl: 'https://' + twitterUrlTemp }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                    setTwitterUrl('https://' + twitterUrlTemp)
                    setTwitterUrlChanging(false)
                });
            } else {
                axios.put(api_url + "/users/" + userId, { twitterUrl: twitterUrlTemp }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                    setTwitterUrl(twitterUrlTemp)
                    setTwitterUrlChanging(false)
                });
            }


        } catch (error) {
            //console.log(error)
        }
    }


    useEffect(() => {
        //console.log("RELOAD PROFILE PAGE")
        consoleVariables()
        setIsAdmin(false)
        axios.get(api_url + "/users/" + userId, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
            //setuserId(response.data.project.userId)
            //console.log(response)
            if (response.data.status === "success") {
                setName(response.data.user.name)
                setPresentation(response.data.user.presentation)
                setImgUrl(response.data.user.imgUrl)
                setIsCreator(response.data.user.creator)
                setEmail(response.data.user.email)
                setPhoneNumber(response.data.user.phoneNumber)
                setEtherscanUrl("https://etherscan.io/address/" + userId)
                setInstagramUrl(response.data.user.instagramUrl)
                setTwitterUrl(response.data.user.twitterUrl)
                setWebsiteUrl(response.data.user.websiteUrl)

                setRealName(response.data.user.realName)
                setEmailVerif(response.data.user.emailVerified)
                setCreatorDemand(response.data.user.creatorDemand)
                if (response.data.group === "owner" || response.data.group === "admin") {
                    setIsAdmin(true)
                    setMaxProjects(response.data.user.maxProjects)
                    axios.get(api_url + "/addresses/" + userId, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response4) => {
                        //console.log("response4 :", response4)
                        if (response4.data.status === "success") {
                            //console.log("SUCCESS")
                            setAddress1(response4.data.address.address1)
                            setAddress2(response4.data.address.address2)
                            setAddress3(response4.data.address.address3)
                            setCity(response4.data.address.city)
                            setCountry(response4.data.address.country)
                            setState(response4.data.address.state)
                            setZipCode(response4.data.address.zipCode)
                        }
                    })
                }
                axios.get(api_url + "/projectsByUser/" + userId).then((response2) => {
                    //console.log("projects:", response2.data.projects)
                    //console.log("nb projects:", response2.data.projects[1].length + response2.data.projects[5].length + response2.data.projects[11155111].length)
                    //console.log(Object.entries(response2.data.projects));
                    //console.log(Object.entries(response2.data.projects).length)
                    //console.log(Object.entries(response2.data.projects)[0][Object.entries(response2.data.projects)[0].length - 1])
                    //console.log("projects :", response2.data.projects[11155111])
                    setUserProjectsSepolia(response2.data.projects[11155111])
                    setUserProjectsGoerli(response2.data.projects[5])
                    setUserProjectsMainnet(response2.data.projects[1])
                    setNbProjectsMainnet(response2.data.projects[1].length)
                    setNbProjectsGoerli(response2.data.projects[5].length)
                    setNbProjectsSepolia(response2.data.projects[11155111].length)
                    setNbProjects(response2.data.projects[1].length + response2.data.projects[5].length + response2.data.projects[11155111].length)
                })
                axios.get(api_url + "/followingsByUser/" + userId, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response3) => {
                    //console.log(response3)
                    if (response3.data.status === "success") {
                        //console.log(response3.data.followings)
                        setUserFollowings(response3.data.followings)
                        setUserIsConnected(true)
                    } else {
                        setUserIsConnected(false)
                        //console.log(response3)
                    }
                })
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, isSigned, userId]);

    return (
        <div className="App-body">
            <Box className="artwork-box">
                <Grid container sx={{ marginTop: 20, alignItems: "flex-end", display: { xs: 'none', md: 'flex' } }} spacing={5} columns={2}>
                    <Grid column={1}>
                        <Card sx={{
                            width: '200px',
                            height: '200px',
                            borderRadius: '5px',
                            display: "flex",
                            alignItems: "center",
                            boxShadow: 0
                        }}>
                            {imgUrl === "" ?
                                <Avatar
                                    size={200}
                                    name={userId}
                                    square
                                    variant="marble"
                                    colors={["#92A1C6", "#146A7C", "#F0AB3D", "#C271B4", "#C20D90"]}
                                />
                                :
                                <CardMedia
                                    component="img"
                                    image={imgUrl}
                                    alt="artwork img"
                                    sx={{
                                        width: '200px',
                                        height: '200px'
                                    }}
                                />
                            }

                        </Card>
                    </Grid>
                    <Grid
                        item
                        xs
                        column={1}>
                        <Card sx={{
                            borderRadius: '5px',
                            boxShadow: 0,
                            display: "flex",
                            alignItems: "flex-end",
                            backgroundColor: 'transparent',
                        }}>
                            <CardContent sx={{
                                minWidth: '15vw',
                                display: 'row',
                                alignItems: 'flex-end',
                                justifyContent: 'left',
                            }}>
                                <Typography align="left">
                                    <h1 className="artwork-title">{name} {isCreator ? <VerifiedIcon /> : <></>} </h1>
                                    <h5 className="artwork-title"><LinkMui href={etherscanUrl} target="_blank" underline="hover">{userId}</LinkMui></h5>

                                    {isAdmin ?
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <Button onClick={handleOpenModal} variant="outlined">edit</Button>
                                            {isCreator ?
                                                <Button component={Link} to={'/personal'} variant="outlined">update private information</Button>
                                                :
                                                <>
                                                    {creatorDemand ?
                                                        <Button component={Link} to={'/personal'} variant="outlined">update private information</Button>
                                                        :
                                                        <Button component={Link} to={'/personal'} variant="outlined">Verify your profile</Button>
                                                    }
                                                </>
                                            }

                                        </Stack>
                                        :
                                        <></>
                                    }
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>


                <Card sx={{
                    minWidth: '35vw',
                    alignItems: 'flex-end',
                    justifyContent: 'left',
                    
                    width: '200px',
                    height: '200px',
                    borderRadius: '5px',
                    marginTop: 16,
                    marginLeft: 2,
                    display: { xs: 'flex', md: 'none' },
                    boxShadow: 0
                }}>
                    {imgUrl === "" ?
                        <Avatar
                            size={200}
                            name={userId}
                            square
                            variant="marble"
                            colors={["#92A1C6", "#146A7C", "#F0AB3D", "#C271B4", "#C20D90"]}
                        />
                        :
                        <CardMedia
                            component="img"
                            image={imgUrl}
                            alt="artwork img"
                            sx={{
                                width: '200px',
                                height: '200px'
                            }}
                        />
                    }

                </Card>

                <Card sx={{
                    borderRadius: '5px',
                    boxShadow: 0,
                    display: { xs: 'flex', md: 'none' },
                    alignItems: "flex-end",
                    backgroundColor: 'transparent'
                }}>
                    <CardContent sx={{
                        minWidth: '35vw',
                        display: 'row',
                        alignItems: 'flex-end',
                        justifyContent: 'left',
                    }}>
                        <Typography align="left">
                            <h1 className="artwork-title">{name} {isCreator ? <VerifiedIcon /> : <></>} </h1>
                            <h5 className="artwork-title"><LinkMui href={etherscanUrl} target="_blank" underline="hover">{userId}</LinkMui></h5>

                            {isAdmin ?
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <Button onClick={handleOpenModal} variant="outlined">edit</Button>
                                    {isCreator ?
                                        <Button component={Link} to={'/personal'} variant="outlined">update private information</Button>
                                        :
                                        <>
                                            {creatorDemand ?
                                                <Button component={Link} to={'/personal'} variant="outlined">update private information</Button>
                                                :
                                                <Button component={Link} to={'/personal'} variant="outlined">Verify your profile</Button>
                                            }
                                        </>
                                    }

                                </Stack>
                                :
                                <></>
                            }
                        </Typography>
                    </CardContent>
                </Card>


            </Box>

            <Box
                sx={{
                    width: '75vw',
                    borderRadius: '10px',
                    boxShadow: 0,
                    paddingLeft: 1,
                    paddingRight: 1,
                    paddingTop: 1,
                    paddingBottom: 0,
                    marginTop: 1,
                    backgroundColor: '#F3F3F3'
                }}>
                <Stack sx={{ padding: "5px" }} direction="row" alignItems="center" spacing={1}>
                    <EmojiPeopleIcon fontSize="small" />
                    <Typography variant="subtitle2" align="left">
                        Bio
                    </Typography>
                </Stack>



                <Typography variant="h6" sx={{ paddingLeft: "20px", paddingBottom: "20px", paddingRight: "20px" }} align="left">
                    {presentation}
                    <br /><br />
                    <IconButton aria-label="etherscan" href={etherscanUrl} target="_blank">
                        <img alt="logo" width={25} height={25} src={etherscan_black_svg} />
                    </IconButton>
                    {websiteUrl !== "" && websiteUrl !== "https://" ?
                        <IconButton aria-label="delete" href={websiteUrl} target="_blank">
                            <LanguageIcon sx={{ color: 'grey.900' }} />
                        </IconButton>
                        :
                        <></>
                    }

                    {twitterUrl !== "" && twitterUrl !== "https://" ?
                        <IconButton aria-label="twitter" href={twitterUrl} target="_blank">
                            <img alt="logo" width={25} height={25} src={twitter_black_svg} />
                        </IconButton>
                        :
                        <></>
                    }
                    {instagramUrl !== "" && instagramUrl !== "https://" ?
                        <IconButton aria-label="insta" href={instagramUrl} target="_blank">
                            <img alt="logo" width={25} height={25} src={insta_black_svg} />
                        </IconButton>
                        :
                        <></>
                    }
                </Typography>
            </Box>




            <Box
                sx={{
                    width: '75vw',
                    borderRadius: '10px',
                    boxShadow: 0,
                    paddingLeft: 1,
                    paddingRight: 1,
                    paddingTop: 1,
                    paddingBottom: 2,
                    marginTop: 2,
                    backgroundColor: '#F3F3F3'
                }}>
                <Stack sx={{ padding: "5px" }} direction="row" alignItems="center" spacing={1}>
                    <RocketLaunchIcon fontSize="small" />
                    <Typography variant="subtitle2" align="left">
                        Created
                    </Typography>
                </Stack>
                <Stack sx={{ paddingLeft: "5px" }} direction="row" alignItems="center" spacing={1}>
                    <EmojiPeopleIcon sx={{ color: "#eee" }} fontSize="small" />
                    <Typography variant="caption" align="left">
                        [All the projects {name} created on crmbl]
                    </Typography>
                </Stack>

                {userProjectsMainnet ?
                    <Grid sx={{ marginTop: 1, marginBottom: 1, paddingLeft: 3, paddingRight: 3 }} container direction="row" justifyContent="center" alignItems="top" spacing={1} columns={5}>
                        {userProjectsMainnet.map((project) => (
                            <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={5}>
                                <Card>
                                    {project.imgUrl === "" ?
                                        <Avatar
                                            size={100}
                                            name={project.id}
                                            square
                                        />
                                        :
                                        <CardMedia
                                            component="img"
                                            alt="green iguana"
                                            image={project.imgUrl}
                                            height="100"
                                            width="100"
                                        />
                                    }
                                    <CardContent sx={{ paddingTop: 1, paddingBottom: 0 }}>
                                        <Typography gutterBottom variant="subtitle2" component="div">
                                            {project.title}
                                        </Typography>
                                    </CardContent>
                                    <CardActions sx={{ paddingTop: 0 }}>
                                        <ButtonGroup fullWidth variant="text">
                                            <Button component={Link} to={"/mint/" + project.id} size="small"><ArrowForwardIcon /></Button>
                                            {userIsConnected ?
                                                <Button component={Link} to={"/launchpad/" + project.id} size="small"><TuneIcon /></Button>
                                                :
                                                <></>
                                            }
                                        </ButtonGroup>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                        {userProjectsMainnet.length % 5 === 1 ?
                            <>
                                <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                            </>
                            :
                            <>
                                {userProjectsMainnet.length % 5 === 2 ?
                                    <>
                                        <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                        <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                        <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                    </>
                                    :
                                    <>
                                        {userProjectsMainnet.length % 5 === 3 ?
                                            <>
                                                <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                                <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                            </>
                                            :
                                            <>
                                                {userProjectsMainnet.length % 5 === 4 ?
                                                    <>
                                                        <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                                    </>
                                                    :
                                                    <></>
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }
                    </Grid>
                    :
                    <></>
                }

            </Box>



            {userIsConnected ?
                <Box
                    sx={{
                        width: '75vw',
                        borderRadius: '10px',
                        boxShadow: 0,
                        paddingLeft: 1,
                        paddingRight: 1,
                        paddingTop: 1,
                        paddingBottom: 2,
                        marginTop: 2,
                        backgroundColor: '#FAFAFA'
                    }}>
                    <Stack sx={{ padding: "5px" }} direction="row" alignItems="center" spacing={1}>
                        <BookmarkIcon fontSize="small" />
                        <Typography variant="subtitle2" align="left">
                            My list
                        </Typography>
                    </Stack>
                    <Stack sx={{ paddingLeft: "5px" }} direction="row" alignItems="center" spacing={1}>
                        <LockIcon fontSize="small" />
                        <Typography variant="caption" align="left">
                            [Only you can see this]
                        </Typography>
                    </Stack>

                    <Grid sx={{ marginTop: 1, marginBottom: 1, paddingLeft: 3, paddingRight: 3 }} container direction="row" justifyContent="center" alignItems="top" spacing={1} columns={5}>
                        {userFollowings.map((project) => (
                            <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={5}>
                                <Card>
                                    {project.imgUrl === "" ?
                                        <Avatar
                                            size={100}
                                            name={project.id}
                                            square
                                        />
                                        :
                                        <CardMedia
                                            component="img"
                                            alt="green iguana"
                                            image={project.imgUrl}
                                            height="100"
                                            width="100"
                                        />
                                    }
                                    <CardContent sx={{ paddingTop: 1, paddingBottom: 0 }}>
                                        <Typography gutterBottom variant="subtitle2" component="div">
                                            {project.title}
                                        </Typography>
                                    </CardContent>
                                    <CardActions sx={{ paddingTop: 0 }}>
                                        <Button fullWidth component={Link} to={"/mint/" + project.id} size="small"><ArrowForwardIcon /></Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                        {userFollowings.length % 5 === 1 ?
                            <>
                                <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                            </>
                            :
                            <>
                                {userFollowings.length % 5 === 2 ?
                                    <>
                                        <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                        <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                        <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                    </>
                                    :
                                    <>
                                        {userFollowings.length % 5 === 3 ?
                                            <>
                                                <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                                <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                            </>
                                            :
                                            <>
                                                {userFollowings.length % 5 === 4 ?
                                                    <>
                                                        <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                                    </>
                                                    :
                                                    <></>
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }
                    </Grid>


                </Box>
                :
                <></>}




            {userIsConnected ?
                <Box
                sx={{
                    width: '75vw',
                    borderRadius: '10px',
                    boxShadow: 0,
                    paddingLeft: 1,
                    paddingRight: 1,
                    paddingTop: 1,
                    paddingBottom: 2,
                    marginTop: 2,
                    backgroundColor: '#FAFAFA'
                }}>
                <Stack sx={{ padding: "5px" }} direction="row" alignItems="center" spacing={1}>
                    <ConstructionIcon fontSize="small" />
                    <Typography variant="subtitle2" align="left">
                        Created on sepolia
                    </Typography>
                </Stack>
                <Stack sx={{ paddingLeft: "5px" }} direction="row" alignItems="center" spacing={1}>
                    <LockIcon fontSize="small" />
                    <Typography variant="caption" align="left">
                        [Only you can see this]
                    </Typography>
                </Stack>

                {userProjectsSepolia ?
                    <Grid sx={{ marginTop: 1, marginBottom: 1, paddingLeft: 3, paddingRight: 3 }} container direction="row" justifyContent="center" alignItems="top" spacing={1} columns={5}>
                        {userProjectsSepolia.map((project) => (
                            <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={5}>
                                <Card>
                                    {project.imgUrl === "" ?
                                        <Avatar
                                            size={100}
                                            name={project.id}
                                            square
                                        />
                                        :
                                        <CardMedia
                                            component="img"
                                            alt="green iguana"
                                            image={project.imgUrl}
                                            height="100"
                                            width="100"
                                        />
                                    }
                                    <CardContent sx={{ paddingTop: 1, paddingBottom: 0 }}>
                                        <Typography gutterBottom variant="subtitle2" component="div">
                                            {project.title}
                                        </Typography>
                                    </CardContent>
                                    <CardActions sx={{ paddingTop: 0 }}>
                                        <ButtonGroup fullWidth variant="text">
                                            <Button component={Link} to={"/mint/" + project.id} size="small"><ArrowForwardIcon /></Button>
                                            {userIsConnected ?
                                                <Button component={Link} to={"/launchpad/" + project.id} size="small"><TuneIcon /></Button>
                                                :
                                                <></>
                                            }
                                        </ButtonGroup>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                        {userProjectsSepolia.length % 5 === 1 ?
                            <>
                                <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                            </>
                            :
                            <>
                                {userProjectsSepolia.length % 5 === 2 ?
                                    <>
                                        <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                        <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                        <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                    </>
                                    :
                                    <>
                                        {userProjectsSepolia.length % 5 === 3 ?
                                            <>
                                                <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                                <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                            </>
                                            :
                                            <>
                                                {userProjectsSepolia.length % 5 === 4 ?
                                                    <>
                                                        <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                                    </>
                                                    :
                                                    <></>
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }
                    </Grid>
                    :
                    <></>
                }

            </Box>
                :
                <></>
            }
            
            {userIsConnected ?
                <Box
                sx={{
                    width: '75vw',
                    borderRadius: '10px',
                    boxShadow: 0,
                    paddingLeft: 1,
                    paddingRight: 1,
                    paddingTop: 1,
                    paddingBottom: 2,
                    marginTop: 2,
                    backgroundColor: '#FAFAFA'
                }}>
                <Stack sx={{ padding: "5px" }} direction="row" alignItems="center" spacing={1}>
                    <ConstructionIcon fontSize="small" />
                    <Typography variant="subtitle2" align="left">
                        Created on goerli
                    </Typography>
                    
                </Stack>
                <Stack sx={{ paddingLeft: "5px" }} direction="row" alignItems="center" spacing={1}>
                    <LockIcon fontSize="small" />
                    <Typography variant="caption" align="left">
                        [Only you can see this]
                    </Typography>
                </Stack>
                

                {userProjectsGoerli ?
                    <Grid sx={{ marginTop: 1, marginBottom: 1, paddingLeft: 3, paddingRight: 3 }} container direction="row" justifyContent="center" alignItems="top" spacing={1} columns={5}>
                        {userProjectsGoerli.map((project) => (
                            <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={5}>
                                <Card>
                                    {project.imgUrl === "" ?
                                        <Avatar
                                            size={100}
                                            name={project.id}
                                            square
                                        />
                                        :
                                        <CardMedia
                                            component="img"
                                            alt="green iguana"
                                            image={project.imgUrl}
                                            height="100"
                                            width="100"
                                        />
                                    }
                                    <CardContent sx={{ paddingTop: 1, paddingBottom: 0 }}>
                                        <Typography gutterBottom variant="subtitle2" component="div">
                                            {project.title}
                                        </Typography>
                                    </CardContent>
                                    <CardActions sx={{ paddingTop: 0 }}>
                                        <ButtonGroup fullWidth variant="text">
                                            <Button component={Link} to={"/mint/" + project.id} size="small"><ArrowForwardIcon /></Button>
                                            {userIsConnected ?
                                                <Button component={Link} to={"/launchpad/" + project.id} size="small"><TuneIcon /></Button>
                                                :
                                                <></>
                                            }
                                        </ButtonGroup>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                        {userProjectsGoerli.length % 5 === 1 ?
                            <>
                                <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                            </>
                            :
                            <>
                                {userProjectsGoerli.length % 5 === 2 ?
                                    <>
                                        <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                        <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                        <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                    </>
                                    :
                                    <>
                                        {userProjectsGoerli.length % 5 === 3 ?
                                            <>
                                                <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                                <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                            </>
                                            :
                                            <>
                                                {userProjectsGoerli.length % 5 === 4 ?
                                                    <>
                                                        <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={1}></Grid>
                                                    </>
                                                    :
                                                    <></>
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }
                    </Grid>
                    :
                    <></>
                }

            </Box>
                :
                <></>
            }
            


            {isAdmin ?
                <Box
                    sx={{
                        width: '75vw',
                        borderRadius: '10px',
                        boxShadow: 0,
                        paddingLeft: 1,
                        paddingRight: 1,
                        paddingTop: 1,
                        paddingBottom: 0,
                        marginTop: 2,
                        backgroundColor: '#FAFAFA'
                    }}>
                    <Stack sx={{ padding: "5px", color: '#000' }} direction="row" alignItems="center" spacing={1}>
                        <PersonIcon fontSize="small" />
                        <Typography variant="subtitle2" align="left">
                            Private information
                        </Typography>
                    </Stack>
                    <Stack sx={{ paddingLeft: "5px", color: '#000' }} direction="row" alignItems="center" spacing={1}>
                        <LockIcon fontSize="small" />
                        <Typography variant="caption" align="left">
                            [Only you can see this]
                        </Typography>
                    </Stack>


                    <Grid container sx={{ paddingTop: 2, paddingLeft: 2, paddingRight: 2, paddingBottom: 3, margin: 0, color: '#000' }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                        <Grid sx={{ borderRight: 0 }} item md={1} xs={5}>
                            <Typography variant="subtitle2" align="left" style={{ fontStyle: "italic" }}>Real name</Typography>
                        </Grid>
                        <Grid sx={{ borderRight: 0 }} item md={4} xs={5}>
                            {realName ?
                                <Typography variant="subtitle1" align="left">{realName}</Typography>
                                :
                                <Typography variant="subtitle2" align="left">[complete information]</Typography>
                            }
                        </Grid>
                    </Grid>

                    <Grid container sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 3, margin: 0, color: '#000' }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>
                        <Grid sx={{ borderRight: 0 }} item md={1} xs={5}>
                            <Typography variant="subtitle2" align="left" style={{ fontStyle: "italic" }}>Email</Typography>
                        </Grid>
                        <Grid sx={{ borderRight: 0 }} item md={4} xs={5}>
                            {email ?
                                <Stack direction="row" alignItems="center" gap={1}>
                                    <Typography variant="subtitle1" align="left">{email}</Typography>
                                    {emailVerif ? 
                                        <CheckIcon />
                                        :
                                        <CloseIcon />
                                    }
                                </Stack>
                                :
                                <Typography variant="subtitle2" align="left">[complete information]</Typography>
                            }
                        </Grid>
                    </Grid>

                    <Grid container sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 3, margin: 0, color: '#000' }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>
                        <Grid sx={{ borderRight: 0 }} item md={1} xs={5}>
                            <Typography variant="subtitle2" align="left" style={{ fontStyle: "italic" }}>Phone Number</Typography>
                        </Grid>
                        <Grid sx={{ borderRight: 0 }} item md={4} xs={5}>
                            {phoneNumber ?
                                <Typography variant="subtitle1" align="left">{phoneNumber}</Typography>
                                :
                                <Typography variant="subtitle2" align="left">[complete information]</Typography>
                            }
                        </Grid>
                    </Grid>

                    <Grid container sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 3, margin: 0, color: '#000' }} direction="row" justifyContent="flex-start" alignItems="top" spacing={0} columns={5}>
                        <Grid sx={{ borderRight: 0 }} item md={1} xs={5}>
                            <Typography variant="subtitle2" align="left" style={{ fontStyle: "italic" }}>Postal address</Typography>
                        </Grid>
                        <Grid sx={{ borderRight: 0 }} item md={4} xs={5}>
                            {address1 ?
                                <>
                                    <Typography variant="subtitle1" align="left">{address1}</Typography>
                                    <Typography variant="subtitle1" align="left">{address2}</Typography>
                                    <Typography variant="subtitle1" align="left">{address3}</Typography>
                                    <Typography variant="subtitle1" align="left">{zipCode}</Typography>
                                    <Typography variant="subtitle1" align="left">{city}</Typography>
                                    <Typography variant="subtitle1" align="left">{state}</Typography>
                                    <Typography variant="subtitle1" align="left">{country}</Typography>
                                </>
                                :
                                <Typography variant="subtitle2" align="left">[complete information]</Typography>
                            }
                        </Grid>
                    </Grid>

                    <Grid container sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 3, margin: 0, color: '#000' }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>
                        <Grid sx={{ borderRight: 0 }} item md={1} xs={5}>
                            <Typography variant="subtitle2" align="left" style={{ fontStyle: "italic" }}>Verification status</Typography>
                        </Grid>
                        <Grid sx={{ borderRight: 0 }} item md={4} xs={5}>
                            <Typography variant="subtitle1" align="left">
                                {isCreator ?
                                    <VerifiedIcon />
                                    :
                                    <>
                                        {creatorDemand ?
                                            <>in process</>
                                            :
                                            <CloseIcon />
                                        }
                                    </>
                                }
                            </Typography>
                        </Grid>
                        <br />
                    </Grid>

                    <Grid container sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 2, margin: 0, color: '#000' }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={3}>
                        <Grid sx={{ borderRight: 0 }} item md={1} xs={3}>
                            {isCreator ?
                                <Button fullWidth component={Link} to={'/personal'} variant="outlined">update private information</Button>
                                :
                                <>
                                    {creatorDemand ?
                                        <Button fullWidth component={Link} to={'/personal'} variant="outlined">update private information</Button>
                                        :
                                        <Button fullWidth component={Link} to={'/personal'} variant="outlined">Verify your profile</Button>
                                    }
                                </>
                            }
                        </Grid>
                    </Grid>

                </Box>
                : <></>
            }





            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Edit your profile information
                    </Typography>
                    <Typography variant="caption" align="left">
                        [Complete the fields below to provide information about you or your company]
                    </Typography>


                    <Grid container sx={{ margin: 3 }} direction="row" justifyContent="space-between" alignItems="center" spacing={0} columns={1}>

                        <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                            <Grid container sx={{ paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="caption" align="left" style={{ fontStyle: "italic" }}>Username</Typography></Grid>

                                {!nameChanging ?
                                    <>
                                        <Grid sx={{ borderRight: 0 }} item md={3} xs={4}>
                                            {name ?
                                                <Typography variant="h6" align="left">{name}</Typography>
                                                :
                                                <Typography variant="subtitle2">[complete information]</Typography>
                                            }
                                        </Grid>
                                        <Grid sx={{ borderRight: 0 }} item md={1} xs={1}>
                                            <Typography align="right">
                                                <IconButton disabled={txLoading} aria-label="edit" onClick={() => { setNameTemp(name); setNameChanging(true); }}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Typography>
                                        </Grid>
                                    </>
                                    :
                                    <Grid sx={{ borderRight: 0 }} item md={4} xs={4}>
                                        <form onSubmit={handleSubmitName}>
                                            <Grid container sx={{ marginLeft: 0, marginRight: 0, marginTop: 0 }} direction="row" justifyContent="flex-start" alignItems="top" spacing={1} columns={14}>
                                                <Grid item md={10} xs={14}>
                                                    <TextField value={nameTemp} onInput={e => setNameTemp(e.target.value)} size="small" fullWidth id="name" label="name" variant="outlined" />
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={3} xs={14}>
                                                    <Button type="submit" size="large" fullWidth variant="contained">set</Button>
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={1} xs={14}>
                                                    <Button size="large" fullWidth variant="outlined" onClick={() => { setNameChanging(false); }}>X</Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>

                        <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                            <Grid container sx={{ paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="caption" align="left" style={{ fontStyle: "italic" }}>Profile picture</Typography></Grid>
                                <Grid sx={{ borderRight: 0 }} item md={2} xs={4}>
                                    <Stack direction="row" alignItems="center" gap={1}>
                                        {imgUrl === "" ?
                                            <Avatar
                                                size={200}
                                                name={userId}
                                                square
                                                variant="marble"
                                                colors={["#92A1C6", "#146A7C", "#F0AB3D", "#C271B4", "#C20D90"]}
                                            />
                                            :
                                            <img alt="logo" width={300} height={300} src={imgUrl} />
                                        }
                                    </Stack>
                                </Grid>
                                <Grid sx={{ borderRight: 0 }} item md={2} xs={1}>
                                    <Typography align="right">
                                        <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={1}>
                                            <input
                                                accept="image/*"
                                                hidden
                                                id="icon-button-photo"
                                                onChange={changeImageHandler}
                                                type="file"
                                                disabled={txLoading || !isCreator}
                                            />
                                            <label htmlFor="icon-button-photo">
                                                <IconButton color="default" component="span" disabled={txLoading || !isCreator}>
                                                    <EditIcon />
                                                </IconButton>
                                            </label>
                                            {isImageFilePicked ?
                                                <Stack direction="row" alignItems="center" gap={1}>
                                                    <Typography>{selectedImageFile.name}</Typography>
                                                    <Button disabled={imageFormatNotOk} variant="contained" onClick={handleImageSubmission}>
                                                        set
                                                    </Button>
                                                </Stack>
                                                : <></>
                                            }
                                            {imgUrl === "" ?
                                                <></>
                                                :
                                                <IconButton onClick={handleImageDelete} color="default" component="span" disabled={txLoading}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            }
                                        </Stack>
                                        {imageFormatNotOk ?
                                            <Alert severity="warning">This format is not supported, choose a png or a jpg!</Alert>
                                            :
                                            <></>
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                            <Grid container sx={{ paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="caption" align="left" style={{ fontStyle: "italic" }}>Biography</Typography></Grid>

                                {!presentationChanging ?
                                    <>
                                        <Grid sx={{ borderRight: 0 }} item md={3} xs={4}>
                                            {presentation ?
                                                <Typography variant="h6" align="left">{presentation}</Typography>
                                                :
                                                <Typography variant="subtitle2">[complete information]</Typography>
                                            }
                                        </Grid>
                                        <Grid sx={{ borderRight: 0 }} item md={1} xs={1}>
                                            <Typography align="right">
                                                <IconButton disabled={txLoading} aria-label="edit" onClick={() => { setPresentationTemp(presentation); setPresentationChanging(true); }}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Typography>
                                        </Grid>
                                    </>
                                    :
                                    <Grid sx={{ borderRight: 0 }} item md={4} xs={4}>
                                        <form onSubmit={handleSubmitPresentation}>
                                            <Grid container sx={{ marginLeft: 0, marginRight: 0, marginTop: 0 }} direction="row" justifyContent="flex-start" alignItems="top" spacing={1} columns={14}>
                                                <Grid item md={10} xs={14}>
                                                    <TextField multiline value={presentationTemp} onInput={e => setPresentationTemp(e.target.value)} size="small" fullWidth id="bio" label="bio" variant="outlined" />
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={3} xs={14}>
                                                    <Button type="submit" size="large" fullWidth variant="contained">set</Button>
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={1} xs={14}>
                                                    <Button size="large" fullWidth variant="outlined" onClick={() => { setPresentationChanging(false); }}>X</Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>


                        <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                            <Grid container sx={{ paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="caption" align="left" style={{ fontStyle: "italic" }}>Website url</Typography></Grid>

                                {!websiteUrlChanging ?
                                    <>
                                        <Grid sx={{ borderRight: 0 }} item md={3} xs={4}>
                                            {websiteUrl === "" || websiteUrl === "https://" || websiteUrl === null ?
                                                <Typography variant="subtitle2" align="left">[complete information]</Typography>
                                                :
                                                <Typography variant="h6" align="left">{websiteUrl}</Typography>
                                            }
                                        </Grid>
                                        <Grid sx={{ borderRight: 0 }} item md={1} xs={1}>
                                            <Typography align="right">
                                                <IconButton disabled={txLoading} aria-label="edit" onClick={() => { setWebsiteUrlTemp(websiteUrl); setWebsiteUrlChanging(true); }}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Typography>
                                        </Grid>
                                    </>
                                    :
                                    <Grid sx={{ borderRight: 0 }} item md={4} xs={4}>
                                        <form onSubmit={handleSubmitWebsiteUrl}>
                                            <Grid container sx={{ marginLeft: 0, marginRight: 0, marginTop: 0 }} direction="row" justifyContent="flex-start" alignItems="top" spacing={1} columns={14}>
                                                <Grid item md={10} xs={14}>
                                                    <TextField value={websiteUrlTemp} onInput={e => setWebsiteUrlTemp(e.target.value)} size="small" fullWidth id="website" label="website" variant="outlined" />
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={3} xs={14}>
                                                    <Button type="submit" size="large" fullWidth variant="contained">set</Button>
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={1} xs={14}>
                                                    <Button size="large" fullWidth variant="outlined" onClick={() => { setWebsiteUrlChanging(false); }}>X</Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>

                        <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                            <Grid container sx={{ paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="caption" align="left" style={{ fontStyle: "italic" }}>Twitter url</Typography></Grid>

                                {!twitterUrlChanging ?
                                    <>
                                        <Grid sx={{ borderRight: 0 }} item md={3} xs={4}>
                                            {twitterUrl === "" || twitterUrl === "https://" || twitterUrl === null ?
                                                <Typography variant="subtitle2" align="left">[complete information]</Typography>
                                                :
                                                <Typography variant="h6" align="left">{twitterUrl}</Typography>
                                            }
                                        </Grid>
                                        <Grid sx={{ borderRight: 0 }} item md={1} xs={1}>
                                            <Typography align="right">
                                                <IconButton disabled={txLoading} aria-label="edit" onClick={() => { setTwitterUrlTemp(twitterUrl); setTwitterUrlChanging(true); }}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Typography>
                                        </Grid>
                                    </>
                                    :
                                    <Grid sx={{ borderRight: 0 }} item md={4} xs={4}>
                                        <form onSubmit={handleSubmitTwitterUrl}>
                                            <Grid container sx={{ marginLeft: 0, marginRight: 0, marginTop: 0 }} direction="row" justifyContent="flex-start" alignItems="top" spacing={1} columns={14}>
                                                <Grid item md={10} xs={14}>
                                                    <TextField value={twitterUrlTemp} onInput={e => setTwitterUrlTemp(e.target.value)} size="small" fullWidth id="twitter" label="twitter" variant="outlined" />
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={3} xs={14}>
                                                    <Button type="submit" size="large" fullWidth variant="contained">set</Button>
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={1} xs={14}>
                                                    <Button size="large" fullWidth variant="outlined" onClick={() => { setTwitterUrlChanging(false); }}>X</Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>

                        <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                            <Grid container sx={{ paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="caption" align="left" style={{ fontStyle: "italic" }}>Instagram url</Typography></Grid>

                                {!instagramUrlChanging ?
                                    <>
                                        <Grid sx={{ borderRight: 0 }} item md={3} xs={4}>
                                            {instagramUrl === "" || instagramUrl === "https://" || instagramUrl === null ?
                                                <Typography variant="subtitle2" align="left">[complete information]</Typography>
                                                :
                                                <Typography variant="h6" align="left">{instagramUrl}</Typography>
                                            }
                                        </Grid>
                                        <Grid sx={{ borderRight: 0 }} item md={1} xs={1}>
                                            <Typography align="right">
                                                <IconButton disabled={txLoading} aria-label="edit" onClick={() => { setInstagramUrlTemp(instagramUrl); setInstagramUrlChanging(true); }}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Typography>
                                        </Grid>
                                    </>
                                    :
                                    <Grid sx={{ borderRight: 0 }} item md={4} xs={4}>
                                        <form onSubmit={handleSubmitInstagramUrl}>
                                            <Grid container sx={{ marginLeft: 0, marginRight: 0, marginTop: 0 }} direction="row" justifyContent="flex-start" alignItems="top" spacing={1} columns={14}>
                                                <Grid item md={10} xs={14}>
                                                    <TextField value={instagramUrlTemp} onInput={e => setInstagramUrlTemp(e.target.value)} size="small" fullWidth id="instagram" label="instagram" variant="outlined" />
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={3} xs={14}>
                                                    <Button type="submit" size="large" fullWidth variant="contained">set</Button>
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={1} xs={14}>
                                                    <Button size="large" fullWidth variant="outlined" onClick={() => { setInstagramUrlChanging(false); }}>X</Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>









                    </Grid>
                </Box>
            </Modal>


        </div>
    )
}
export default Profile;