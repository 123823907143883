import React from 'react';

import CreatePersoInfos from '../components/CreatePersoInfos';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import './styles/Home.css';


function PersonalInfos() {

    return (
        <div className="App-body">

            <br />
            <Box sx={{ width: '90%', marginTop: 7 }}>
                <React.Fragment>
                    <Typography variant="h4" align="left" sx={{ mt: 2, mb: 1 }}>Personnal information</Typography>
                    <Typography variant="subtitle1" align="left" sx={{ mt: 2, mb: 1 }}>Complete your personnal information, this helps us to ensure a premium service.</Typography>
                    <CreatePersoInfos />
                </React.Fragment>
            </Box>

        </div>
    )
}

export default PersonalInfos;