import React, { useState, useEffect } from 'react';
import axios from 'axios';

//import { useLocation } from 'react-router-dom';
//import { Link } from "react-router-dom";
//import Box from '@mui/material/Box';
//import Card from '@mui/material/Card';
//import CardMedia from '@mui/material/CardMedia';
//import Avatar from '@mui/material/Avatar';
//import CardContent from '@mui/material/CardContent';
//import Typography from '@mui/material/Typography';
//import Grid from '@mui/material/Unstable_Grid2';
//import Button from '@mui/material/Button';
//import { styled } from '@mui/material/styles';
//import Paper from '@mui/material/Paper';

import FeaturedBox from '../components/FeaturedBox';

import Zoom from '@mui/material/Zoom';

import ExploreIcon from '@mui/icons-material/Explore';


import './styles/Home.css';

import { UserContext } from '../App';
import { Typography, Button, Tooltip } from '@mui/material';


function Home() {

    const { chain_img, chain_name, api_url } = React.useContext(UserContext);

    //const rout = useLocation();
    const [projectId, setprojectId] = useState("");
    //const [userId, setuserId] = useState(null);
    const [artwork_img, setartwork_img] = useState("");
    const [artwork_title, setartwork_title] = useState("");
    const [artist_name, setartist_name] = useState("");
    const [network, setnetwork] = useState("");
    const [pricePerToken, setpricePerToken] = useState("");
    const [maxSupply, setmaxSupply] = useState("");
    const [contract_address, setcontract_address] = useState("");
    const [creationDate, setCreationDate] = useState("");
    const [artworkBanner, setArtworkBanner] = useState("");
    const [artworkPresentation, setArtworkPresentation] = useState("");
    
    function consoleVariables() {
        console.log(contract_address)
    }

    useEffect(() => {
        const interval = setInterval(() => {
            consoleVariables()
            axios.get(api_url + "/getFeaturedProject").then((response) => {
                //console.log(response)
                setprojectId(response.data.project.id)
                //setuserId(response.data.project.userId)
                setartwork_img(response.data.project.imgUrl)
                setartwork_title(response.data.project.title)
                setArtworkBanner(response.data.project.bannerUrl)
                setArtworkPresentation(response.data.project.presentation)
                setcontract_address(response.data.project.smartContractAddress)
                setnetwork(response.data.project.network)
                //console.log("creationDate :", response.data.project.creationDate)
                setCreationDate(response.data.project.creationDate)
                setmaxSupply(response.data.project.maxSupply)
                setpricePerToken(response.data.project.unitPrice)
                axios.get(api_url + "/users/" + response.data.project.userId, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response2) => {
                    setartist_name(response2.data.user.name)
                    //console.log(response2.data.user)
                    clearInterval(interval)
                });
            });
            //console.log("getting featured project")
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);


    return (
        <>   
            <header className="App-header">  
                <FeaturedBox network={network} creationDate={creationDate} pricePerToken={pricePerToken} maxSupply={maxSupply} chain_img={chain_img} chain_name={chain_name} artworkPresentation={artworkPresentation} artworkBanner={artworkBanner} projectId={projectId} artwork_img={artwork_img} artwork_title={artwork_title} artist_name={artist_name} />
                <Tooltip sx={{marginTop: 10}} title={<Typography fontSize={20}>coming soon</Typography>} placement="top" arrow TransitionComponent={Zoom}>
                    <Button variant="outlined" size="large" style={{width: '75%'}} startIcon={<ExploreIcon />}>
                        Explore
                    </Button>
                </Tooltip>
                {/*<Box 
                    sx={{
                    color: 'text.primary',
                    fontFamily: 'Arial',
                    fontWeight: 'medium',
                    width: '100%',
                    borderRadius: '0px',
                    boxShadow: 0,
                    paddingTop: 5,
                    paddingBottom: 5,
                    marginBottom: 0,
                    }}
                    style={{background: 'linear-gradient(to bottom, #ffc9fb, #ffc9fb)'}}
                ><br/><br/><br/>
                </Box>*/}
                {/*<Button variant="contained" style={{width: "75%"}}><RocketLaunchIcon style={{ color: "#fff" }} />&nbsp;launch your collection</Button>*/}
            </header>
        </>
    );
}

export default Home;