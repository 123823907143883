import React from 'react';
//import { useParams } from 'react-router-dom';

//import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import VisibilityIcon from '@mui/icons-material/Visibility';
import DoneIcon from '@mui/icons-material/Done';

const RevealBox = (props) => {

    const { presaleIsOver, saleIsOver, revealIsOver } = props;

    return (
        <div>
            <Box
                className="phase-box"
                sx={{
                    boxShadow: 2
                }}>

                {revealIsOver ?
                    <Stack justifyContent="space-between" direction="row" alignItems="center" gap={1}>
                        <Stack justifyContent="left" direction="row" alignItems="center" gap={1}>
                            <VisibilityIcon style={{ color: "#757575", fontSize: 20 }} />
                            <Typography style={{ color: "#757575" }} variant="subtitle2">COLLECTION IS REVEALED</Typography>
                        </Stack>
                        <DoneIcon style={{ color: "#757575", fontSize: 20 }} />
                    </Stack>
                    :
                    <>
                        {presaleIsOver && saleIsOver ?
                            <Stack direction="row" alignItems="center" gap={1}>
                                <VisibilityIcon style={{ color: "#000" }} />
                                <Typography style={{ color: "#000" }} variant="subtitle2">REVEAL IS COMING</Typography>
                            </Stack>
                            :
                            <Stack direction="row" alignItems="center" gap={1}>
                                <VisibilityIcon style={{ color: "#757575" }} />
                                <Typography style={{ color: "#757575" }} variant="subtitle2">COLLECTION IS NOT REVEALED YET</Typography>
                            </Stack>
                        }
                    </>
                }
            </Box>
        </div >
    );
};

export default RevealBox;