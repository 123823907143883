import React, { useState, useEffect } from 'react';
import axios from 'axios';
//import { useParams } from 'react-router-dom';

//import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';

import VisibilityIcon from '@mui/icons-material/Visibility';
import TuneIcon from "@mui/icons-material/Tune";
import InfoIcon from '@mui/icons-material/Info';
import FastForwardIcon from '@mui/icons-material/FastForward';
import StarIcon from '@mui/icons-material/Star';
import LoopIcon from '@mui/icons-material/Loop';

import Avatar from "boring-avatars";


import { ethers } from "ethers";

//import { UserContext } from '../App';

//import CustomCountdown from './CustomCountdown';

const AdminControlPannel = (props) => {

    const { chain_name, network, sameNetwork, revealOption, presaleOption, saleOption, api_url, setPresaleData, presaleData, projectId, artwork_title, artwork_img, contract_address, presaleIsActive, setPresaleIsActive, abi, presaleIsOver, setPresaleIsOver, saleIsActive, setSaleIsActive, saleIsOver, setSaleIsOver, revealIsOver, setRevealIsOver, txLoading, setTxLoading } = props;

    const [addressesToAdd, setAddressesToAdd] = useState("");
    const [numberNftToAdd, setNumberNftToAdd] = useState(0);
    const [presaleAddMessage, setPresaleAddMessage] = useState("");

    useEffect(() => {
    }, []);


    const txnCheck = async (txnHash) => {

        const provider = new ethers.providers.Web3Provider(window.ethereum)
        await provider.send("eth_requestAccounts", []);
        let txn_test = await provider.getTransaction(txnHash);
        if (txn_test) {
            if (txn_test.blockNumber) {
                console.log("txn_test :", txn_test);
                return txn_test
            }
        }

    }

    async function reversePresaleState() {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();

        const SmartContract = new ethers.Contract(contract_address, abi, signer);

        const tx = await SmartContract.reversePresaleState();
        console.log(`Transaction hash: ${tx.hash}`);
        console.log(tx)
        setTxLoading(true)

        const interval = setInterval(() => {
            txnCheck(tx.hash).then((response) => {
                try {
                    console.log("res : ", response.hash)
                    axios.get(api_url + "/getContractStatus/" + projectId, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response1) => {
                        setPresaleIsActive(response1.data.data.presaleIsActive)
                        setSaleIsActive(response1.data.data.saleIsActive)
                        setRevealIsOver(response1.data.data.revealed)

                        console.log("presaleIsActive :", response1.data.data.presaleIsActive)

                        axios.put(api_url + "/projects/" + projectId, { presaleIsActive: response1.data.data.presaleIsActive }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response87) => {
                            console.log(response87.data)
                        });

                        
                        clearInterval(interval)
                        setTxLoading(false)
                    });
                } catch (error) {
                    //console.error(error)
                }
            });
            console.log("reverse presale on going")
        }, 1000);
    }

    async function reverseSaleState() {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();

        const SmartContract = new ethers.Contract(contract_address, abi, signer);

        const tx = await SmartContract.reverseSaleState();
        console.log(`Transaction hash: ${tx.hash}`);
        setTxLoading(true)

        const interval = setInterval(() => {
            txnCheck(tx.hash).then((response) => {
                try {
                    console.log("res : ", response.hash)
                    
                    axios.get(api_url + "/getContractStatus/" + projectId, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response1) => {
                        setPresaleIsActive(response1.data.data.presaleIsActive)
                        setSaleIsActive(response1.data.data.saleIsActive)
                        setRevealIsOver(response1.data.data.revealed)

                        console.log("saleIsActive :", response1.data.data.saleIsActive)

                        axios.put(api_url + "/projects/" + projectId, { saleIsActive: response1.data.data.saleIsActive }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response87) => {
                            console.log(response87.data)
                        });

                        
                        setTxLoading(false)
                        clearInterval(interval)
                    });
                } catch (error) {
                    //console.error(error)
                }
            });
            console.log("reverse sale on going")
        }, 1000);
    }

    async function reveal() {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();

        const SmartContract = new ethers.Contract(contract_address, abi, signer);

        const tx = await SmartContract.reveal();
        console.log(`Transaction hash: ${tx.hash}`);
        setTxLoading(true)

        const interval = setInterval(() => {
            txnCheck(tx.hash).then((response) => {
                try {
                    console.log("res : ", response.hash)
                    axios.get(api_url + "/getContractStatus/" + projectId, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response1) => {
                        setPresaleIsActive(response1.data.data.presaleIsActive)
                        setSaleIsActive(response1.data.data.saleIsActive)
                        setRevealIsOver(response1.data.data.revealed)

                        axios.put(api_url + "/projects/" + projectId, { isRevealed: response1.data.data.revealed }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response87) => {
                            console.log(response87.data)
                        });

                        
                        setTxLoading(false)
                        clearInterval(interval)
                    });
                } catch (error) {
                    //console.error(error)
                }
            });
            console.log("reveal on going")
        }, 1000);
    }



    async function startPresale() {
        reversePresaleState()
    }

    async function pausePresale() {
        reversePresaleState()
    }

    async function endPresale() {
        axios.put(api_url + "/projects/" + projectId, { presaleIsOver: true }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
            console.log(response.data)
            setPresaleIsOver(true)
            
        });
    }

    async function reopenPresale() {
        axios.put(api_url + "/projects/" + projectId, { presaleIsOver: false }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
            console.log(response.data)
            setPresaleIsOver(false)
            
        });
    }

    async function startSale() {
        reverseSaleState()
    }

    async function pauseSale() {
        reverseSaleState()
    }

    async function endSale() {
        axios.put(api_url + "/projects/" + projectId, { saleIsOver: true }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
            console.log(response.data)
            setSaleIsOver(true)
            
        });
    }

    async function reopenSale() {
        axios.put(api_url + "/projects/" + projectId, { saleIsOver: false }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
            console.log(response.data)
            setSaleIsOver(false)
            
        });
    }

    async function handleSubmitPresaleAdd(event) {
        try {
            event.preventDefault();
            console.log('addresses:', addressesToAdd, 'number: ', numberNftToAdd);

            const array = addressesToAdd;
            const num = parseInt(numberNftToAdd);
            console.log(array);
            console.log(num)

            const provider = new ethers.providers.Web3Provider(window.ethereum)
            await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner();

            const SmartContract = new ethers.Contract(contract_address, abi, signer);

            const tx = await SmartContract.setPresaleList([array], num);
            console.log(`Transaction hash: ${tx.hash}`);
            setTxLoading(true)

            const interval = setInterval(() => {
                txnCheck(tx.hash).then((response) => {
                    try {
                        console.log("res : ", response.hash)
                        axios.post(api_url + "/whitelists", {walletAddress: array, projectId: projectId, amountSaved: num}, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response6) => {
                            console.log(response6)
                            axios.get(api_url + "/getPresaleInfos/" + projectId, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response5) => {
                                setPresaleData(response5.data.data)
                                setTxLoading(false)
                                clearInterval(interval)
                                console.log("address added")
                                setPresaleAddMessage("");
                            });
                        });
                    } catch (error) {
                        setPresaleAddMessage();
                    }
                });
                console.log("add to whitelist on going")
            }, 1000);

        } catch (error) {
            console.error(error)
            setPresaleAddMessage("check format, this creates an error");
        }
    }

    return (
        <div>
            <Box>

                <Stack sx={{ marginLeft: 3, marginBottom: 3 }} direction="row" alignItems="center" gap={1}>
                    {artwork_img === "" || artwork_img === null ?
                        <Avatar
                            size={50}
                            name={projectId}
                            square
                        />
                        :
                        <img alt="logo" width={50} height={50} src={artwork_img} />
                    }
                    <Typography style={{ color: "#000" }} variant="h4">&nbsp;{artwork_title}</Typography>
                </Stack>

                <Stack sx={{ padding: 2, marginLeft: 3, marginRight: 3, marginBottom: 0, border: 0, borderRadius: 2, backgroundColor: "#E3F2FD", color: "#000" }} direction="row" alignItems="center" gap={1}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <TuneIcon fontSize="large" />
                        <Typography variant="h3">CONTROL PANEL</Typography>
                    </Stack>
                </Stack>

                {sameNetwork ?
                    <></>
                    :
                    <Box sx={{
                        borderRadius: '5px',
                        boxShadow: 0,
                        paddingTop: 1,
                        paddingBottom: 1,
                        paddingRight: 1,
                        paddingLeft: 1,
                        margin: 3,
                        backgroundColor: '#000',
                        color: "#fff"
                        }}>
                            <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
                                <LoopIcon style={{ color: "#fff" }} />
                                <Typography style={{ color: "#fff" }} variant="button">switch network to {chain_name[network]}</Typography>
                            </Stack>
                        </Box>
                }








                <Box sx={{
                    borderRadius: '5px',
                    boxShadow: 0,
                    paddingTop: 1,
                    paddingBottom: 1,
                    paddingRight: 1,
                    paddingLeft: 1,
                    marginLeft: 3,
                    marginRight: 3,
                    marginTop: 3,
                    backgroundColor: '#F5F5F5'
                }}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <FastForwardIcon style={{ color: "#000" }} />
                        <Typography style={{ color: "#000" }} variant="button">mint steps</Typography>
                    </Stack>

                    {txLoading ?
                        <Stack direction="row" alignItems="center" gap={1}>
                            <CircularProgress color="inherit" size={20} />
                            <Typography style={{ color: "#888" }} sx={{ fontStyle: 'italic' }} variant="button">applying changes, don't refresh your web browser</Typography>
                        </Stack>
                        :
                        <></>
                    }

                    <Grid container sx={{ margin: 3 }} direction="row" justifyContent="center" alignItems="center" spacing={1} columns={2}>


                        {presaleOption ?
                            <Grid sx={{ borderRight: 0 }} item md={1} xs={2}>

                                <Stack justifyContent="center" direction="row" alignItems="center" gap={1}>
                                    <Typography style={{ color: "#000" }} variant="h4">PRESALE</Typography>
                                    <Tooltip title="Opening and closing the presale activate or deactivate the 'presale' box on the minting page. Note that starting or pausing the presale interacts directly with your smart contract." placement="right">
                                        <InfoIcon style={{ color: "#888" }}/>
                                    </Tooltip>
                                </Stack>
                                <Stack sx={{ marginTop: 0 }} direction="row" justifyContent="center" alignItems="center" gap={1}>
                                    {presaleIsActive ?
                                        <>
                                            <Box sx={{ border: 0, borderRadius: '5px', backgroundColor: '#000', paddingTop: 0, paddingBottom: 0, paddingRight: 2, paddingLeft: 2 }}><Typography style={{ color: "#fff" }} variant="h6">ON</Typography></Box>
                                            <Box sx={{ border: 0, borderRadius: '5px', backgroundColor: '#ccc', paddingTop: 0, paddingBottom: 0, paddingRight: 2, paddingLeft: 2 }}><Typography style={{ color: "#000" }} variant="h6">OFF</Typography></Box>
                                            <Box sx={{ border: 0, borderRadius: '5px', backgroundColor: '#ccc', paddingTop: 0, paddingBottom: 0, paddingRight: 2, paddingLeft: 2 }}><Typography style={{ color: "#000" }} variant="h6">OVER</Typography></Box>

                                        </>
                                        :
                                        <>
                                            {presaleIsOver ?
                                                <>
                                                    <Box sx={{ border: 0, borderRadius: '5px', backgroundColor: '#ccc', paddingTop: 0, paddingBottom: 0, paddingRight: 2, paddingLeft: 2 }}><Typography style={{ color: "#000" }} variant="h6">ON</Typography></Box>
                                                    <Box sx={{ border: 0, borderRadius: '5px', backgroundColor: '#ccc', paddingTop: 0, paddingBottom: 0, paddingRight: 2, paddingLeft: 2 }}><Typography style={{ color: "#000" }} variant="h6">OFF</Typography></Box>
                                                    <Box sx={{ border: 0, borderRadius: '5px', backgroundColor: '#000', paddingTop: 0, paddingBottom: 0, paddingRight: 2, paddingLeft: 2 }}><Typography style={{ color: "#fff" }} variant="h6">OVER</Typography></Box>
                                                </>
                                                :
                                                <>
                                                    <Box sx={{ border: 0, borderRadius: '5px', backgroundColor: '#ccc', paddingTop: 0, paddingBottom: 0, paddingRight: 2, paddingLeft: 2 }}><Typography style={{ color: "#000" }} variant="h6">ON</Typography></Box>
                                                    <Box sx={{ border: 0, borderRadius: '5px', backgroundColor: '#000', paddingTop: 0, paddingBottom: 0, paddingRight: 2, paddingLeft: 2 }}><Typography style={{ color: "#fff" }} variant="h6">OFF</Typography></Box>
                                                    <Box sx={{ border: 0, borderRadius: '5px', backgroundColor: '#ccc', paddingTop: 0, paddingBottom: 0, paddingRight: 2, paddingLeft: 2 }}><Typography style={{ color: "#000" }} variant="h6">OVER</Typography></Box>
                                                </>
                                            }
                                        </>
                                    }
                                </Stack>



                                <Box sx={{
                                    borderRadius: '10px',
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    paddingRight: 1,
                                    paddingLeft: 1,
                                    marginLeft: 2,
                                    marginRight: 2,
                                    marginTop: 5,
                                    backgroundColor: '#dbdbdb'
                                }}>
                                    <Grid container sx={{ marginTop: 0, marginRight: 0, marginLeft: 0 }} direction="row" justifyContent="space-between" alignItems="center" spacing={0} columns={1}>
                                        <Grid item md={1} xs={1}>
                                            <Button sx={{ marginTop : 1, marginBottom : 1 }} fullWidth color="warning" variant="contained" onClick={reopenPresale} disabled={!sameNetwork || txLoading || presaleIsActive || !presaleIsOver || saleIsActive || saleIsOver}>(re)Open</Button>
                                        </Grid>
                                        <Grid item md={1} xs={1}>
                                            <Grid container sx={{ marginTop: 0 }} direction="row" justifyContent="space-between" alignItems="center" spacing={1} columns={2}>
                                                <Grid item md={1} xs={2}>
                                                    <Button sx={{ marginBottom : 1 }} fullWidth variant="contained" onClick={startPresale} disabled={!sameNetwork || txLoading || presaleIsActive || presaleIsOver || saleIsActive || saleIsOver} >Start 🦊</Button>
                                                </Grid>
                                                <Grid item md={1} xs={2}>
                                                    <Button sx={{ marginBottom : 1 }} fullWidth variant="contained" onClick={pausePresale} disabled={!sameNetwork || txLoading || !presaleIsActive || presaleIsOver || saleIsActive || saleIsOver} >Pause 🦊</Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item md={1} xs={1}>
                                            <Button sx={{ marginBottom : 1 }}fullWidth color="error" variant="contained" onClick={endPresale} disabled={!sameNetwork || txLoading || presaleIsActive || presaleIsOver || saleIsActive || saleIsOver}>Close</Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            :
                            <></>
                        }

                        {saleOption ?
                            <Grid item md={1} xs={2}>
                                <Stack justifyContent="center" direction="row" alignItems="center" gap={1}>
                                    <Typography style={{ color: "#000" }} variant="h4">SALE</Typography>
                                    <Tooltip title="Opening and closing the sale activate or deactivate the 'sale' box on the minting page. Note that starting or pausing the sale interacts directly with your smart contract." placement="right">
                                        <InfoIcon style={{ color: "#888" }} />
                                    </Tooltip>
                                </Stack>

                                <Stack sx={{ marginTop: 0 }} direction="row" justifyContent="center" alignItems="center" gap={1}>
                                    {saleIsActive ?
                                        <>
                                            <Box sx={{ border: 0, borderRadius: '5px', backgroundColor: '#000', paddingTop: 0, paddingBottom: 0, paddingRight: 2, paddingLeft: 2 }}><Typography style={{ color: "#fff" }} variant="h6">ON</Typography></Box>
                                            <Box sx={{ border: 0, borderRadius: '5px', backgroundColor: '#ccc', paddingTop: 0, paddingBottom: 0, paddingRight: 2, paddingLeft: 2 }}><Typography style={{ color: "#000" }} variant="h6">OFF</Typography></Box>
                                            <Box sx={{ border: 0, borderRadius: '5px', backgroundColor: '#ccc', paddingTop: 0, paddingBottom: 0, paddingRight: 2, paddingLeft: 2 }}><Typography style={{ color: "#000" }} variant="h6">OVER</Typography></Box>
                                        </>
                                        :
                                        <>
                                            {saleIsOver ?
                                                <>
                                                    <Box sx={{ border: 0, borderRadius: '5px', backgroundColor: '#ccc', paddingTop: 0, paddingBottom: 0, paddingRight: 2, paddingLeft: 2 }}><Typography style={{ color: "#000" }} variant="h6">ON</Typography></Box>
                                                    <Box sx={{ border: 0, borderRadius: '5px', backgroundColor: '#ccc', paddingTop: 0, paddingBottom: 0, paddingRight: 2, paddingLeft: 2 }}><Typography style={{ color: "#000" }} variant="h6">OFF</Typography></Box>
                                                    <Box sx={{ border: 0, borderRadius: '5px', backgroundColor: '#000', paddingTop: 0, paddingBottom: 0, paddingRight: 2, paddingLeft: 2 }}><Typography style={{ color: "#fff" }} variant="h6">OVER</Typography></Box>
                                                </>
                                                :
                                                <>
                                                    <Box sx={{ border: 0, borderRadius: '5px', backgroundColor: '#ccc', paddingTop: 0, paddingBottom: 0, paddingRight: 2, paddingLeft: 2 }}><Typography style={{ color: "#000" }} variant="h6">ON</Typography></Box>
                                                    <Box sx={{ border: 0, borderRadius: '5px', backgroundColor: '#000', paddingTop: 0, paddingBottom: 0, paddingRight: 2, paddingLeft: 2 }}><Typography style={{ color: "#fff" }} variant="h6">OFF</Typography></Box>
                                                    <Box sx={{ border: 0, borderRadius: '5px', backgroundColor: '#ccc', paddingTop: 0, paddingBottom: 0, paddingRight: 2, paddingLeft: 2 }}><Typography style={{ color: "#000" }} variant="h6">OVER</Typography></Box>
                                                </>
                                            }
                                        </>
                                    }
                                </Stack>

                                <Box sx={{
                                    borderRadius: '10px',
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    paddingRight: 1,
                                    paddingLeft: 1,
                                    marginLeft: 2,
                                    marginRight: 2,
                                    marginTop: 5,
                                    backgroundColor: '#dbdbdb'
                                }}>
                                    <Grid container sx={{ marginTop: 0, marginRight: 0, marginLeft: 0 }} direction="row" justifyContent="space-between" alignItems="center" spacing={0} columns={1}>
                                        <Grid item md={1} xs={1}>
                                            <Button sx={{ marginTop : 1, marginBottom : 1 }} fullWidth color="warning" variant="contained" onClick={reopenSale} disabled={!sameNetwork || txLoading || presaleIsActive || !presaleIsOver || saleIsActive || !saleIsOver}>(re)Open</Button>
                                        </Grid>
                                        <Grid item md={1} xs={1}>
                                        
                                            <Grid container sx={{ marginTop: 0 }} direction="row" justifyContent="space-between" alignItems="center" spacing={1} columns={2}>
                                                <Grid item md={1} xs={1}>
                                                    <Button sx={{ marginBottom : 1 }} fullWidth variant="contained" onClick={startSale} disabled={!sameNetwork || txLoading || presaleIsActive || !presaleIsOver || saleIsActive || saleIsOver}>Start 🦊</Button>
                                                </Grid>
                                                <Grid item md={1} xs={1}>
                                                    <Button sx={{ marginBottom : 1 }} fullWidth variant="contained" onClick={pauseSale} disabled={!sameNetwork || txLoading || presaleIsActive || !presaleIsOver || !saleIsActive || saleIsOver}>Pause 🦊</Button>
                                                </Grid>
                                            </Grid>
                                        
                                        </Grid>
                                        <Grid item md={1} xs={1}>
                                            <Button sx={{ marginBottom : 1 }} fullWidth color="error" variant="contained" onClick={endSale} disabled={!sameNetwork || txLoading || presaleIsActive || !presaleIsOver || saleIsActive || saleIsOver}>Close</Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            :
                            <></>
                        }
                    </Grid>

                </Box>




                {revealOption ?
                    <Box sx={{
                        borderRadius: '5px',
                        boxShadow: 0,
                        paddingTop: 1,
                        paddingBottom: 1,
                        paddingRight: 1,
                        paddingLeft: 1,
                        marginLeft: 3,
                        marginRight: 3,
                        marginTop: 3,
                        backgroundColor: '#F5F5F5'
                    }}>
                        <Stack direction="row" alignItems="center" gap={1}>
                            <VisibilityIcon style={{ color: "#000" }} />
                            <Typography style={{ color: "#000" }} variant="button">reveal</Typography>
                        </Stack>

                        {txLoading ?
                            <Stack direction="row" alignItems="center" gap={1}>
                                <CircularProgress color="inherit" size={20} />
                                <Typography style={{ color: "#888" }} sx={{ fontStyle: 'italic' }} variant="button">applying changes, don't refresh your web browser</Typography>
                            </Stack>
                            :
                            <></>
                        }

                        <Grid container sx={{ margin: 3 }} direction="row" justifyContent="space-between" alignItems="top" spacing={0} columns={3}>
                            <Grid sx={{ border: 0, paddingTop: 0.5 }} item md={1} xs={3}>
                                <Stack justifyContent="center" direction="row" alignItems="center" gap={1}>
                                    <Typography style={{ color: "#000" }} variant="h4">REVEAL</Typography>
                                    <Tooltip title="Reveal ensures equal chances between holders, and creates hype when revealing the metadata. Whenever the final metadata link is updated, click on the button. Even though you can change the metadata whenever you want, pushing this button cannot be undone." placement="right">
                                        <InfoIcon style={{ color: "#888" }}/>
                                    </Tooltip>
                                </Stack>
                            </Grid>

                            <Grid sx={{ borderRight: 0 }} item md={2} xs={3}>
                                <Grid container sx={{ border: 0, marginTop: 0, marginRight: 2, marginLeft: 2 }} direction="row" justifyContent="space-between" alignItems="center" spacing={0} columns={1}>
                                    <Grid item md={1} xs={1}>
                                        <Grid container sx={{ marginBottom: 0 }} direction="row" justifyContent="space-between" alignItems="center" spacing={0} columns={1}>
                                            <Grid item md={1} xs={1}>
                                                <Button fullWidth variant="contained" onClick={reveal} disabled={!sameNetwork || revealIsOver || txLoading} >reveal 🦊</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    :
                    <></>
                }






                {presaleOption ?
                    <Box sx={{
                        borderRadius: '5px',
                        boxShadow: 0,
                        paddingTop: 1,
                        paddingBottom: 1,
                        paddingRight: 1,
                        paddingLeft: 1,
                        marginLeft: 3,
                        marginRight: 3,
                        marginTop: 3,
                        backgroundColor: '#F5F5F5'
                    }}>
        
                        {presaleIsOver ?
                            <Stack direction="row" alignItems="center" gap={1}>
                                <StarIcon style={{ color: "#888" }} />
                                <Typography style={{ color: "#888" }} variant="button">whitelist</Typography>
                            </Stack>
                            :
                            <Stack direction="row" alignItems="center" gap={1}>
                                <StarIcon style={{ color: "#000" }} />
                                <Typography style={{ color: "#000" }} variant="button">whitelist</Typography>
                            </Stack>
                        }
                        
                        <Stack justifyContent="center" direction="row" alignItems="center" gap={1}>
                            <Typography style={{ color: "#000" }} variant="h4">WHITELIST</Typography>
                            <Tooltip title="The whitelist is the list of wallets that have saved nfts during the presale phase. Add wallets and the amount of nfts associated below." placement="right">
                                <InfoIcon style={{ color: "#888" }}/>
                            </Tooltip>
                        </Stack>

                        {txLoading ?
                            <Stack direction="row" alignItems="center" gap={1}>
                                <CircularProgress color="inherit" size={20} />
                                <Typography style={{ color: "#888" }} sx={{ fontStyle: 'italic' }} variant="button">applying changes, don't refresh your web browser</Typography>
                            </Stack>
                            :
                            <></>
                        }


                        <form onSubmit={handleSubmitPresaleAdd}>
                            <Grid container sx={{ margin: 3 }} direction="row" justifyContent="space-between" alignItems="top" spacing={1} columns={14}>
                                <Grid item md={10} xs={14}>
                                    <TextField disabled={!sameNetwork || presaleIsOver || txLoading} value={addressesToAdd} onInput={e => setAddressesToAdd(e.target.value)} multiline size="small" fullWidth id="addresses" label="wallet addresses" helperText="format: 0x..." variant="outlined" />
                                </Grid>
                                <Grid item md={2} xs={14}>
                                    <TextField disabled={!sameNetwork || presaleIsOver || txLoading} value={numberNftToAdd} onInput={e => setNumberNftToAdd(e.target.value)} size="small" fullWidth id="outlined-basic" label="# of nfts" helperText="ex: 8" variant="outlined" />
                                </Grid>
                                <Grid sx={{ paddingTop: 0 }} item md={2} xs={14}>
                                    <Button disabled={!sameNetwork || presaleIsOver || txLoading} type="submit" size="large" fullWidth variant="contained">add 🦊</Button>
                                </Grid>
                            </Grid>
                        </form>
                        <Box sx={{ marginLeft: 3, marginTop: 1, marginBottom: 1, padding: 0, backgroundColor: "#eee", borderRadius: 1 }}>
                            {presaleAddMessage ?
                                <>
                                    <Divider />
                                    <Stack sx={{ color: "#000" }} direction="row" justifyContent="flex-startfdfs" alignItems="center" gap={1}>
                                        <InfoIcon />
                                        <Typography variant="h6">{presaleAddMessage}</Typography>
                                    </Stack>
                                </>
                                :
                                <>
                                </>
                            }
                        </Box>
                        {presaleData['addresses'] ?
                            <>
                                {presaleData['addresses'].map(({ address, num }) => (
                                    <Grid container sx={{ marginLeft: 3, marginRight: 3, marginTop: 0, marginBottom: 0, borderBottom: 0, borderRight: 0, borderLeft: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={1} columns={14}>
                                        <Grid sx={{ paddingLeft: 2, borderRight: 0 }} item md={10} xs={14}>
                                            <Typography align="left" variant="subtitle2"><span className="metadata">{address}</span></Typography>
                                        </Grid>
                                        <Grid sx={{ paddingLeft: 2 }} item md={4} xs={14}>
                                            <Typography align="left" variant="h6">{num}</Typography>
                                        </Grid>
                                    </Grid>
                                ))}
                            </>
                            :
                            <></>
                        }
                        <Grid container sx={{ marginLeft: 3, marginRight: 3, marginTop: 0, marginBottom: 0, borderBottom: 0, borderRight: 0, borderLeft: 0, borderTop: 1 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={1} columns={14}>
                            <Grid sx={{ paddingLeft: 2, borderRight: 0 }} item md={10} xs={14}>
                                <Typography align="left" variant="h6">{presaleData["totalWallet"]} wallets on whitelist</Typography>
                            </Grid>
                            <Grid sx={{ paddingLeft: 2 }} item md={4} xs={14}>
                                <Typography align="left" variant="h6">{presaleData["totalMint"]} saved nfts</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    :
                    <></>
                }

            </Box>
        </div >
    );
};

export default AdminControlPannel;