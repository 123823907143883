import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom"
import axios from 'axios';

import NotFoundCompo from '../components/NotFoundCompo';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

import EditIcon from '@mui/icons-material/Edit';
import ShieldIcon from '@mui/icons-material/Shield';
import CheckIcon from '@mui/icons-material/Check';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PendingIcon from '@mui/icons-material/Pending';

import { UserContext } from '../App';


const CreatePersoInfos = (props) => {


    const { api_url, account, isSigned } = React.useContext(UserContext);

    const [access, setAccess] = useState(false)

    const [name, setName] = useState("")
    const [nameChanging, setNameChanging] = useState("")
    const [nameTemp, setNameTemp] = useState("")

    const [realName, setRealName] = useState("")
    const [realNameChanging, setRealNameChanging] = useState("")
    const [realNameTemp, setRealNameTemp] = useState("")

    const [email, setEmail] = useState("")
    const [emailChanging, setEmailChanging] = useState("")
    const [emailTemp, setEmailTemp] = useState("")
    const [emailIsValid, setEmailIsValid] = useState(true)

    const [emailVerified, setEmailVerified] = useState(false)
    const [emailCodeTemp, setEmailCodeTemp] = useState("")
    const [emailChecked, setEmailChecked] = useState(true)

    const [emailCodeSent, setEmailCodeSent] = useState("")
    const [waitSend, setWaitSend] = useState(false)

    const [phoneNumber, setPhoneNumber] = useState("")
    const [phoneNumberTemp, setPhoneNumberTemp] = useState("")
    const [phoneNumberChanging, setPhoneNumberChanging] = useState(false)

    const [address1, setAddress1] = useState("")
    const [address2, setAddress2] = useState("")
    const [address3, setAddress3] = useState("")
    const [city, setCity] = useState("")
    const [country, setCountry] = useState("")
    const [state, setState] = useState("")
    const [zipCode, setZipCode] = useState("")

    const [addressChanging, setAddressChanging] = useState("")
    const [address1Temp, setAddress1Temp] = useState("")
    const [address2Temp, setAddress2Temp] = useState("")
    const [address3Temp, setAddress3Temp] = useState("")
    const [cityTemp, setCityTemp] = useState("")
    const [countryTemp, setCountryTemp] = useState("")
    const [stateTemp, setStateTemp] = useState("")
    const [zipCodeTemp, setZipCodeTemp] = useState("")
    const [addressFormatNotValid, setAddressFormatNotValid] = useState(false)
    const [majorProblem, setMajorProblem] = useState(false)

    const [creator, setCreator] = useState(false)
    const [creatorDemand, setCreatorDemand] = useState(false)

    const [verifyProfileWrong, setVerifyProfileWrong] = useState(false)

    function consoleVariables() {
        console.log(verifyProfileWrong)
    }
    
    async function handleSubmitName(event) {
        try {
            event.preventDefault();
            console.log('name: ', nameTemp);
            axios.put(api_url + "/users/" + String(account), { name: nameTemp }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                setName(nameTemp)
                setNameChanging(false)
            });

        } catch (error) {
            console.log(error)
        }
    }

    async function handleSubmitRealName(event) {
        try {
            event.preventDefault();
            console.log('real name: ', realNameTemp);
            axios.put(api_url + "/users/" + String(account), { realName: realNameTemp }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                setRealName(realNameTemp)
                setRealNameChanging(false)
            });

        } catch (error) {
            console.log(error)
        }
    }

    async function handleSubmitPhoneNumber(event) {
        try {
            event.preventDefault();
            console.log('phone number: ', phoneNumberTemp);
            axios.put(api_url + "/users/" + String(account), { phoneNumber: phoneNumberTemp }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                setPhoneNumber(phoneNumberTemp)
                setPhoneNumberChanging(false)
            });

        } catch (error) {
            console.log(error)
        }
    }

    async function handleSubmitEmail(event) {
        try {
            event.preventDefault();
            console.log('email: ', emailTemp);
            console.log("email valid: ", isValidEmail(emailTemp))
            if (isValidEmail(emailTemp)) {
                setEmailIsValid(true)
                axios.put(api_url + "/users/" + String(account), { email: emailTemp }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                    console.log(response)
                    setEmail(emailTemp)
                    setEmailChanging(false)
                    axios.get(api_url + "/users/" + String(account), { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response1) => {
                        console.log(response1)
                        if (response1.data.status === "success") {
                            setEmailVerified(response1.data.user.emailVerified)
                        }
                    })
                });
            } else {
                setEmailIsValid(false)
            }

        } catch (error) {
            console.log(error)
        }
    }

    function isValidEmail(emailTemp) {
        return /\S+@\S+\.\S+/.test(emailTemp);
    }


    async function handleSubmitEmailCode(event) {
        try {
            event.preventDefault();
            console.log('code: ', emailCodeTemp);
            axios.put(api_url + "/verifyUserEmail/" + String(account), { emailCode: emailCodeTemp }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                console.log(response.data.status)
                if (response.data.status === "success") {
                    setEmailVerified(true)
                    setEmailChecked(true)
                    setEmailCodeTemp("")
                    setWaitSend(false)
                } else {
                    setEmailChecked(false)
                    setWaitSend(false)
                }
            });

        } catch (error) {
            console.log(error)
        }
    }

    function sendEmailCode() {
        try {
            console.log('email: ', email);
            axios.put(api_url + "/sendEmailCode/" + String(account), {}, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                console.log(response)
                if (response.data.status === "success") {
                    setWaitSend(false)
                    axios.get(api_url + "/users/" + String(account), { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response1) => {
                        console.log(response1)
                        if (response1.data.status === "success") {
                            setEmailCodeSent(response1.data.user.emailCodeSent)
                            setEmailVerified(response1.data.user.emailVerified)
                            setEmailCodeTemp("")
                        }
                    })
                } else if (response.data.alert === "wait") {
                    setWaitSend(true)
                    setEmailChecked(true)
                }
            });

        } catch (error) {
            console.log(error)
        }
    }

    async function handleSubmitAddress(event) {
        try {
            event.preventDefault();
            console.log('address: ', address1Temp);
            axios.post(api_url + "/addresses", { walletAddress: String(account), address1: address1Temp, address2: address2Temp, address3: address3Temp, zipCode: zipCodeTemp, city: cityTemp, state: stateTemp, country: countryTemp }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                console.log(response)

                if (response.data.status === "fail") {
                    if (response.data.message === "Address already exists") {
                        axios.put(api_url + "/addresses/" + String(account), { address1: address1Temp, address2: address2Temp, address3: address3Temp, zipCode: zipCodeTemp, city: cityTemp, state: stateTemp, country: countryTemp }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response2) => {
                            console.log(response2)
                            if (response2.data.status === "success") {
                                setAddressFormatNotValid(false)
                                setMajorProblem(false)
                                setAddress1(address1Temp)
                                setAddress2(address2Temp)
                                setAddress3(address3Temp)
                                setZipCode(zipCodeTemp)
                                setCity(cityTemp)
                                setState(stateTemp)
                                setCountry(countryTemp)
                                setAddressChanging(false)
                            } else {
                                if (response2.data.message === "address format is not ok") {
                                    setAddressFormatNotValid(true)
                                    setMajorProblem(false)
                                } else {
                                    setAddressFormatNotValid(false)
                                    setMajorProblem(true)
                                }
                            }
                        });
                    } else if (response.data.message === "address format is not ok") {
                        setAddressFormatNotValid(true)
                        setMajorProblem(false)
                    } else {
                        setAddressFormatNotValid(false)
                        setMajorProblem(true)
                    }
                } else {
                    setAddressFormatNotValid(false)
                    setMajorProblem(false)
                    setAddress1(address1Temp)
                    setAddress2(address2Temp)
                    setAddress3(address3Temp)
                    setZipCode(zipCodeTemp)
                    setCity(cityTemp)
                    setState(stateTemp)
                    setCountry(countryTemp)
                    setAddressChanging(false)
                }

            });

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        console.log(api_url + "/users/" + String(account))
        consoleVariables()
        setEmailChanging(false)
        setNameChanging(false)
        setRealNameChanging(false)
        setEmailChecked(true)
        setEmail("")
        setRealName("")
        setEmailVerified(true)
        setEmailCodeSent("")
        setAddressChanging(false)
        setAddress1("")
        setAddress2("")
        setAddress3("")
        setCity("")
        setCountry("")
        setState("")
        setZipCode("")
        setMajorProblem(false)
        setAddressFormatNotValid(false)
        setEmailIsValid(true)
        setCreator(false)
        setCreatorDemand(false)
        setVerifyProfileWrong(false)
        setPhoneNumber("")
        setPhoneNumberChanging(false)

        axios.get(api_url + "/users/" + String(account), { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
            console.log(response)
            if (response.data.status === "success") {
                setAccess(true)
                setName(response.data.user.name)
                if (response.data.group === "owner" || response.data.group === "admin") {
                    axios.get(api_url + "/addresses/" + String(account), { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response2) => {
                        console.log("response2 :", response2)
                        if (response2.data.status === "success") {
                            console.log("SUCCESS")
                            setAddress1(response2.data.address.address1)
                            setAddress2(response2.data.address.address2)
                            setAddress3(response2.data.address.address3)
                            setCity(response2.data.address.city)
                            setCountry(response2.data.address.country)
                            setState(response2.data.address.state)
                            setZipCode(response2.data.address.zipCode)
                        }
                    })
                    setRealName(response.data.user.realName)
                    setEmail(response.data.user.email)
                    setEmailVerified(response.data.user.emailVerified)
                    setEmailCodeSent(response.data.user.emailCodeSent)
                    setCreator(response.data.user.creator)
                    setCreatorDemand(response.data.user.creatorDemand)
                    setPhoneNumber(response.data.user.phoneNumber)
                }
            } else {
                setAccess(false)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, api_url, isSigned]);


    function verifyProfile() {
        try {
            axios.put(api_url + "/modifyCreatorDemand/" + String(account), {}, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                console.log(response)
                if (response.data.status === "success") {
                    setVerifyProfileWrong(false)
                    axios.get(api_url + "/users/" + String(account), { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response2) => {
                        console.log(response2)
                        if (response2.data.status === "success") {
                            setCreator(response2.data.user.creator)
                            setCreatorDemand(response2.data.user.creatorDemand)
                        }
                    })
                } else {
                    setVerifyProfileWrong(true)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    async function handleModifyInformation(event) {
        try {
            event.preventDefault();
            axios.put(api_url + "/modifyCreatorDemand/" + String(account), {}, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                console.log(response)
                if (response.data.status === "success") {
                    setVerifyProfileWrong(false)
                    axios.put(api_url + "/modifyCreator/" + String(account), {}, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response2) => {
                        console.log(response2)
                        if (response2.data.status === "success") {
                            setVerifyProfileWrong(false)
                            axios.get(api_url + "/users/" + String(account), { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response3) => {
                                console.log(response3)
                                if (response3.data.status === "success") {
                                    setCreator(response3.data.user.creator)
                                    setCreatorDemand(response3.data.user.creatorDemand)
                                }
                            })
                        } else {
                            axios.get(api_url + "/users/" + String(account), { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response3) => {
                                console.log(response3)
                                if (response3.data.status === "success") {
                                    setCreator(response3.data.user.creator)
                                    setCreatorDemand(response3.data.user.creatorDemand)
                                }
                            })
                        }
                    })
                } else {
                    setVerifyProfileWrong(false)
                }
            })

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            {access ?
                <Grid container sx={{ marginTop: 3, marginBottom: 3 }} direction="row" justifyContent="space-between" alignItems="center" spacing={0} columns={1}>

                    <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                        <Grid container sx={{ paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                            <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>id</Typography></Grid>

                            <Grid sx={{ borderRight: 0 }} item md={3} xs={4}>
                                <Typography variant="subtitle1" align="left">{account}</Typography>
                            </Grid>

                        </Grid>
                    </Grid>



                    <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                        <Grid container sx={{ paddingBottom: 4, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                            <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>pseudo</Typography></Grid>

                            {!nameChanging ?
                                <>
                                    <Grid sx={{ borderRight: 0 }} item md={3} xs={4}>
                                        <Typography variant="subtitle1" align="left">{name}</Typography>
                                    </Grid>
                                    <Grid sx={{ borderRight: 0 }} item md={1} xs={1}>
                                        <Typography align="right">
                                            <IconButton disabled={!isSigned} aria-label="edit" onClick={() => { setNameTemp(name); setNameChanging(true); }}>
                                                <EditIcon />
                                            </IconButton>
                                        </Typography>
                                    </Grid>

                                </>
                                :
                                <Grid sx={{ borderRight: 0 }} item md={4} xs={4}>
                                    <form onSubmit={handleSubmitName}>
                                        <Grid container sx={{ marginLeft: 0, marginRight: 0, marginTop: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={1} columns={14}>
                                            <Grid item md={10} xs={14}>
                                                <TextField value={nameTemp} onInput={e => setNameTemp(e.target.value)} size="small" fullWidth id="name" label="name" variant="outlined" />
                                            </Grid>
                                            <Grid sx={{ paddingTop: 0 }} item md={3} xs={14}>
                                                <Button type="submit" size="large" fullWidth variant="contained">set</Button>
                                            </Grid>
                                            <Grid sx={{ paddingTop: 0 }} item md={1} xs={14}>
                                                <Button size="large" fullWidth variant="outlined" onClick={() => { setNameChanging(false); }}>X</Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                            }
                        </Grid>
                    </Grid>


                    <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                        <Divider textAlign="center">
                            <Stack justifyContent="center" direction="row" alignItems="center" gap={1}>
                                <ShieldIcon style={{ color: "#000" }} />
                                <Typography variant="subtitle2">confidential info (this will never appear anywhere)</Typography>
                            </Stack>
                        </Divider>

                        <Grid container sx={{ paddingTop: 2, paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                            <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>real name</Typography></Grid>

                            {!realNameChanging ?
                                <>
                                    <Grid sx={{ borderRight: 0 }} item md={3} xs={4}>
                                        {isSigned ?
                                            <>
                                                {realName === "" || realName === null ?
                                                    <Typography variant="subtitle2" align="left">[complete information]</Typography>
                                                    :
                                                    <Typography variant="subtitle1" align="left">{realName}</Typography>
                                                }
                                            </>
                                            :
                                            <Typography variant="subtitle2" align="left">[connect wallet to edit]</Typography>
                                        }
                                    </Grid>
                                    {creator || creatorDemand ?
                                        <></>
                                        :
                                        <Grid sx={{ borderRight: 0 }} item md={1} xs={1}>
                                            <Typography align="right">
                                                <IconButton disabled={!isSigned} aria-label="edit" onClick={() => { setRealNameTemp(realName); setRealNameChanging(true); }}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Typography>
                                        </Grid>
                                    }
                                </>
                                :
                                <Grid sx={{ borderRight: 0 }} item md={4} xs={4}>
                                    <form onSubmit={handleSubmitRealName}>
                                        <Grid container sx={{ marginLeft: 0, marginRight: 0, marginTop: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={1} columns={14}>
                                            <Grid item md={10} xs={14}>
                                                <TextField value={realNameTemp} onInput={e => setRealNameTemp(e.target.value)} size="small" fullWidth id="real name" label="real name" variant="outlined" />
                                            </Grid>
                                            <Grid sx={{ paddingTop: 0 }} item md={3} xs={14}>
                                                <Button type="submit" size="large" fullWidth variant="contained">set</Button>
                                            </Grid>
                                            <Grid sx={{ paddingTop: 0 }} item md={1} xs={14}>
                                                <Button size="large" fullWidth variant="outlined" onClick={() => { setRealNameChanging(false); }}>X</Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                            }
                        </Grid>
                    </Grid>



                    <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                        <Grid container sx={{ paddingTop: 0, paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                            <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>email</Typography></Grid>

                            {!emailChanging ?
                                <>
                                    <Grid sx={{ borderRight: 0 }} item md={3} xs={4}>
                                        {isSigned ?
                                            <>
                                                {email === "" || email === null ?
                                                    <Typography variant="subtitle2" align="left">[complete information]</Typography>
                                                    :
                                                    <Typography variant="subtitle1" align="left">{email}</Typography>
                                                }
                                            </>
                                            :
                                            <Typography variant="subtitle2" align="left">[connect wallet to edit]</Typography>
                                        }

                                    </Grid>
                                    {creator || creatorDemand ?
                                        <></>
                                        :
                                        <Grid sx={{ borderRight: 0 }} item md={1} xs={1}>
                                            <Typography align="right">
                                                <IconButton disabled={!isSigned} aria-label="edit" onClick={() => { setEmailTemp(email); setEmailChanging(true); }}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Typography>
                                        </Grid>
                                    }
                                </>
                                :
                                <Grid sx={{ borderRight: 0 }} item md={4} xs={4}>
                                    <form onSubmit={handleSubmitEmail}>
                                        <Grid container sx={{ marginLeft: 0, marginRight: 0, marginTop: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={1} columns={14}>
                                            <Grid item md={10} xs={14}>
                                                <TextField value={emailTemp} onInput={e => setEmailTemp(e.target.value)} size="small" fullWidth id="email" label="email" variant="outlined" />
                                            </Grid>
                                            <Grid sx={{ paddingTop: 0 }} item md={3} xs={14}>
                                                <Button type="submit" size="large" fullWidth variant="contained">set</Button>
                                            </Grid>
                                            <Grid sx={{ paddingTop: 0 }} item md={1} xs={14}>
                                                <Button size="large" fullWidth variant="outlined" onClick={() => { setEmailChanging(false); }}>X</Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                            }
                        </Grid>
                        {!emailIsValid ?
                            <Alert severity="warning">This email address is not valid (expected format example : user.name@domain.com)</Alert>
                            :
                            <></>
                        }
                    </Grid>

                    {email === "" || email === null ?
                        <>
                        </>
                        :
                        <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                            <Grid container sx={{ paddingTop: 0, paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>verify email</Typography></Grid>

                                {emailVerified ?
                                    <Stack justifyContent="center" direction="row" alignItems="center" gap={1}>
                                        <Typography variant="subtitle1" align="left">verified</Typography>
                                        <CheckIcon style={{ color: "#000" }} />
                                    </Stack>
                                    :
                                    <Grid sx={{ borderRight: 0 }} item md={4} xs={5}>
                                        <form onSubmit={handleSubmitEmailCode}>
                                            <Grid container sx={{ marginLeft: 0, marginRight: 0, marginTop: 0 }} direction="row" justifyContent="flex-start" alignItems="top" spacing={1} columns={14}>
                                                <Grid item md={10} xs={14}>
                                                    <Stack justifyContent="center" direction="row" alignItems="center" gap={2}>
                                                        <Button onClick={sendEmailCode} style={{ "whiteSpace": "nowrap", "textAlign": "center" }} variant="text">send code by mail</Button>
                                                        <TextField value={emailCodeTemp} onInput={e => setEmailCodeTemp(e.target.value)} size="small" fullWidth id="code" label="code" variant="outlined" />
                                                    </Stack>
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={4} xs={14}>
                                                    <Button type="submit" size="large" fullWidth variant="contained">verify</Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Grid>
                                }

                            </Grid>
                            {waitSend ?
                                <Alert severity="warning">Please wait 5 minutes between two attempts of sending. Last attempt: {emailCodeSent}</Alert>
                                :
                                <></>
                            }
                            {!emailChecked ?
                                <Alert severity="warning">Code is not valid</Alert>
                                :
                                <></>
                            }
                        </Grid>
                    }

                    <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                        <Grid container sx={{ paddingTop: 0, paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                            <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>phone number</Typography></Grid>

                            {!phoneNumberChanging ?
                                <>
                                    <Grid sx={{ borderRight: 0 }} item md={3} xs={4}>
                                        {isSigned ?
                                            <>
                                                {phoneNumber === "" || phoneNumber === null ?
                                                    <Typography variant="subtitle2" align="left">[complete information]</Typography>
                                                    :
                                                    <Typography variant="subtitle1" align="left">{phoneNumber}</Typography>
                                                }
                                            </>
                                            :
                                            <Typography variant="subtitle2" align="left">[connect wallet to edit]</Typography>
                                        }
                                    </Grid>
                                    {creator || creatorDemand ?
                                        <></>
                                        :
                                        <Grid sx={{ borderRight: 0 }} item md={1} xs={1}>
                                            <Typography align="right">
                                                <IconButton disabled={!isSigned} aria-label="edit" onClick={() => { setPhoneNumberTemp(phoneNumber); setPhoneNumberChanging(true); }}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Typography>
                                        </Grid>
                                    }
                                </>
                                :
                                <Grid sx={{ borderRight: 0 }} item md={4} xs={4}>
                                    <form onSubmit={handleSubmitPhoneNumber}>
                                        <Grid container sx={{ marginLeft: 0, marginRight: 0, marginTop: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={1} columns={14}>
                                            <Grid item md={10} xs={14}>
                                                <TextField value={phoneNumberTemp} onInput={e => setPhoneNumberTemp(e.target.value)} size="small" fullWidth id="phone number" label="phone number" variant="outlined" />
                                            </Grid>
                                            <Grid sx={{ paddingTop: 0 }} item md={3} xs={14}>
                                                <Button type="submit" size="large" fullWidth variant="contained">set</Button>
                                            </Grid>
                                            <Grid sx={{ paddingTop: 0 }} item md={1} xs={14}>
                                                <Button size="large" fullWidth variant="outlined" onClick={() => { setPhoneNumberChanging(false); }}>X</Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                            }
                        </Grid>
                    </Grid>


                    <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                        <Grid container sx={{ paddingTop: 0, paddingBottom: 0, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                            <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>address</Typography></Grid>

                            {!addressChanging ?
                                <>
                                    <Grid sx={{ borderRight: 0 }} item md={3} xs={4}>
                                        {isSigned ?
                                            <>
                                                {address1 === "" || address1 === null || zipCode === "" || zipCode === null || city === "" || city === null || country === "" || country === null ?
                                                    <Typography variant="subtitle2" align="left">[complete information]</Typography>
                                                    :
                                                    <Grid>
                                                        <Grid>
                                                            <Typography variant="subtitle1" align="left">{address1}</Typography>
                                                        </Grid>
                                                        <Grid>
                                                            <Typography variant="subtitle1" align="left">{address2}</Typography>
                                                        </Grid>
                                                        <Grid>
                                                            <Typography variant="subtitle1" align="left">{address3}</Typography>
                                                        </Grid>
                                                        <Grid>
                                                            <Typography variant="subtitle1" align="left">{zipCode}</Typography>
                                                        </Grid>
                                                        <Grid>
                                                            <Typography variant="subtitle1" align="left">{city}</Typography>
                                                        </Grid>
                                                        <Grid>
                                                            <Typography variant="subtitle1" align="left">{state}</Typography>
                                                        </Grid>
                                                        <Grid>
                                                            <Typography variant="subtitle1" align="left">{country}</Typography>
                                                        </Grid>
                                                    </Grid>

                                                }
                                            </>
                                            :
                                            <Typography variant="subtitle2" align="left">[connect wallet to edit]</Typography>
                                        }

                                    </Grid>
                                    {creator || creatorDemand ?
                                        <></>
                                        :
                                        <Grid sx={{ borderRight: 0 }} item md={1} xs={1}>
                                            <Typography align="right">
                                                <IconButton disabled={!isSigned} aria-label="edit" onClick={() => { setAddress1Temp(address1); setAddress2Temp(address2); setAddress3Temp(address3); setZipCodeTemp(zipCode); setCityTemp(city); setStateTemp(state); setCountryTemp(country); setAddressChanging(true); }}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Typography>
                                        </Grid>
                                    }
                                </>
                                :
                                <Grid sx={{ borderRight: 0 }} item md={4} xs={4}>
                                    <form onSubmit={handleSubmitAddress}>
                                        <TextField sx={{ marginBottom: 1 }} value={address1Temp} onInput={e => setAddress1Temp(e.target.value)} size="small" fullWidth id="address1" label="address1 (*)" variant="outlined" />
                                        <TextField sx={{ marginBottom: 1 }} value={address2Temp} onInput={e => setAddress2Temp(e.target.value)} size="small" fullWidth id="address2" label="address2" variant="outlined" />
                                        <TextField sx={{ marginBottom: 1 }} value={address3Temp} onInput={e => setAddress3Temp(e.target.value)} size="small" fullWidth id="address3" label="address3" variant="outlined" />
                                        <TextField sx={{ marginBottom: 1 }} value={zipCodeTemp} onInput={e => setZipCodeTemp(e.target.value)} size="small" fullWidth id="zipcode" label="zipcode (*)" variant="outlined" />
                                        <TextField sx={{ marginBottom: 1 }} value={cityTemp} onInput={e => setCityTemp(e.target.value)} size="small" fullWidth id="city" label="city (*)" variant="outlined" />
                                        <TextField sx={{ marginBottom: 1 }} value={stateTemp} onInput={e => setStateTemp(e.target.value)} size="small" fullWidth id="state" label="state" variant="outlined" />
                                        <TextField value={countryTemp} onInput={e => setCountryTemp(e.target.value)} size="small" fullWidth id="country" label="country (*)" variant="outlined" />

                                        <Grid container sx={{ marginLeft: 0, marginRight: 0, marginTop: 0 }} direction="row" justifyContent="flex-start" alignItems="top" spacing={1} columns={14}>
                                            <Grid sx={{ paddingTop: 0 }} item md={10} xs={14}>
                                                <Button type="submit" size="large" fullWidth variant="contained">set</Button>
                                            </Grid>
                                            <Grid sx={{ paddingTop: 0 }} item md={4} xs={14}>
                                                <Button size="large" fullWidth variant="outlined" onClick={() => { setAddressChanging(false); }}>X</Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                            }

                        </Grid>
                        {addressFormatNotValid ?
                            <Alert severity="warning">This address format is not valid (fields followed by this symbol (*) must be filled in)</Alert>
                            :
                            <></>
                        }
                        {majorProblem ?
                            <Alert severity="warning">Problem...</Alert>
                            :
                            <></>
                        }
                    </Grid>

                    <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                        <Grid container sx={{ paddingTop: 2, paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                            <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>creator status</Typography></Grid>

                            <Grid sx={{ borderRight: 0 }} item md={3} xs={4}>
                                <Typography variant="subtitle1" align="left">
                                    {creator ?
                                        <Stack justifyContent="left" direction="row" alignItems="center" gap={1}>
                                            <HowToRegIcon />
                                            <>your profile is verified</>
                                        </Stack>
                                        :
                                        <>
                                            {creatorDemand ?
                                                <Stack justifyContent="left" direction="row" alignItems="center" gap={1}>
                                                    <CircularProgress size={20} /> your profile is being reviewed
                                                </Stack>
                                                :
                                                <Stack justifyContent="left" direction="row" alignItems="center" gap={1}>
                                                    <PendingIcon />
                                                    <>your profile is not verified yet</>
                                                </Stack>
                                            }
                                        </>
                                    }
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>

                    {!creator && !creatorDemand ?
                        <Button size="medium" disabled={creatorDemand} variant="outlined" onClick={verifyProfile} fullWidth>verify profile</Button>
                        :
                        <>
                            <Button component={Link} to={'/profile/' + String(account)} fullWidth variant="contained"> profile </Button>
                            <br /><br />
                            <Button size="medium" variant="outlined" onClick={handleModifyInformation} fullWidth >Modify information</Button>
                        </>
                    }

                </Grid>
                :
                <NotFoundCompo />
            }
        </>

    );
};



export default CreatePersoInfos;