import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ContractFactory, ethers } from 'ethers';
import { useNavigate, Link } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';


import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import EditIcon from '@mui/icons-material/Edit';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import WalletIcon from '@mui/icons-material/Wallet';
import LoginIcon from '@mui/icons-material/Login';

import './styles/Home.css';

import { UserContext } from '../App';


function Create() {

    const { isConnected, api_url, account, isSigned, accountNetwork } = React.useContext(UserContext);

    const [access, setAccess] = useState(false)



    const [title, setTitle] = useState("")
    const [titleChanging, setTitleChanging] = useState(false)

    const [projectId, setProjectId] = useState("")
    const [projectIdTemp, setProjectIdTemp] = useState("")
    const [projectIdChanging, setProjectIdChanging] = useState("")
    const [projectIdNotUnique, setProjectIdNotUnique] = useState(false)
    const [projectIdWrongFormat, setProjectIdWrongFormat] = useState(false)

    const [projectPresentation, setProjectPresentation] = useState("")
    const [projectPresentationChanging, setProjectPresentationChanging] = useState("")

    const [projectNetwork, setProjectNetwork] = useState(1)

    const [reveal, setReveal] = useState(true)
    const [presale, setPresale] = useState(true)
    const [sale, setSale] = useState(true)
    const [fiat, setFiat] = useState(false)
    const [abiId, setAbiId] = useState(0)


    const [nameSmartContract, setNameSmartContract] = useState("")
    const [nameSmartContractChanging, setNameSmartContractChanging] = useState(false)
    const [symbolSmartContract, setSymbolSmartContract] = useState("")
    const [symbolSmartContractChanging, setSymbolSmartContractChanging] = useState(false)
    const [supplySmartContract, setSupplySmartContract] = useState(0)
    const [supplySmartContractChanging, setSupplySmartContractChanging] = useState(false)
    const [supplySmartContractValid, setSupplySmartContractValid] = useState(true)
    const [priceSmartContract, setPriceSmartContract] = useState(0)
    const [priceSmartContractChanging, setPriceSmartContractChanging] = useState(false)
    const [priceSmartContractValid, setPriceSmartContractValid] = useState(true)
    const [maxPublicMintSmartContract, setMaxPublicMintSmartContract] = useState(0)
    const [maxPublicMintSmartContractChanging, setMaxPublicMintSmartContractChanging] = useState(false)
    const [maxPublicMintSmartContractValid, setMaxPublicMintSmartContractValid] = useState(true)

    const [fee, setFee] = useState(5000);

    const [deploying, setDeploying] = useState(false)
    const [differentNetworks, setDifferentNetworks] = useState(false)
    const [missingInformation, setMissingInformation] = useState(false)

    const [projectIdTaken, setProjectIdTaken] = useState(false)

    const [maxProjects, setMaxProjects] = useState(0)
    const [nbProjects, setNbProjects] = useState(0)
    const [nbProjectsMainnet, setNbProjectsMainnet] = useState(0)
    const [nbProjectsSepolia, setNbProjectsSepolia] = useState(0)
    const [nbProjectsGoerli, setNbProjectsGoerli] = useState(0)
    const [maxProjectsReach, setMaxProjectReach] = useState(false)


    let navigate = useNavigate();

    function consoleVariables() {
        console.log(maxProjects, nbProjects, nbProjectsMainnet, nbProjectsSepolia, nbProjectsGoerli)
    }

    useEffect(() => {
        //console.log(api_url + "/users/" + String(account))
        consoleVariables()

        axios.get(api_url + "/users/" + String(account), { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
            //console.log(response)
            if (response.data.status === "success") {
                if (response.data.user.creator) {
                    setAccess(true)

                    axios.get(api_url + "/projectsByUser/" + String(account)).then((response2) => {
                        //console.log("projects:", response2.data.projects)
                        //console.log("nb projects:", response2.data.projects[1].length + response2.data.projects[5].length + response2.data.projects[11155111].length)
                        //console.log(Object.entries(response2.data.projects));
                        //console.log(Object.entries(response2.data.projects).length)
                        //console.log(Object.entries(response2.data.projects)[0][Object.entries(response2.data.projects)[0].length - 1])
                        //console.log("projects :", response2.data.projects[11155111])
                        setNbProjectsMainnet(response2.data.projects[1].length)
                        setNbProjectsGoerli(response2.data.projects[5].length)
                        setNbProjectsSepolia(response2.data.projects[11155111].length)
                        setNbProjects(response2.data.projects[1].length + response2.data.projects[5].length + response2.data.projects[11155111].length)
                        if (response2.data.projects[1].length + response2.data.projects[5].length + response2.data.projects[11155111].length >= response.data.user.maxProjects) {
                            setMaxProjectReach(true)
                        } else {
                            setMaxProjectReach(false)
                        }
                    })

                    setMaxProjects(response.data.user.maxProjects)

                    if (response.data.user.fee > 1) {
                        setFee(response.data.user.fee)
                    }
                } else {
                    setAccess(false)
                }
            } else {
                setAccess(false)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, api_url, isSigned]);


    async function handleCheckId(event) {
        try {
            event.preventDefault();
            console.log("checking...")
            if (projectIdTemp !== "") {
                if (isAlphaNumeric(projectIdTemp)) {
                    axios.get(api_url + "/projects/" + projectIdTemp, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                        if (response.data.status === "fail" && response.data.message === "project does not exist") {
                            setProjectId(projectIdTemp)
                            setProjectIdChanging(false)
                            setProjectIdNotUnique(false)
                            setProjectIdWrongFormat(false)
                            setProjectIdTaken(false)
                        } else {
                            setProjectIdNotUnique(true)
                            setProjectIdWrongFormat(false)
                            setProjectIdTaken(false)
                        }
                    })
                } else {
                    setProjectIdWrongFormat(true)
                    setProjectIdNotUnique(false)
                }

            } else {
                setProjectIdNotUnique(false)
                setProjectIdWrongFormat(true)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleChangeProjectNetwork = (event) => {
        setProjectNetwork(event.target.value);
        if (event.target.value !== 1) {
            //console.log("PROJECT ID UUID :", uuidv4())
            setProjectId(uuidv4())
        } else {
            setProjectId("")
            setProjectIdTemp("")
        }
    };

    function isAlphaNumeric(str) {
        var code, i, len;

        for (i = 0, len = str.length; i < len; i++) {
            code = str.charCodeAt(i);
            if (!(code > 47 && code < 58) && // numeric (0-9)
                !(code > 64 && code < 91) && // upper alpha (A-Z)
                !(code > 96 && code < 123) && // lower alpha (a-z)
                !(code === 45)) {
                return false;
            }
        }
        return true;
    };

    const handleChangeReveal = (event) => {
        setReveal(event.target.checked);
    }

    const handleChangePresale = (event) => {
        setPresale(event.target.checked);
    }

    const handleChangeSale = (event) => {
        setSale(event.target.checked);
    }

    const handleChangeFiat = (event) => {
        setFiat(event.target.checked);
        if (event.target.checked) {
            setAbiId(1)
        } else {
            setAbiId(0)
        }
    }

    async function handleCheckSupply(event) {
        try {
            event.preventDefault();
            console.log("checking...")
            if (!isNaN(parseInt(supplySmartContract)) && parseInt(supplySmartContract) > 0 && parseInt(supplySmartContract) <= 50000) {
                setSupplySmartContract(parseInt(supplySmartContract))
                setSupplySmartContractChanging(false)
                setSupplySmartContractValid(true)
            } else {
                setSupplySmartContractChanging(true)
                setSupplySmartContractValid(false)
            }
        } catch (error) {
            console.log(error)
            setSupplySmartContractChanging(true)
            setSupplySmartContractValid(false)
        }
    }

    async function handleCheckPrice(event) {
        try {
            event.preventDefault();
            console.log("checking...")
            if (!isNaN(parseFloat(priceSmartContract)) && parseFloat(priceSmartContract) >= 0) {
                setPriceSmartContract(parseFloat(priceSmartContract))
                setPriceSmartContractChanging(false)
                setPriceSmartContractValid(true)
                //console.log(priceSmartContract)
            } else {
                setPriceSmartContractChanging(true)
                setPriceSmartContractValid(false)
                //console.log(priceSmartContract)
            }
        } catch (error) {
            console.log(error)
            setPriceSmartContractChanging(true)
            setPriceSmartContractValid(false)
            //console.log(priceSmartContract)
        }
    }

    async function handleCheckMaxPublicMint(event) {
        try {
            event.preventDefault();
            console.log("checking...")
            if (!isNaN(parseInt(maxPublicMintSmartContract)) && parseInt(maxPublicMintSmartContract) > 0 && parseInt(maxPublicMintSmartContract) <= 50000) {
                setMaxPublicMintSmartContract(parseInt(maxPublicMintSmartContract))
                setMaxPublicMintSmartContractChanging(false)
                setMaxPublicMintSmartContractValid(true)
            } else {
                setMaxPublicMintSmartContractChanging(true)
                setMaxPublicMintSmartContractValid(false)
            }
        } catch (error) {
            console.log(error)
            setMaxPublicMintSmartContractChanging(true)
            setMaxPublicMintSmartContractValid(false)
        }
    }

    async function deployTest() {
        console.log("deploying...")

        setDeploying(true)
        //console.log(projectNetwork)
        //console.log("network :", accountNetwork)
        if (accountNetwork === projectNetwork) {
            console.log("SAME NETWORK")
            setDifferentNetworks(false)


            if (title === "" || (projectNetwork === 1 && projectId === "") || nameSmartContract === "" || symbolSmartContract === "" || supplySmartContract === 0 || maxPublicMintSmartContract === 0 || (presale + sale === 0)) {
                console.log("MISSING INFORMATION")
                setMissingInformation(true)
                setDeploying(false)
            } else {
                console.log("ALL INFORMATION THERE")
                setMissingInformation(false)

                axios.get(api_url + "/projects/" + projectId, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response5) => {
                    if (response5.data.status === "fail" && response5.data.message === "project does not exist") {
                        console.log("PROJECT ID NOT TAKEN")
                        setProjectIdTaken(false)
                        const provider = new ethers.providers.Web3Provider(window.ethereum)
                        provider.send("eth_requestAccounts", []).then((response6) => {
                            const signer = provider.getSigner();
                            //console.log(abiId)
                            axios.get(api_url + "/abis/" + abiId, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                                if (response.data.status === "success") {
                                    //console.log("OK")
                                    //console.log(response)
                                    const factory = new ContractFactory(response.data.abi.abiJson, response.data.abi.byteCodeJson, signer)
                                    //console.log("signer :", signer)
                                    //console.log("factory :", factory)
                                    //console.log("price :", (priceSmartContract * (10 ** 18)).toString())
                                    factory.deploy(nameSmartContract, symbolSmartContract, supplySmartContract, maxPublicMintSmartContract, (priceSmartContract * (10 ** 18)).toString(), fee, reveal, presale).then((response2) => {
                                        //console.log(response2)
                                        //console.log(`Transaction hash: ${response2.deployTransaction.hash}`);

                                        const interval = setInterval(() => {
                                            txnCheck(response2.deployTransaction.hash).then((response3) => {
                                                try {
                                                    console.log("res : ", response3.hash)
                                                    console.log("tot : ", response3)
                                                    console.log("creat : ", response3.creates)

                                                    setDeploying(false)
                                                    clearInterval(interval)
                                                    const dat = { id: projectId, title: title, network: projectNetwork, presentation: projectPresentation, imgUrl: "", bannerUrl: "", instagramUrl: "", openseaUrl: "", secondaryUrl: "", twitterUrl: "", websiteUrl: "", smartContractAddress: response3.creates, abiId: abiId, userId: String(account), baseUri: "", fee: fee, revealOption: reveal, presaleOption: presale, saleOption: sale, maxSupply: supplySmartContract, unitPrice: priceSmartContract, name: nameSmartContract, symbol: symbolSmartContract, maxPublicMint: maxPublicMintSmartContract, saleIsActive: false, presaleIsActive: false, isRevealed: !reveal}
                                                    axios.post(api_url + "/projects", dat, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response4) => {
                                                        //console.log(response4)
                                                        navigate("/launchpad/" + response4.data.id);
                                                    }).catch(e => {
                                                        console.log(e)
                                                    })
                                                } catch (error) {
                                                    //console.error(error)
                                                }
                                            });
                                            console.log("deployment on going")
                                        }, 1000);

                                    }).catch(e => {
                                        console.log(e)
                                        console.log(e.code)
                                        setDeploying(false)
                                        if (e.code === "ACTION_REJECTED") {
                                            //user rejected the transaction
                                            console.log("DEPLOY | DENIED")
                                        }
                                    })
                                } else {
                                    console.log("NOT OK")
                                    //console.log(response)
                                    setDeploying(false)
                                }
                            })
                        })

                    } else {
                        console.log("PROJECT ID TAKEN")
                        setProjectIdTaken(true)
                        setDeploying(false)

                    }
                })

            }
        } else {
            console.log("DIFFERENT NETWORK")
            setDeploying(false)
            setDifferentNetworks(true)
            setMissingInformation(false)
        }
    }


    const txnCheck = async (txnHash) => {

        const provider = new ethers.providers.Web3Provider(window.ethereum)
        await provider.send("eth_requestAccounts", []);
        let txn_test = await provider.getTransaction(txnHash);
        if (txn_test) {
            if (txn_test.blockNumber) {
                //console.log("txn_test :", txn_test);
                return txn_test
            }
        }

    }

    return (
        <div className="App-body">
            {access ?
                <>
                    <br />
                    <Box sx={{ width: '90%', marginTop: 7 }}>
                    
                        
                        <React.Fragment>
                            <Typography variant="h4" align="left" sx={{ mt: 2, mb: 1 }}>Deploy your smart contract</Typography>
                            <Typography variant="subtitle1" align="left" sx={{ mt: 2, mb: 1 }}>Complete information about your project in order to deploy the perfect smart contract for your need.</Typography>
                            
                            {!isSigned ?
                                <Stack justifyContent="left" direction="row" alignItems="center" gap={1}>
                                    <InfoIcon style={{ color: "#f54242" }} />
                                    <Typography variant="subtitle1" align="left" style={{ color: "#f54242" }}>Your wallet is connected but you are not logged in. Log in to create a project.</Typography>
                                </Stack>
                                :
                                <>
                                    {maxProjectsReach ?
                                        <Stack justifyContent="left" direction="row" alignItems="center" gap={1}>
                                            <InfoIcon style={{ color: "#f54242" }} />
                                            <Typography variant="subtitle1" align="left" style={{ color: "#f54242" }}>You have reached the maximum number of projects. Contact us at <b>staff@crmbl.io</b> to increase the number of projects you can launch.</Typography>
                                        </Stack>
                                        :
                                        <Stack justifyContent="left" direction="row" alignItems="center" gap={1}>
                                            <InfoIcon style={{ color: "#888" }} />
                                            <Typography variant="subtitle1" align="left" style={{ color: "#888" }}>You will be able to add other information like the project picture, the banner, metadata, etc. after the smart contract deployment.</Typography>
                                        </Stack>
                                    }
                                </>
                            }
                            
                            {deploying ?
                                <Box
                                    sx={{
                                        borderRadius: '10px',
                                        boxShadow: 0,
                                        paddingLeft: 1,
                                        paddingRight: 1,
                                        paddingTop: 1,
                                        paddingBottom: 1,
                                        marginTop: 1,
                                        backgroundColor: '#EEEEEE'
                                    }}>
                                    <Stack justifyContent="left" direction="row" alignItems="center" gap={1}>
                                        <CircularProgress color="inherit" size={20} />
                                        <Typography style={{ color: "#265c99" }} sx={{ fontStyle: 'italic' }} variant="h6" align="left">Deploying your smart contract, don't refresh your web browser, don't leave this page</Typography>
                                    </Stack>
                                    <Stack justifyContent="left" direction="row" alignItems="center" gap={1}>
                                        <EditIcon style={{ color: "#eeeeee" }} size={20} />
                                        <Typography style={{ color: "#265c99" }} sx={{ fontStyle: 'italic' }} variant="subtitle2" align="left">(this can take up to 5 minutes, depending on the blockchain activity status)</Typography>
                                    </Stack>
                                </Box>
                                :
                                <></>
                            }

                            <Grid container sx={{ marginTop: 3, marginBottom: 3 }} direction="row" justifyContent="space-between" alignItems="center" spacing={0} columns={1}>

                                <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                                    <Grid container sx={{ paddingTop: 2, paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                        <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>project network</Typography></Grid>

                                        <Grid sx={{ borderRight: 0 }} item md={4} xs={5}>

                                            <FormControl fullWidth size="small">
                                                <InputLabel id="demo-select-small">Network</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={projectNetwork}
                                                    label="network"
                                                    onChange={handleChangeProjectNetwork}
                                                    disabled={!isSigned || deploying || maxProjectsReach}
                                                >
                                                    <MenuItem value={1}>Ethereum</MenuItem>
                                                    <MenuItem value={5}>Goerli (testnet)</MenuItem>
                                                    <MenuItem value={11155111}>Sepolia (testnet)</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                
                                <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                                    <Grid container sx={{ paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                        <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>title</Typography></Grid>

                                        {!titleChanging ?
                                            <>
                                                <Grid sx={{ borderRight: 0 }} item md={3} xs={4}>
                                                    {title === "" || title === null ?
                                                        <Typography variant="subtitle2" align="left">[complete information]</Typography>
                                                        :
                                                        <Typography variant="subtitle1" align="left">{title}</Typography>
                                                    }

                                                </Grid>
                                                <Grid sx={{ borderRight: 0 }} item md={1} xs={1}>
                                                    <Typography align="right">
                                                        <IconButton disabled={!isSigned || deploying || maxProjectsReach} aria-label="edit" onClick={() => { setTitleChanging(true); }}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Typography>
                                                </Grid>

                                            </>
                                            :
                                            <Grid sx={{ borderRight: 0 }} item md={4} xs={4}>
                                                <form onSubmit={() => { setTitleChanging(false); }}>
                                                    <Grid container sx={{ marginLeft: 0, marginRight: 0, marginTop: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={1} columns={14}>
                                                        <Grid item md={10} xs={14}>
                                                            <TextField disabled={!isSigned || deploying || maxProjectsReach} value={title} onInput={e => setTitle(e.target.value)} size="small" fullWidth id="title" label="title" variant="outlined" />
                                                        </Grid>
                                                        <Grid item md={4} xs={14}>
                                                            <Button disabled={!isSigned || deploying || maxProjectsReach} type="submit" size="large" fullWidth variant="contained">set</Button>
                                                        </Grid>
                                                    </Grid>
                                                </form>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>


                                <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                                    <Grid container sx={{ paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                        <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>id</Typography></Grid>

                                        {!projectIdChanging ?
                                            <>
                                                <Grid sx={{ borderRight: 0 }} item md={3} xs={4}>
                                                    {projectNetwork === 1 ?
                                                        <>
                                                            {projectId === "" || projectId === null ?
                                                                <Typography variant="subtitle2" align="left">[complete information]</Typography>
                                                                :
                                                                <Typography variant="subtitle1" align="left">{projectId}</Typography>
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            <Typography sx={{ color: "#999" }} variant="subtitle2" align="left">testnet is chosen, id will be randomly generated (id is customizable only on mainnet)</Typography>
                                                        </>
                                                    }


                                                </Grid>
                                                <Grid sx={{ borderRight: 0 }} item md={1} xs={1}>
                                                    <Typography align="right">
                                                        <IconButton disabled={!isSigned || deploying || projectNetwork !== 1 || maxProjectsReach} aria-label="edit" onClick={() => { setProjectIdChanging(true); }}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Typography>
                                                </Grid>

                                            </>
                                            :
                                            <Grid sx={{ borderRight: 0 }} item md={4} xs={4}>
                                                <form onSubmit={handleCheckId}>
                                                    <Grid container sx={{ marginLeft: 0, marginRight: 0, marginTop: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={1} columns={14}>
                                                        <Grid item md={10} xs={14}>
                                                            <TextField disabled={!isSigned || deploying || projectNetwork !== 1 || maxProjectsReach} value={projectIdTemp} onInput={e => setProjectIdTemp(e.target.value)} size="small" fullWidth id="id" label="id" variant="outlined" />
                                                        </Grid>
                                                        <Grid sx={{ paddingTop: 0 }} item md={3} xs={14}>
                                                            <Button disabled={!isSigned || deploying || projectNetwork !== 1 || maxProjectsReach} type="submit" size="large" fullWidth variant="contained">set</Button>
                                                        </Grid>
                                                        <Grid sx={{ paddingTop: 0 }} item md={1} xs={14}>
                                                            <Button disabled={!isSigned || deploying || projectNetwork !== 1 || maxProjectsReach} size="large" fullWidth variant="outlined" onClick={() => { setProjectIdChanging(false); }}>X</Button>
                                                        </Grid>
                                                    </Grid>
                                                </form>
                                            </Grid>
                                        }
                                    </Grid>
                                    {projectIdNotUnique ?
                                        <Alert severity="warning">This project id is already taken</Alert>
                                        :
                                        <></>
                                    }
                                    {projectIdWrongFormat ?
                                        <Alert severity="warning">This project id has the wrong format</Alert>
                                        :
                                        <></>
                                    }
                                </Grid>

                                <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                                    <Grid container sx={{ paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                        <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}> project presentation</Typography></Grid>

                                        {!projectPresentationChanging ?
                                            <>
                                                <Grid sx={{ borderRight: 0 }} item md={3} xs={4}>
                                                    {projectPresentation === "" || projectPresentation === null ?
                                                        <Typography variant="subtitle2" align="left">[complete information]</Typography>
                                                        :
                                                        <Typography variant="subtitle1" align="left">{projectPresentation}</Typography>
                                                    }

                                                </Grid>
                                                <Grid sx={{ borderRight: 0 }} item md={1} xs={1}>
                                                    <Typography align="right">
                                                        <IconButton disabled={!isSigned || deploying || maxProjectsReach} aria-label="edit" onClick={() => { setProjectPresentationChanging(true); }}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Typography>
                                                </Grid>

                                            </>
                                            :
                                            <Grid sx={{ borderRight: 0 }} item md={4} xs={4}>
                                                <form onSubmit={() => { setProjectPresentationChanging(false); }}>
                                                    <Grid container sx={{ marginLeft: 0, marginRight: 0, marginTop: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={1} columns={14}>
                                                        <Grid item md={10} xs={14}>
                                                            <TextField disabled={!isSigned || deploying || maxProjectsReach} multiline value={projectPresentation} onInput={e => setProjectPresentation(e.target.value)} size="small" fullWidth id="presentation" label="presentation" variant="outlined" />
                                                        </Grid>
                                                        <Grid sx={{ paddingTop: 0 }} item md={4} xs={14}>
                                                            <Button disabled={!isSigned || deploying || maxProjectsReach} type="submit" size="large" fullWidth variant="contained">set</Button>
                                                        </Grid>
                                                    </Grid>
                                                </form>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>

                                <Grid sx={{ marginTop: 2, borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                                    <Divider textAlign="left">
                                        <Stack justifyContent="center" direction="row" alignItems="center" gap={1}>
                                            <WarningIcon style={{ color: "#000" }} />
                                            <Typography variant="subtitle2">information below cannot be modified later</Typography>
                                        </Stack>
                                    </Divider>

                                    <Grid container sx={{ paddingBottom: 2, marginTop: 2 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                        <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>features</Typography></Grid>

                                        <Grid sx={{ borderRight: 0 }} item md={4} xs={5}>

                                            <Grid align="left">
                                                <FormControlLabel control={<Checkbox
                                                    checked={presale}
                                                    onChange={handleChangePresale}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    disabled={!isSigned || deploying || maxProjectsReach}
                                                />} label="Presale" />
                                            </Grid>
                                            <Grid align="left">
                                                <FormControlLabel control={<Checkbox
                                                    checked={sale}
                                                    onChange={handleChangeSale}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    disabled={!isSigned || deploying || maxProjectsReach}
                                                />} label="Sale" />
                                            </Grid>
                                            <Grid align="left">
                                                <FormControlLabel control={<Checkbox
                                                    checked={reveal}
                                                    onChange={handleChangeReveal}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    disabled={!isSigned || deploying || maxProjectsReach}
                                                />} label="Reveal" />
                                            </Grid>
                                            <Grid align="left">
                                                <FormControlLabel control={<Checkbox
                                                    checked={fiat}
                                                    onChange={handleChangeFiat}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    disabled={!isSigned || deploying || maxProjectsReach}
                                                />} label="Accept FIAT checkouts" />
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                                    <Grid container sx={{ paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                        <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>name</Typography></Grid>

                                        {!nameSmartContractChanging ?
                                            <>
                                                <Grid sx={{ borderRight: 0 }} item md={3} xs={4}>
                                                    {nameSmartContract === "" || nameSmartContract === null ?
                                                        <Typography variant="subtitle2" align="left">[complete information]</Typography>
                                                        :
                                                        <Typography variant="subtitle1" align="left">{nameSmartContract}</Typography>
                                                    }

                                                </Grid>
                                                <Grid sx={{ borderRight: 0 }} item md={1} xs={1}>
                                                    <Typography align="right">
                                                        <IconButton disabled={!isSigned || deploying || maxProjectsReach} aria-label="edit" onClick={() => { setNameSmartContractChanging(true); }}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Typography>
                                                </Grid>

                                            </>
                                            :
                                            <Grid sx={{ borderRight: 0 }} item md={4} xs={4}>
                                                <form onSubmit={() => { setNameSmartContractChanging(false); }}>
                                                    <Grid container sx={{ marginLeft: 0, marginRight: 0, marginTop: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={1} columns={14}>
                                                        <Grid item md={10} xs={14}>
                                                            <TextField disabled={!isSigned || deploying || maxProjectsReach} value={nameSmartContract} onInput={e => setNameSmartContract(e.target.value)} size="small" fullWidth id="name" label="name" variant="outlined" />
                                                        </Grid>
                                                        <Grid sx={{ paddingTop: 0 }} item md={4} xs={14}>
                                                            <Button disabled={!isSigned || deploying || maxProjectsReach} type="submit" size="large" fullWidth variant="contained">set</Button>
                                                        </Grid>
                                                    </Grid>
                                                </form>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>

                                <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                                    <Grid container sx={{ paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                        <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>symbol</Typography></Grid>

                                        {!symbolSmartContractChanging ?
                                            <>
                                                <Grid sx={{ borderRight: 0 }} item md={3} xs={4}>
                                                    {symbolSmartContract === "" || symbolSmartContract === null ?
                                                        <Typography variant="subtitle2" align="left">[complete information]</Typography>
                                                        :
                                                        <Typography variant="subtitle1" align="left">{symbolSmartContract}</Typography>
                                                    }

                                                </Grid>
                                                <Grid sx={{ borderRight: 0 }} item md={1} xs={1}>
                                                    <Typography align="right">
                                                        <IconButton disabled={!isSigned || deploying || maxProjectsReach} aria-label="edit" onClick={() => { setSymbolSmartContractChanging(true); }}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Typography>
                                                </Grid>

                                            </>
                                            :
                                            <Grid sx={{ borderRight: 0 }} item md={4} xs={4}>
                                                <form onSubmit={() => { setSymbolSmartContractChanging(false); }}>
                                                    <Grid container sx={{ marginLeft: 0, marginRight: 0, marginTop: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={1} columns={14}>
                                                        <Grid item md={10} xs={14}>
                                                            <TextField disabled={!isSigned || deploying || maxProjectsReach} value={symbolSmartContract} onInput={e => setSymbolSmartContract(e.target.value)} size="small" fullWidth id="symbol" label="symbol" variant="outlined" />
                                                        </Grid>
                                                        <Grid sx={{ paddingTop: 0 }} item md={4} xs={14}>
                                                            <Button disabled={!isSigned || deploying || maxProjectsReach} type="submit" size="large" fullWidth variant="contained">set</Button>
                                                        </Grid>
                                                    </Grid>
                                                </form>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>

                                <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                                    <Grid container sx={{ paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                        <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>supply</Typography></Grid>

                                        {!supplySmartContractChanging ?
                                            <>
                                                <Grid sx={{ borderRight: 0 }} item md={3} xs={4}>
                                                    {supplySmartContract === "" || supplySmartContract === null ?
                                                        <Typography variant="subtitle2" align="left">[complete information]</Typography>
                                                        :
                                                        <Typography variant="subtitle1" align="left">{supplySmartContract}</Typography>
                                                    }

                                                </Grid>
                                                <Grid sx={{ borderRight: 0 }} item md={1} xs={1}>
                                                    <Typography align="right">
                                                        <IconButton disabled={!isSigned || deploying || maxProjectsReach} aria-label="edit" onClick={() => { setSupplySmartContractChanging(true); }}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Typography>
                                                </Grid>

                                            </>
                                            :
                                            <Grid sx={{ borderRight: 0 }} item md={4} xs={4}>
                                                <form onSubmit={handleCheckSupply}>
                                                    <Grid container sx={{ marginLeft: 0, marginRight: 0, marginTop: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={1} columns={14}>
                                                        <Grid item md={10} xs={14}>
                                                            <TextField disabled={!isSigned || deploying || maxProjectsReach} value={supplySmartContract} onInput={e => setSupplySmartContract(e.target.value)} size="small" fullWidth id="supply" label="supply" variant="outlined" />
                                                        </Grid>
                                                        <Grid sx={{ paddingTop: 0 }} item md={4} xs={14}>
                                                            <Button disabled={!isSigned || deploying || maxProjectsReach} type="submit" size="large" fullWidth variant="contained">set</Button>
                                                        </Grid>
                                                    </Grid>
                                                </form>
                                            </Grid>
                                        }
                                    </Grid>
                                    {!supplySmartContractValid ?
                                        <Alert severity="warning">Provide an integer between 1 and 50 000</Alert>
                                        :
                                        <></>
                                    }
                                </Grid>

                                <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                                    <Grid container sx={{ paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                        <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>nft price</Typography></Grid>

                                        {!priceSmartContractChanging ?
                                            <>
                                                <Grid sx={{ borderRight: 0 }} item md={3} xs={4}>
                                                    {priceSmartContract === "" || priceSmartContract === null ?
                                                        <Typography variant="subtitle2" align="left">[complete information]</Typography>
                                                        :
                                                        <Typography variant="subtitle1" align="left">{priceSmartContract}</Typography>
                                                    }

                                                </Grid>
                                                <Grid sx={{ borderRight: 0 }} item md={1} xs={1}>
                                                    <Typography align="right">
                                                        <IconButton disabled={!isSigned || deploying || maxProjectsReach} aria-label="edit" onClick={() => { setPriceSmartContractChanging(true); }}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Typography>
                                                </Grid>

                                            </>
                                            :
                                            <Grid sx={{ borderRight: 0 }} item md={4} xs={4}>
                                                <form onSubmit={handleCheckPrice}>
                                                    <Grid container sx={{ marginLeft: 0, marginRight: 0, marginTop: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={1} columns={14}>
                                                        <Grid item md={10} xs={14}>
                                                            <TextField disabled={!isSigned || deploying || maxProjectsReach} value={priceSmartContract} onInput={e => setPriceSmartContract(e.target.value)} size="small" fullWidth id="price" label="price" variant="outlined" />
                                                        </Grid>
                                                        <Grid sx={{ paddingTop: 0 }} item md={4} xs={14}>
                                                            <Button disabled={!isSigned || deploying || maxProjectsReach} type="submit" size="large" fullWidth variant="contained">set</Button>
                                                        </Grid>
                                                    </Grid>
                                                </form>
                                            </Grid>
                                        }
                                    </Grid>
                                    {!priceSmartContractValid ?
                                        <Alert severity="warning">Provide an amount in ether in the form : 0.05</Alert>
                                        :
                                        <></>
                                    }
                                </Grid>

                                <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                                    <Grid container sx={{ paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                        <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>max public mint</Typography></Grid>

                                        {!maxPublicMintSmartContractChanging ?
                                            <>
                                                <Grid sx={{ borderRight: 0 }} item md={3} xs={4}>
                                                    {maxPublicMintSmartContract === "" || maxPublicMintSmartContract === null ?
                                                        <Typography variant="subtitle2" align="left">[complete information]</Typography>
                                                        :
                                                        <Typography variant="subtitle1" align="left">{maxPublicMintSmartContract}</Typography>
                                                    }

                                                </Grid>
                                                <Grid sx={{ borderRight: 0 }} item md={1} xs={1}>
                                                    <Typography align="right">
                                                        <IconButton disabled={!isSigned || deploying || maxProjectsReach} aria-label="edit" onClick={() => { setMaxPublicMintSmartContractChanging(true); }}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Typography>
                                                </Grid>

                                            </>
                                            :
                                            <Grid sx={{ borderRight: 0 }} item md={4} xs={4}>
                                                <form onSubmit={handleCheckMaxPublicMint}>
                                                    <Grid container sx={{ marginLeft: 0, marginRight: 0, marginTop: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={1} columns={14}>
                                                        <Grid item md={10} xs={14}>
                                                            <TextField disabled={!isSigned || deploying || maxProjectsReach} value={maxPublicMintSmartContract} onInput={e => setMaxPublicMintSmartContract(e.target.value)} size="small" fullWidth id="max public mint" label="max public mint" variant="outlined" />
                                                        </Grid>
                                                        <Grid item md={4} xs={14}>
                                                            <Button disabled={!isSigned || deploying || maxProjectsReach} type="submit" size="large" fullWidth variant="contained">set</Button>
                                                        </Grid>
                                                    </Grid>
                                                </form>
                                            </Grid>
                                        }
                                    </Grid>
                                    {!maxPublicMintSmartContractValid ?
                                        <Alert severity="warning">Provide an integer between 1 and 50 000</Alert>
                                        :
                                        <></>
                                    }

                                </Grid>

                                <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                                    <Grid container sx={{ paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                        <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>crmbl's cut</Typography></Grid>

                                        <Grid sx={{ borderRight: 0 }} item md={4} xs={4}>
                                            <Typography variant="subtitle1" align="left">{fee / 1000}%</Typography>
                                        </Grid>

                                    </Grid>


                                </Grid>


                                <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>
                                    {differentNetworks ?
                                        <Alert fullWidth severity="warning">Your wallet network and the desired deploiement network must be the same</Alert>
                                        :
                                        <></>
                                    }
                                    {missingInformation ?
                                        <Alert fullWidth severity="warning">You must fill all the above information to deploy the smart contract</Alert>
                                        :
                                        <></>
                                    }
                                    {projectIdTaken ?
                                        <Alert fullWidth severity="warning">This id is already taken</Alert>
                                        :
                                        <></>
                                    }
                                </Grid>

                            </Grid>


                            <Box >

                                <Button fullWidth disabled={!isSigned || deploying || maxProjectsReach} variant="contained" onClick={deployTest}>DEPLOY 🦊</Button>


                            </Box>





                        </React.Fragment>

                    </Box>
                </>
                :
                <>
                    {!isConnected ?
                        <header className="App-header">
                            <Stack justifyContent="left" direction="row" alignItems="center" gap={2}>
                                <WalletIcon fontSize="large"/>
                                <Typography variant="h3">connect your wallet to create a project</Typography>
                            </Stack>
                            
                        </header>
                        :
                        <>
                            {!isSigned ?
                                <header className="App-header">
                                    <Stack justifyContent="left" direction="row" alignItems="center" gap={2}>
                                        <LoginIcon fontSize="large"/>
                                        <Typography variant="h3">log in to create a project</Typography>
                                    </Stack>
                                </header>
                                :
                                <header className="App-header">
                                    <Typography variant="h5">verify your profile to create a project</Typography>
                                    <Button component={Link} to={"/personal"} sx={{ marginTop: 2 }} size="large" variant="outlined">Verify profile</Button>
                                </header>
                            }
                        </>
                    }
                    {/*<NotFoundCompo />*/}
                </>
            }
        </div >
    );
}

export default Create;