import React, { useState, useEffect } from 'react';
import axios from 'axios';
//import { useParams } from 'react-router-dom';

//import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
//import Skeleton from '@mui/material/Skeleton'
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
//import Chip from '@mui/material/Chip';
//import Divider from '@mui/material/Divider';

import InfoIcon from '@mui/icons-material/Info';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import LoopIcon from '@mui/icons-material/Loop';

import Avatar from "boring-avatars";


import { ethers } from "ethers";

import { UserContext } from '../App';

//import CustomCountdown from './CustomCountdown';

const AdminOnChainInfos = (props) => {

    //const { isMMInstalled, isConnected, account, accountNetwork } = React.useContext(UserContext);
    const { chain_name } = React.useContext(UserContext);
    const { sameNetwork, etherscanOwner, projectId, etherscanUrl, reservedAmount, pricePerToken, name, symbol, owner, maxPublicMint, totalSupply, maxSupply, chain_img, artwork_title, artwork_img, contract_address, abi, setTxLoading, txLoading, network } = props;

    const [addressToCheck, setAddressToCheck] = useState("")
    const [balanceOfAddress, setBalanceOfAddress] = useState(0)

    const [addressOwnToken, setAddressOwnToken] = useState("")

    const [tokenUriToView, setTokenUriToView] = useState(null)
    const [uriTokenToView, setUriTokenToView] = useState("")
    const [tokenViewData, setTokenViewData] = useState()

    const [tokenViewBool, setTokenViewBool] = useState(false)
    const [tokenViewKeys, setTokenViewKeys] = useState({})
    const [tokenViewValues, setTokenViewValues] = useState({})

    const [invalidToken, setInvalidToken] = useState(false)

    const [imageUrl, setImageUrl] = useState("")

    useEffect(() => {
    }, []);

    async function handleSubmitBalanceOf(event) {
        try {
            setTxLoading(true)
            event.preventDefault();
            console.log('addressToCheck:', addressToCheck);

            const provider = new ethers.providers.Web3Provider(window.ethereum)
            await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner();

            const SmartContract = new ethers.Contract(contract_address, abi, signer);

            SmartContract.balanceOf(addressToCheck).then((response) => {
                console.log(response._hex)
                setBalanceOfAddress(parseInt(response._hex, 16))
                setTxLoading(false)
            }).catch(err => {
                setBalanceOfAddress(0)
                setTxLoading(false)
            });


        } catch (error) {
            console.error(error)
        }
    }

    async function handleSubmitViewToken(event) {
        try {
            event.preventDefault();
            console.log('token to check:', tokenUriToView);
            setTxLoading(true)
            if (tokenUriToView >= totalSupply) {
                setInvalidToken(true)
                setUriTokenToView("")
                setAddressOwnToken("")
                setTokenViewData()
                setTokenViewKeys("")
                setTokenViewValues("")
                setTokenViewBool(false)
                setImageUrl("")
                setTxLoading(false)
            } else {
                setInvalidToken(false)
                const provider = new ethers.providers.Web3Provider(window.ethereum)
                await provider.send("eth_requestAccounts", []);
                const signer = provider.getSigner();

                const SmartContract = new ethers.Contract(contract_address, abi, signer);

                SmartContract.tokenURI(tokenUriToView).then((response) => {
                    setUriTokenToView(response)
                    console.log(response)

                    console.log(response.slice(0, 4))
                    if (response.slice(0, 4) === "ipfs") {
                        console.log("https://ipfs.io/ipfs/" + response.split('/').slice(-2, -1) + "/" + response.split('/').slice(-1))
                        axios.get("https://ipfs.io/ipfs/" + response.split('/').slice(-2, -1) + "/" + response.split('/').slice(-1)).then((response1) => {
                            setTokenViewData(response1.data)
                            setTokenViewKeys(Object.keys(response1.data))
                            setTokenViewValues(Object.values(response1.data))
                            setTokenViewBool(true)
                            if (response1.data.image) {
                                if (response1.data.image.slice(0, 4) === "ipfs") {
                                    setImageUrl("https://ipfs.io/ipfs/" + response1.data.image.split('/').slice(-2, -1) + "/" + response1.data.image.split('/').slice(-1))
                                } else {
                                    setImageUrl(response1.data.image)
                                }
                            }
                            console.log(response1.data)
                            console.log(Object.keys(response1.data));
                            console.log(Object.values(response1.data));
                            setTxLoading(false)
                        }).catch(err => {
                            // Handle error
                            console.log(err);
                            setTokenViewData()
                            setTokenViewKeys()
                            setTokenViewValues()
                            setTokenViewBool(false)
                            setImageUrl("")
                            setTxLoading(false)
                        });
                    } else if (response.slice(0, 4) === "http") {
                        axios.get(response).then((response1) => {
                            setTokenViewData(response1.data)
                            setTokenViewKeys(Object.keys(response1.data))
                            setTokenViewValues(Object.values(response1.data))
                            setTokenViewBool(true)
                            if (response1.data.image) {
                                if (response1.data.image.slice(0, 4) === "ipfs") {
                                    setImageUrl("https://ipfs.io/ipfs/" + response1.data.image.split('/').slice(-2, -1) + "/" + response1.data.image.split('/').slice(-1))
                                } else {
                                    setImageUrl(response1.data.image)
                                }
                            }
                            console.log(response1.data)
                            console.log(Object.keys(response1.data));
                            console.log(Object.values(response1.data));
                            setTxLoading(false)
                        });
                    } else {
                        setTxLoading(false)
                    }
                });

                SmartContract.ownerOf(tokenUriToView).then((response2) => {
                    console.log(response2)
                    setAddressOwnToken(response2)
                });
            }


        } catch (error) {
            //console.error(error)
            setUriTokenToView("error")
            setAddressOwnToken("error")
            setTokenViewData({})
            setTokenViewKeys({})
            setTokenViewValues({})
            setTokenViewBool(false)
            setImageUrl("")
            setTxLoading(false)
        }
    }





    return (
        <div>
            <Box>

                <Stack sx={{ marginLeft: 3, marginBottom: 3 }} direction="row" alignItems="center" gap={1}>
                    {artwork_img === "" || artwork_img === null ?
                        <Avatar
                            size={50}
                            name={projectId}
                            square
                        />
                        :
                        <img alt="logo" width={50} height={50} src={artwork_img} />
                    }
                    <Typography style={{ color: "#000" }} variant="h4">&nbsp;{artwork_title}</Typography>
                </Stack>

                <Stack sx={{ padding: 2, marginLeft: 3, marginRight: 3, marginBottom: 0, border:0, borderRadius: 2, backgroundColor: "#E3F2FD", color: "#000" }} direction="row" alignItems="center" gap={1}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <AccountTreeIcon fontSize="large" />
                        <Typography variant="h3">ON CHAIN INFOS</Typography>
                    </Stack>
                </Stack>


                {sameNetwork ?
                    <></>
                    :
                    <Box sx={{
                        borderRadius: '5px',
                        boxShadow: 0,
                        paddingTop: 1,
                        paddingBottom: 1,
                        paddingRight: 1,
                        paddingLeft: 1,
                        margin: 3,
                        backgroundColor: '#000',
                        color: "#fff"
                        }}>
                            <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
                                <LoopIcon style={{ color: "#fff" }} />
                                <Typography style={{ color: "#fff" }} variant="button">switch network to {chain_name[network]}</Typography>
                            </Stack>
                        </Box>
                }







                <Box sx={{
                    borderRadius: '5px',
                    boxShadow: 0,
                    paddingTop: 1,
                    paddingBottom: 1,
                    paddingRight: 1,
                    paddingLeft: 1,
                    marginLeft: 3,
                    marginRight: 3,
                    marginTop: 3,
                    backgroundColor: '#F5F5F5'
                }}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <InfoIcon style={{ color: "#000" }} />
                        <Typography style={{ color: "#000" }} variant="button">smart contract information</Typography>
                    </Stack>

                    <Grid container sx={{ margin: 3 }} direction="row" justifyContent="space-between" alignItems="center" spacing={0} columns={1}>

                        <Grid sx={{ marginBottom: 2, borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>
                            <Grid container sx={{ margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                <Grid sx={{ borderRight: 0 }} item md={1} xs={5}>
                                    <Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>contract address</Typography>
                                </Grid>
                                <Grid sx={{ borderRight: 0 }} item md={4} xs={5}>
                                    <Typography variant="subtitle1" align="left"><span className="metadata"><Link href={etherscanUrl} target="_blank" underline="hover">{contract_address}</Link></span></Typography>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid sx={{ marginBottom: 2, borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>
                            <Grid container sx={{ margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>owner</Typography></Grid>
                                <Grid sx={{ borderRight: 0 }} item md={4} xs={5}>
                                    <Typography variant="subtitle1" align="left"><span className="metadata"><Link href={etherscanOwner} target="_blank" underline="hover">{owner}</Link></span></Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                        
                        <Grid sx={{ marginBottom: 2, borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>
                            <Grid container sx={{ margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                <Grid sx={{ borderRight: 0 }} item md={1} xs={5}>
                                    <Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>name</Typography>
                                </Grid>
                                <Grid sx={{ borderRight: 0 }} item md={4} xs={5}>
                                    <Typography variant="h5" align="left">{name}</Typography>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid sx={{ marginBottom: 2, borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>
                            <Grid container sx={{ margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>symbol</Typography></Grid>
                                <Grid sx={{ borderRight: 0 }} item md={4} xs={5}>
                                    <Typography variant="h5" align="left">{symbol}</Typography>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid sx={{ marginBottom: 2, borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>
                            <Grid container sx={{ margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>blockchain</Typography></Grid>
                                <Grid sx={{ borderRight: 0 }} item md={4} xs={5}>
                                    <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={1}>
                                        <img alt="logo" width={20} height={20} src={chain_img[network]} />
                                        <Typography variant="h5" align="left">{chain_name[network]}</Typography>
                                    </Stack>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid sx={{ marginBottom: 2, borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>
                            <Grid container sx={{ margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>supply</Typography></Grid>
                                <Grid sx={{ borderRight: 0 }} item md={4} xs={5}>
                                    <Typography variant="h5" align="left">{maxSupply}</Typography>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid sx={{ marginBottom: 2, borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>
                            <Grid container sx={{ margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>minted</Typography></Grid>
                                <Grid sx={{ borderRight: 0 }} item md={4} xs={5}>
                                    <Typography variant="h5" align="left">{totalSupply}</Typography>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid sx={{ marginBottom: 2, borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>
                            <Grid container sx={{ margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>reserved</Typography></Grid>
                                <Grid sx={{ borderRight: 0 }} item md={4} xs={5}>
                                    <Typography variant="h5" align="left">{reservedAmount}</Typography>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid sx={{ marginBottom: 2, borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>
                            <Grid container sx={{ margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>unit price</Typography></Grid>
                                <Grid sx={{ borderRight: 0 }} item md={4} xs={5}>
                                    <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={1}>
                                        <img alt="logo" width={20} height={20} src={chain_img[network]} />
                                        <Typography variant="h5" align="left">{pricePerToken}</Typography>
                                    </Stack>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid sx={{ marginBottom: 0, borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>
                            <Grid container sx={{ margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>max public mint</Typography></Grid>
                                <Grid sx={{ borderRight: 0 }} item md={4} xs={5}>
                                    <Typography variant="h5" align="left">{maxPublicMint}</Typography>
                                </Grid>

                            </Grid>
                        </Grid>


                    </Grid>
                </Box>







                <Box sx={{
                    borderRadius: '5px',
                    boxShadow: 0,
                    paddingTop: 1,
                    paddingBottom: 1,
                    paddingRight: 1,
                    paddingLeft: 1,
                    marginLeft: 3,
                    marginRight: 3,
                    marginTop: 3,
                    backgroundColor: '#F5F5F5'
                }}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <SmartDisplayIcon style={{ color: "#000" }} />
                        <Typography style={{ color: "#000" }} variant="button">nft viewer</Typography>
                    </Stack>

                    {txLoading ?
                        <Stack direction="row" alignItems="center" gap={1}>
                            <CircularProgress color="inherit" size={20} />
                            <Typography style={{ color: "#888" }} sx={{ fontStyle: 'italic' }} variant="button">applying changes, don't refresh your web browser</Typography>
                        </Stack>
                        :
                        <></>
                    }


                    <Grid container sx={{ margin: 3 }} direction="row" justifyContent="space-between" alignItems="center" spacing={0} columns={1}>

                        <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa", paddingTop: 1, paddingBottom: 1 }} item md={1} xs={1}>

                            <form onSubmit={handleSubmitViewToken}>
                                <Grid container sx={{ margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={1} columns={15}>
                                    <Grid item md={3} xs={15}>
                                        <Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>enter token id</Typography>
                                    </Grid>
                                    <Grid item md={2} xs={15}>
                                        <TextField disabled={!sameNetwork || txLoading} value={tokenUriToView} onInput={e => setTokenUriToView(e.target.value)} size="small" fullWidth id="token" label="token id" variant="outlined" />
                                    </Grid>
                                    <Grid sx={{ paddingTop: 0 }} item md={2} xs={15}>
                                        <Button disabled={!sameNetwork || txLoading} type="submit" size="large" fullWidth variant="contained">view</Button>
                                    </Grid>
                                    {invalidToken ?
                                        <Grid item md={5} xs={15}>
                                            <Typography variant="subtitle1" align="left" style={{ fontStyle: "italic", color: "#ff5978" }}>invalid token id</Typography>
                                        </Grid>
                                        :
                                        <></>
                                    }
                                    

                                </Grid>
                            </form>

                        </Grid>

                        <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa", paddingTop: 1, paddingBottom: 1 }} item md={1} xs={1}>
                            <Grid container sx={{ margin: 0 }} direction="row" justifyContent="space-between" alignItems="center" spacing={1} columns={15}>
                                <Grid item md={3} xs={15}>
                                    <Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>owner</Typography>
                                </Grid>
                                <Grid item md={12} xs={15}>
                                    <Box sx={{ borderRadius: '5px', backgroundColor: "#fff", padding: 1, margin: 0 }}>
                                        <Typography variant="body2" align="left"><span className="metadata">{addressOwnToken}</span></Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        
                        <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa", paddingTop: 1, paddingBottom: 1 }} item md={1} xs={1}>
                            <Grid container sx={{ margin: 0 }} direction="row" justifyContent="space-between" alignItems="center" spacing={1} columns={15}>
                                <Grid item md={3} xs={15}>
                                    <Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>tokenURI</Typography>
                                </Grid>
                                <Grid item md={12} xs={15}>
                                    <Box sx={{ borderRadius: '5px', backgroundColor: "#fff", padding: 1, margin: 0 }}>
                                        <Typography variant="body2" align="left"><span className="metadata">{uriTokenToView}</span></Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa", paddingTop: 1, paddingBottom: 1 }} item md={1} xs={1}>
                            <Grid container sx={{ margin: 0 }} direction="row" justifyContent="space-between" alignItems="center" spacing={1} columns={15}>
                                <Grid item md={3} xs={15}>
                                    <Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>image</Typography>
                                </Grid>
                                <Grid item md={12} xs={15}>
                                    <Box sx={{ borderRadius: '5px', backgroundColor: "#fff", padding: 1, margin: 0 }}>
                                        {tokenViewBool && tokenViewKeys.indexOf('image') ?
                                            <>
                                                <Typography align="left"><img alt="img" width={300} src={imageUrl} /></Typography>
                                                <Typography display="block" gutterBottom variant="caption" align="left"><span className="metadata">{imageUrl}</span></Typography>
                                            </>
                                            :
                                            <Typography variant="body2" align="left"><span className="metadata"></span></Typography>
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa", paddingTop: 1, paddingBottom: 1 }} item md={1} xs={1}>
                            <Grid container sx={{ margin: 0 }} direction="row" justifyContent="space-between" alignItems="center" spacing={1} columns={15}>
                                <Grid item md={3} xs={15}>
                                    <Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>information</Typography>
                                </Grid>
                                <Grid item md={12} xs={15}>
                                    <Box sx={{ borderRadius: '5px', backgroundColor: "#fff", padding: 1, margin: 0 }}>
                                        {tokenViewBool ?
                                            <Typography variant="subtitle2" align="left">
                                                {tokenViewKeys.map((key, index) =>
                                                    <Grid container spacing={1} columns={5}>
                                                        <Grid sx={{ borderBottom:0 }} item md={1} xs={5}>{key}</Grid>
                                                        <Grid sx={{ borderBottom:0 }} item md={4} xs={5}>
                                                            <span className="metadata">
                                                                {key === "attributes" ?
                                                                    <>{tokenViewValues[index].map((item) => <Typography variant="subtitle2">{JSON.stringify(item)}</Typography>)}</>
                                                                    :
                                                                    <>{tokenViewValues[index]}</>
                                                                }
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </Typography>
                                            :
                                            <Typography variant="subtitle1" align="left"></Typography>
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa", paddingTop: 1, paddingBottom: 1 }} item md={1} xs={1}>
                            <Grid container sx={{ margin: 0 }} direction="row" justifyContent="space-between" alignItems="center" spacing={1} columns={15}>
                                <Grid item md={3} xs={15}>
                                    <Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>raw metadata</Typography>
                                </Grid>
                                <Grid item md={12} xs={15}>
                                    <Box sx={{ borderRadius: '5px', backgroundColor: "#fff", padding: 1, margin: 0 }}>
                                        <Typography variant="subtitle2" align="left"><span className="metadata">{JSON.stringify(tokenViewData)}</span></Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                </Box>










                <Box sx={{
                    borderRadius: '5px',
                    boxShadow: 0,
                    paddingTop: 1,
                    paddingBottom: 1,
                    paddingRight: 1,
                    paddingLeft: 1,
                    marginLeft: 3,
                    marginRight: 3,
                    marginTop: 3,
                    backgroundColor: '#F5F5F5'
                }}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <CompareArrowsIcon style={{ color: "#000" }} />
                        <Typography style={{ color: "#000" }} variant="button">deeper infos</Typography>
                    </Stack>

                    {txLoading ?
                        <Stack direction="row" alignItems="center" gap={1}>
                            <CircularProgress color="inherit" size={20} />
                            <Typography style={{ color: "#888" }} sx={{ fontStyle: 'italic' }} variant="button">applying changes, don't refresh your web browser</Typography>
                        </Stack>
                        :
                        <></>
                    }

                    <Grid container sx={{ margin: 3 }} direction="row" justifyContent="space-between" alignItems="center" spacing={0} columns={1}>

                        <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa", paddingTop: 1, paddingBottom: 1 }} item md={1} xs={1}>

                            <form onSubmit={handleSubmitBalanceOf}>
                                <Grid container sx={{ margin: 0 }} direction="row" justifyContent="space-between" alignItems="center" spacing={1} columns={15}>
                                    <Grid item md={3} xs={15}>
                                        <Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>balanceOf</Typography>
                                    </Grid>
                                    <Grid item md={8} xs={15}>
                                        <TextField disabled={!sameNetwork || txLoading} value={addressToCheck} onInput={e => setAddressToCheck(e.target.value)} multiline size="small" fullWidth id="address" label="wallet address" variant="outlined" />
                                    </Grid>
                                    <Grid sx={{ paddingTop: 0 }} item md={2} xs={15}>
                                        <Button disabled={!sameNetwork || txLoading} type="submit" size="large" fullWidth variant="contained">check</Button>
                                    </Grid>
                                    <Grid sx={{ paddingTop: 0.5 }} item md={2} xs={15}>
                                        <Box sx={{ borderRadius: '5px', backgroundColor: "#fff", padding: 1, margin: 1 }}>
                                            <Typography variant="body2">{balanceOfAddress} nft(s)</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>

                </Box>

            </Box>
        </div >
    );
};

export default AdminOnChainInfos;