import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from './reportWebVitals';

import ScrollToTop from './components/ScrollToTop';

import App from './App';
import Home from "./pages/Home";
import Mint from './pages/Mint'
import Launchpad from './pages/Launchpad'
import Profile from './pages/Profile';
import PersonalInfos from './pages/PersonalInfos';
import Create from './pages/Create';
import ReviewList from './pages/ReviewList';
import NotFound from './pages/NotFound';

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ScrollToTop />
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<Home />} />
        <Route path="/mint/:projectId" element={<Mint/>}/>
        <Route path="/launchpad/:projectId" element={<Launchpad/>}/>
        <Route path="/profile/:userId" element={<Profile/>}/>
        <Route path="/personal/" element={<PersonalInfos/>}/>
        <Route path="/create/" element={<Create/>}/>
        <Route path="/reviewlist/" element={<ReviewList/>}/>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
