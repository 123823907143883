import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';

//import { useLocation } from 'react-router-dom';

import { Box, Tab, Tabs, Typography, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import Dashboard from '../components/Dashboard';
import ArtworkBox from '../components/ArtworkBox';
import AdminControlPannel from '../components/AdminControlPannel';
import AdminSet from '../components/AdminSet';
import AdminOwner from '../components/AdminOwner';
import AdminOnChainInfos from '../components/AdminOnChainInfos';
import NotFoundCompo from '../components/NotFoundCompo';

import TuneIcon from '@mui/icons-material/Tune';
import BadgeIcon from '@mui/icons-material/Badge';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
//import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import CopyrightIcon from '@mui/icons-material/Copyright';
import SpeedIcon from '@mui/icons-material/Speed';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { UserContext } from '../App';


function Launchpad() {

    //const rout = useLocation();

    let { projectId } = useParams();

    const { isSigned, accountNetwork, account, chain_img, chain_name, chain_etherscan_name, api_url } = React.useContext(UserContext);
    //const { isConnected, account } = React.useContext(UserContext);



    //const opensea_svg = "https://drive.google.com/uc?export=view&id=14_0QPinCm7X6f-PsJkLDq9i9wgc2QyWq";
    const opensea_black_svg = "https://drive.google.com/uc?export=view&id=1lJKKJ5kSBg0_cJDBLykx5sVxHhuWGIZL";
    //const etherscan_svg = "https://drive.google.com/uc?export=view&id=1_fOqtQex3XmkX3s1R9RR7kwM2WIIZACH";
    const etherscan_black_svg = "https://drive.google.com/uc?export=view&id=1JfTp9LRtb_4Mq-465WLyofRlsFfWP9jo";
    //const twitter_svg = "https://drive.google.com/uc?export=view&id=1nii-vp0cCO3QV5HTdm-MyR7rak8Bp4v0";
    const twitter_black_svg = "https://drive.google.com/uc?export=view&id=1cX-Xjr_nM7zES-xy01bEtU11XuHRrkEh";
    //const insta_svg = "https://drive.google.com/uc?export=view&id=1S3qc5_emCr-5-fAwuHFFiw8cN3tQPNou";
    const insta_black_svg = "https://drive.google.com/uc?export=view&id=1kATG84GKz6xIEgw1MyL8goI9BelFAa8_";
    //const ethereum_svg = "https://drive.google.com/uc?export=view&id=1wxhyC6DcGaP8QcMmxsTj3t70E_qJrEXl";
    //const rinkeby_svg = "https://drive.google.com/uc?export=view&id=13LO-fowcktxlhnGv5ri6sZUQRs17-oZQ";
    //const rinkeby1_svg = "https://drive.google.com/uc?export=view&id=1YXZhrqb8tZwj5bf3RWH5xLNY2n2vksWe";

    //const [userId, setuserId] = useState(null);
    const [access, setAccess] = useState(false);
    const [artwork_img, setArtwork_img] = useState("");
    const [banner_img, setBanner_img] = useState("");
    const [artwork_title, setArtwork_title] = useState("");
    const [artwork_presentation, setArtwork_presentation] = useState("");
    const [artist_name, setArtist_name] = useState("");
    //const [artist_presentation, setartist_presentation] = useState("");
    //const [artist_wallet, setartist_wallet] = useState("");
    const [contract_address, setContract_address] = useState("");
    const [network, setNetwork] = useState(0);
    const [abiId, setAbiId] = useState(null);
    const [abi, setAbi] = useState(0);
    const [presaleOption, setPresaleOption] = useState(true);
    const [saleOption, setSaleOption] = useState(true);
    const [revealOption, setRevealOption] = useState(true);
    const [creationDate, setCreationDate] = useState(0);
    const [fee, setFee] = useState(0);

    const [maxPublicMint, setmaxPublicMint] = useState(0);
    const [maxSupply, setMaxSupply] = useState(0);
    const [pricePerToken, setPricePerToken] = useState(0);
    const [presaleIsActive, setPresaleIsActive] = useState(false);
    //const [isValid, setisValid] = useState(null);
    //const [numAvailableToMint, setnumAvailableToMint] = useState(0);
    const [owner, setOwner] = useState("");
    const [saleIsActive, setSaleIsActive] = useState(false);
    const [totalSupply, setTotalSupply] = useState(0)
    const [presaleIsOver, setPresaleIsOver] = useState(false);
    const [saleIsOver, setSaleIsOver] = useState(false);
    const [revealIsOver, setRevealIsOver] = useState(false);
    const [name, setName] = useState("")
    const [symbol, setSymbol] = useState("")

    const [crossmintId, setCrossmintId] = useState("");

    const [etherscanUrl, setEtherscanUrl] = useState("");
    const [etherscanOwner, setEtherscanOwner] = useState("");
    const [openseaUrl, setOpenseaUrl] = useState("");
    const [secondaryUrl, setSecondaryUrl] = useState("");
    const [twitterUrl, setTwitterUrl] = useState("");
    const [instagramUrl, setInstagramUrl] = useState("");
    const [websiteUrl, setWebsiteUrl] = useState("");

    //const [onThePrelist, setOnThePreList] = useState(false);

    const [tabIndex, setTabIndex] = useState(0);

    const [mintData, setMintData] = useState(0);

    const [presaleData, setPresaleData] = useState([])

    const [txLoading, setTxLoading] = useState(true)

    const [balance, setBalance] = useState(0)
    const [artistShare, setArtistShare] = useState(0)

    const [reservedAmount, setReservedAmount] = useState(0)

    const [baseUri, setBaseUri] = useState('')

    const [tokenIds, setTokenIds] = useState([])

    const [sameNetwork, setSameNetwork] = useState(true)

    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };

    useEffect(() => {
        axios.get(api_url + "/projects/" + projectId, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
            console.log("projects :", response)
            if (response.data.status === "fail" || (response.data.status === "success" && response.data.group === "lambdas")) {
                setAccess(false)
            } else if (response.data.status === "success" && response.data.group === "owner") {
                //console.log(response)
                //setuserId(response.data.project.userId)
                setAccess(true)
                setArtwork_img(response.data.project.imgUrl)
                setBanner_img(response.data.project.bannerUrl)
                setArtwork_title(response.data.project.title)
                setArtwork_presentation(response.data.project.presentation)
                setContract_address(response.data.project.smartContractAddress)
                setNetwork(response.data.project.network)
                setSaleIsOver(response.data.project.saleIsOver)
                setPresaleIsOver(response.data.project.presaleIsOver)
                //console.log(response.data.project.network)
                setOpenseaUrl(response.data.project.openseaUrl)
                setSecondaryUrl(response.data.project.secondaryUrl)
                setWebsiteUrl(response.data.project.websiteUrl)
                setTwitterUrl(response.data.project.twitterUrl)
                setInstagramUrl(response.data.project.instagramUrl)
                setEtherscanUrl("https://" + chain_etherscan_name[response.data.project.network] + "etherscan.io/address/" + response.data.project.smartContractAddress)
                setCreationDate(response.data.project.creationDate)
                setAbiId(response.data.project.abiId)
                setPresaleOption(response.data.project.presaleOption)
                setSaleOption(response.data.project.saleOption)
                setRevealOption(response.data.project.revealOption)
                setReservedAmount(response.data.project.reservedAmount)
                setBaseUri(response.data.project.baseUri)
                console.log("FEEEEEEEE :", response.data.project.fee)
                setFee(response.data.project.fee)
                setmaxPublicMint(response.data.project.maxPublicMint)
                setMaxSupply(response.data.project.maxSupply)
                setPricePerToken(response.data.project.unitPrice)
                setSameNetwork(response.data.project.network === accountNetwork)
                setName(response.data.project.name)
                setSymbol(response.data.project.symbol)
                setOwner(response.data.project.userId)
                setEtherscanOwner("https://" + chain_etherscan_name[response.data.project.network] + "etherscan.io/address/" + response.data.project.userId)
                setPresaleIsActive(response.data.project.presaleIsActive)
                setSaleIsActive(response.data.project.saleIsActive)
                setRevealIsOver(response.data.project.isRevealed)
                setCrossmintId(response.data.project.crossmintId)
                
                axios.get(api_url + "/users/" + response.data.project.userId, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response1) => {
                    //console.log(response1)
                    setArtist_name(response1.data.user.name)
                    
                    //setartist_wallet(response1.data.user.walletAddress)
                    //setartist_presentation(response1.data.user.presentation)
                });
                axios.get(api_url + "/getTotalSupply/" + projectId).then((response2) => {
                    setTotalSupply(response2.data.data.totalSupply)
                    setTxLoading(false)
                });
                axios.get(api_url + "/abis/" + response.data.project.abiId).then((response3) => {
                    setAbi(response3.data.abi.abiJson)
                });
                axios.get(api_url + "/getMintEvents/" + projectId, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response4) => {
                    setMintData(response4.data.data)
                    console.log("mintevents :", response4)
                });
                axios.get(api_url + "/getPresaleInfos/" + projectId, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response5) => {
                    setPresaleData(response5.data.data)
                    //console.log(response5.data)
                });
                axios.get(api_url + "/getBalance/" + projectId, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response6) => {
                    setBalance(response6.data.data)
                    console.log("balance : ", response6.data.data)
                    console.log("balance BOOL : ", response6.data.data === "0.0")
                        if (response6.data.data === "0.0") {
                            setArtistShare("0.00")
                        } else {
                            setArtistShare(Math.round(response6.data.data * 0.95 * 10000) / 10000)
                        }
                    setArtistShare(Math.round(response6.data.data * 0.95 * 10000) / 10000)
                    //console.log(response6.data)
                });
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, projectId, isSigned, accountNetwork]);

    return (
        <div className="App-body">
            {access ?
                <>
                    <Grid container spacing={0} direction="row" justifyContent="center" alignItems="top" sx={{ width: '98vw', marginLeft: 0, paddingLeft: 0, paddingTop: 3, bgcolor: '#fff' }}>
                        <Grid sx={{ borderLeft: 0, borderRight: 1, borderColor: '#eee' }} item style={{ width: "250px" }}>
                            <Typography variant="h6" sx={{ marginBottom: 3 }}>&nbsp;</Typography>
                        </Grid>
                        <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item xs >
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} direction="row" justifyContent="center" alignItems="top" sx={{ width: '98vw', marginLeft: 0, paddingLeft: 0, paddingTop: 0, bgcolor: '#fff' }}>
                        <Grid sx={{ borderLeft: 0, borderRight: 1, borderColor: '#eee' }} item style={{ width: "250px" }}>

                            <div style={{ position: "fixed", width: "250px" }}>
                                <Typography sx={{ paddingLeft: 2 }} align="left" variant="h5">LAUNCHPAD</Typography>
                                {txLoading ?
                                    <Stack sx={{ paddingLeft: 2 }} direction="row" alignItems="center" gap={1}>
                                        <ArrowForwardIcon fontSize="small" />
                                        <Typography align="left" variant="subtitle2">go to mint page</Typography>
                                    </Stack>
                                    :
                                    <Link to={"/mint/" + projectId} className="small-menu-link">
                                        <Stack sx={{ paddingLeft: 2 }} direction="row" alignItems="center" gap={1}>
                                            <ArrowForwardIcon fontSize="small" />
                                            <Typography align="left" variant="subtitle2">go to mint page</Typography>
                                        </Stack>
                                    </Link>
                                }
                                

                                <Tabs
                                    value={tabIndex}
                                    onChange={handleTabChange}
                                    orientation="vertical"
                                    centered="false"
                                    sx={{ paddingTop: 2, minWidth: '100%', maxWidth: '100%', justifyContent: "flex-start" }}
                                >
                                    <Tab label={<Stack direction="row" alignItems="center" gap={1}><SpeedIcon /><Typography>dashboard &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</Typography></Stack>} sx={{ alignSelf: 'start' }} disabled={txLoading} />
                                    <Tab label={<Stack direction="row" alignItems="center" gap={1}><TuneIcon /><Typography>control panel &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; </Typography></Stack>} sx={{ alignSelf: 'start' }} disabled={txLoading} />
                                    <Tab label={<Stack direction="row" alignItems="center" gap={1}><CopyrightIcon /><Typography>owner panel &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</Typography></Stack>} sx={{ alignSelf: 'start' }} disabled={txLoading} />
                                    <Tab label={<Stack direction="row" alignItems="center" gap={1}><AccountTreeIcon /><Typography>on chain infos &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</Typography></Stack>} sx={{ alignSelf: 'start' }} disabled={txLoading} />
                                    <Tab label={<Stack direction="row" alignItems="center" gap={1}><BadgeIcon /><Typography>settings &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</Typography></Stack>} sx={{ alignSelf: 'start' }} disabled={txLoading} />
                                    
                                </Tabs>
                            </div>
                        </Grid>
                        <Grid sx={{ borderLeft: 0, borderColor: 'grey.600' }} item xs >
                            {tabIndex === 0 && (
                                <>
                                    <Typography align="left" variant="h3" sx={{ marginLeft: 3, marginBottom: 1 }}>&nbsp;</Typography>
                                    <Box sx={{
                                        marginLeft: 3,
                                        marginRight: 3
                                    }}>
                                        <ArtworkBox isMint={false} firstCharg={txLoading} projectId={projectId} chain_img={chain_img} chain_name={chain_name} artwork_img={artwork_img} artwork_title={artwork_title} artist_name={artist_name} creationDate={creationDate} pricePerToken={pricePerToken} network={network} totalSupply={totalSupply} maxSupply={maxSupply} contract_address={contract_address} etherscanUrl={etherscanUrl} />
                                    </Box>
                                    <Dashboard sameNetwork={sameNetwork} revealOption={revealOption} presaleOption={presaleOption} saleOption={saleOption} abiId={abiId} txLoading={txLoading} reservedAmount={reservedAmount} balance={balance} artwork_title={artwork_title} artwork_img={artwork_img} artist_name={artist_name} projectId={projectId} creationDate={creationDate} chain_name={chain_name} network={network} chain_img={chain_img} contract_address={contract_address} presaleIsActive={presaleIsActive} presaleIsOver={presaleIsOver} saleIsActive={saleIsActive} saleIsOver={saleIsOver} revealIsOver={revealIsOver} totalSupply={totalSupply} pricePerToken={pricePerToken} maxSupply={maxSupply} mintData={mintData} etherscanUrl={etherscanUrl} websiteUrl={websiteUrl} openseaUrl={openseaUrl} secondaryUrl={secondaryUrl} twitterUrl={twitterUrl} instagramUrl={instagramUrl} opensea_black_svg={opensea_black_svg} etherscan_black_svg={etherscan_black_svg} twitter_black_svg={twitter_black_svg} insta_black_svg={insta_black_svg} />
                                </>
                            )}
                            {tabIndex === 1 && (
                                <>
                                    <Box sx={{ marginTop: 1, minHeight: '100vh' }}>
                                        <AdminControlPannel chain_name={chain_name} network={network} sameNetwork={sameNetwork} revealOption={revealOption} presaleOption={presaleOption} saleOption={saleOption} api_url={api_url} setPresaleData={setPresaleData} presaleData={presaleData} setTokenIds={setTokenIds} tokenIds={tokenIds} totalSupply={totalSupply} maxSupply={maxSupply} baseUri={baseUri} setBaseUri={setBaseUri} reservedAmount={reservedAmount} setReservedAmount={setReservedAmount} setTotalSupply={setTotalSupply} artistShare={artistShare} setArtistShare={setArtistShare} balance={balance} setBalance={setBalance} chain_img={chain_img} projectId={projectId} artwork_title={artwork_title} artwork_img={artwork_img} contract_address={contract_address} abi={abi} abiId={abiId} presaleIsActive={presaleIsActive} setPresaleIsActive={setPresaleIsActive} presaleIsOver={presaleIsOver} setPresaleIsOver={setPresaleIsOver} saleIsOver={saleIsOver} setSaleIsOver={setSaleIsOver} saleIsActive={saleIsActive} setSaleIsActive={setSaleIsActive} txLoading={txLoading} setTxLoading={setTxLoading} revealIsOver={revealIsOver} setRevealIsOver={setRevealIsOver} />
                                    </Box>
                                </>
                            )}
                            {tabIndex === 2 && (
                                <>
                                    <Box sx={{ marginTop: 1, minHeight: '100vh' }}>
                                        <AdminOwner chain_name={chain_name} network={network} sameNetwork={sameNetwork} revealOption={revealOption} presaleOption={presaleOption} saleOption={saleOption} fee={fee} setMintData={setMintData} api_url={api_url} websiteUrl={websiteUrl} setWebsiteUrl={setWebsiteUrl} twitterUrl={twitterUrl} setTwitterUrl={setTwitterUrl} instagramUrl={instagramUrl} setInstagramUrl={setInstagramUrl} openseaUrl={openseaUrl} setOpenseaUrl={setOpenseaUrl} secondaryUrl={secondaryUrl} setSecondaryUrl={setSecondaryUrl} banner_img={banner_img} setBanner_img={setBanner_img} setArtwork_img={setArtwork_img} artwork_presentation={artwork_presentation} setArtwork_presentation={setArtwork_presentation} setTokenIds={setTokenIds} tokenIds={tokenIds} totalSupply={totalSupply} maxSupply={maxSupply} baseUri={baseUri} setBaseUri={setBaseUri} reservedAmount={reservedAmount} setReservedAmount={setReservedAmount} setTotalSupply={setTotalSupply} artistShare={artistShare} setArtistShare={setArtistShare} balance={balance} setBalance={setBalance} chain_img={chain_img} projectId={projectId} artwork_title={artwork_title} setArtwork_title={setArtwork_title} artwork_img={artwork_img} contract_address={contract_address} abi={abi} abiId={abiId} presaleIsActive={presaleIsActive} setPresaleIsActive={setPresaleIsActive} presaleIsOver={presaleIsOver} setPresaleIsOver={setPresaleIsOver} saleIsOver={saleIsOver} setSaleIsOver={setSaleIsOver} presaleData={presaleData} saleIsActive={saleIsActive} setSaleIsActive={setSaleIsActive} txLoading={txLoading} setTxLoading={setTxLoading} revealIsOver={revealIsOver} setRevealIsOver={setRevealIsOver} />
                                    </Box>
                                </>
                            )}
                            {tabIndex === 4 && (
                                <>
                                    <Box sx={{ marginTop: 1, minHeight: '100vh' }}>
                                        <AdminSet chain_name={chain_name} network={network} sameNetwork={sameNetwork} revealOption={revealOption} presaleOption={presaleOption} saleOption={saleOption} api_url={api_url} crossmintId={crossmintId} setCrossmintId={setCrossmintId} websiteUrl={websiteUrl} setWebsiteUrl={setWebsiteUrl} twitterUrl={twitterUrl} setTwitterUrl={setTwitterUrl} instagramUrl={instagramUrl} setInstagramUrl={setInstagramUrl} openseaUrl={openseaUrl} setOpenseaUrl={setOpenseaUrl} secondaryUrl={secondaryUrl} setSecondaryUrl={setSecondaryUrl} banner_img={banner_img} setBanner_img={setBanner_img} setArtwork_img={setArtwork_img} artwork_presentation={artwork_presentation} setArtwork_presentation={setArtwork_presentation} setTokenIds={setTokenIds} tokenIds={tokenIds} totalSupply={totalSupply} maxSupply={maxSupply} baseUri={baseUri} setBaseUri={setBaseUri} reservedAmount={reservedAmount} setReservedAmount={setReservedAmount} setTotalSupply={setTotalSupply} artistShare={artistShare} setArtistShare={setArtistShare} balance={balance} setBalance={setBalance} chain_img={chain_img} projectId={projectId} artwork_title={artwork_title} setArtwork_title={setArtwork_title} artwork_img={artwork_img} contract_address={contract_address} abi={abi} abiId={abiId} presaleIsActive={presaleIsActive} setPresaleIsActive={setPresaleIsActive} presaleIsOver={presaleIsOver} setPresaleIsOver={setPresaleIsOver} saleIsOver={saleIsOver} setSaleIsOver={setSaleIsOver} presaleData={presaleData} saleIsActive={saleIsActive} setSaleIsActive={setSaleIsActive} txLoading={txLoading} setTxLoading={setTxLoading} revealIsOver={revealIsOver} setRevealIsOver={setRevealIsOver} />
                                    </Box>
                                </>
                            )}
                            {tabIndex === 3 && (
                                <>
                                    <Box sx={{ marginTop: 1, minHeight: '100vh' }}>
                                        <AdminOnChainInfos chain_name={chain_name} sameNetwork={sameNetwork} etherscanOwner={etherscanOwner} revealOption={revealOption} presaleOption={presaleOption} saleOption={saleOption} projectId={projectId} etherscanUrl={etherscanUrl} pricePerToken={pricePerToken} maxPublicMint={maxPublicMint} owner={owner} name={name} symbol={symbol} websiteUrl={websiteUrl} setWebsiteUrl={setWebsiteUrl} twitterUrl={twitterUrl} setTwitterUrl={setTwitterUrl} instagramUrl={instagramUrl} setInstagramUrl={setInstagramUrl} openseaUrl={openseaUrl} setOpenseaUrl={setOpenseaUrl} secondaryUrl={secondaryUrl} setSecondaryUrl={setSecondaryUrl} banner_img={banner_img} setBanner_img={setBanner_img} setArtwork_img={setArtwork_img} artwork_presentation={artwork_presentation} setArtwork_presentation={setArtwork_presentation} setTokenIds={setTokenIds} tokenIds={tokenIds} totalSupply={totalSupply} maxSupply={maxSupply} baseUri={baseUri} setBaseUri={setBaseUri} reservedAmount={reservedAmount} setReservedAmount={setReservedAmount} setTotalSupply={setTotalSupply} artistShare={artistShare} setArtistShare={setArtistShare} balance={balance} setBalance={setBalance} chain_img={chain_img} artwork_title={artwork_title} setArtwork_title={setArtwork_title} artwork_img={artwork_img} contract_address={contract_address} abi={abi} abiId={abiId} presaleIsActive={presaleIsActive} setPresaleIsActive={setPresaleIsActive} presaleIsOver={presaleIsOver} setPresaleIsOver={setPresaleIsOver} saleIsOver={saleIsOver} setSaleIsOver={setSaleIsOver} presaleData={presaleData} saleIsActive={saleIsActive} setSaleIsActive={setSaleIsActive} txLoading={txLoading} setTxLoading={setTxLoading} network={network} revealIsOver={revealIsOver} setRevealIsOver={setRevealIsOver} />
                                    </Box>
                                </>
                            )}


                        </Grid>
                    </Grid>
                </>
                :
                <NotFoundCompo />
            }
        </div >
    )
}
export default Launchpad;