import React, { useState, useEffect } from 'react';
//import { useParams } from 'react-router-dom';
import axios from 'axios';

//import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Checkbox from '@mui/material/Checkbox';
import Avatar from "boring-avatars";

import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';

import CircularProgress from '@mui/material/CircularProgress';

import { UserContext } from '../App';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


const ArtworkBox = (props) => {

    const { isSigned, account, api_url } = React.useContext(UserContext);
    const { isMint, firstCharg, projectId, chain_img, chain_name, artwork_img, artwork_title, artist_name, creationDate, pricePerToken, network, totalSupply, maxSupply } = props;

    const [following, setFollowing] = useState(false)

    useEffect(() => {
        if (isMint && isSigned) {
            axios.get(api_url + "/userFollowProject/" + String(account) + "/" + projectId, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                if (response.data.status === "success") {
                    if (response.data.isFollowings) {
                        setFollowing(true)
                    } else {
                        setFollowing(false)
                    }
                }
                //console.log("ARTWORKBOX : ", response.data)
            })
        }
    }, [account, isSigned, api_url, projectId, isMint]);

    function followUnfollow() {
        axios.post(api_url + "/followings", { "walletAddress": String(account), "project": projectId }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response2) => {
            //console.log(response2)
            axios.get(api_url + "/userFollowProject/" + String(account) + "/" + projectId, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                if (response.data.status === "success") {
                    if (response.data.isFollowings) {
                        setFollowing(true)
                    } else {
                        setFollowing(false)
                    }
                }
                //console.log("ARTWORKBOX : ", response.data)
            })
        })
    }

    return (
        <div>
            <Box className="artwork-box">
                <Grid container sx={{ alignItems: "flex-end", display: { xs: 'none', md: 'flex' } }} spacing={5} columns={2}>
                    <Grid column={1}>
                        <Card sx={{
                            width: '350px',
                            height: '350px',
                            borderRadius: '5px',
                            display: "flex",
                            alignItems: "center",
                            boxShadow: 7
                        }}>
                            {artwork_img === "" || artwork_img === null ?
                                <Avatar
                                    size={400}
                                    name={projectId}
                                    square
                                />
                                :
                                <CardMedia
                                    component="img"
                                    image={artwork_img}
                                    alt="artwork img"
                                />
                            }
                        </Card>
                    </Grid>
                    <Grid
                        item
                        xs
                        column={1}>
                        <Card sx={{
                            borderRadius: '5px',
                            boxShadow: 0,
                            display: "flex",
                            alignItems: "flex-end",
                            backgroundColor: 'transparent',
                        }}>
                            <CardContent sx={{
                                minWidth: '35vw',
                                display: 'row',
                                alignItems: 'flex-end',
                                justifyContent: 'left',
                            }}>
                                <Typography align="left">
                                    <h1 className="artwork-title">
                                        {artwork_title}
                                        {isMint && isSigned ?
                                            <Checkbox
                                                {...label}
                                                checked={following}
                                                onChange={followUnfollow}
                                                icon={<BookmarkBorderIcon />}
                                                checkedIcon={<BookmarkIcon />}
                                            />
                                            :
                                            <></>
                                        }
                                    </h1>
                                    <h2 className="artwork-title"><span className="by-artist">by</span> {artist_name}</h2>
                                    <h3 className="artwork-title"><span className="by-artist">created</span> {creationDate.toString().split(' ')[1]} {creationDate.toString().split(' ')[2]} {creationDate.toString().split(' ')[3]}</h3>

                                    <Box sx={{ border: 1, borderColor: 'grey.600', borderRadius: 2 }}>
                                        <Box
                                            sx={{
                                                width: '100%',
                                            }}>
                                            <Grid sx={{ paddingTop: 2, paddingBottom: 2 }} container direction="row" justifyContent="flex-start" alignItems="center" spacing={1} columns={4}>
                                                <Grid sx={{ borderRight: 1, borderColor: 'grey.600' }} item md={1} xs={4}>
                                                    <Typography align="center">
                                                        <span className='stats-variables'>unit price</span>
                                                        <br />
                                                        <span className='stats-values' >{pricePerToken}</span>
                                                        
                                                    </Typography>
                                                </Grid>
                                                <Grid sx={{ borderRight: 1, borderColor: 'grey.600' }} item md={1} xs={4}>
                                                    <Typography align="center">
                                                        <span className='stats-variables'>supply</span>
                                                        <br />
                                                        <span className='stats-values' >{maxSupply}</span>
                                                    </Typography>
                                                </Grid>

                                                <Grid sx={{ borderRight: 1, borderColor: 'grey.600' }} item md={1} xs={4}>
                                                    <Typography align="center">
                                                        <span className='stats-variables'>minted</span>
                                                        <br />
                                                        {firstCharg ?
                                                            <CircularProgress color="inherit" size={27} />
                                                            :
                                                            <span className='stats-values' >{totalSupply}</span>
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={4}>
                                                    <Typography align="center">
                                                        <span className='stats-variables'>{chain_name[network]}</span>
                                                        <br />
                                                        <img alt="logo" width={30} height={30} src={chain_img[network]} />
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>



                <Card sx={{
                    width: '70vw',
                    height: '70vw',
                    borderRadius: '5px',
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: 7,
                    display: { xs: 'flex', md: 'none' }
                }}>
                    {artwork_img === "" || artwork_img === null ?
                        <Avatar
                            size={800}
                            name={projectId}
                            square
                        />
                        :
                        <CardMedia
                            component="img"
                            image={artwork_img}
                            alt="artwork img"
                            sx={{
                                width: '70vw',
                                height: '70vw'
                            }}
                        />
                    }
                </Card>


                <Card sx={{
                    borderRadius: '5px',
                    boxShadow: 0,
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: 'transparent',
                    display: { xs: 'flex', md: 'none' }
                }}>
                    <CardContent sx={{
                        minWidth: '80vw',
                        display: 'row',
                        alignItems: 'flex-end',
                        justifyContent: 'center',
                    }}>
                        <Typography align="left">
                            <h1 className="artwork-title">
                                {artwork_title}
                                <Checkbox
                                    {...label}
                                    checked={following}
                                    onChange={followUnfollow}
                                    icon={<BookmarkBorderIcon />}
                                    checkedIcon={<BookmarkIcon />}
                                />
                            </h1>
                            <h2 className="artwork-title"><span className="by-artist">by</span> {artist_name}</h2>
                            <h3 className="artwork-title"><span className="by-artist">created</span> {creationDate.toString().split(' ')[1]} {creationDate.toString().split(' ')[2]} {creationDate.toString().split(' ')[3]}</h3>

                            <Box sx={{ border: 1, borderColor: 'grey.600', borderRadius: 2 }}>
                                <Box
                                    sx={{
                                        width: '100%',
                                    }}>
                                    <Grid sx={{ paddingTop: 2, paddingBottom: 2 }} container direction="row" justifyContent="flex-start" alignItems="center" spacing={1} columns={4}>
                                        <Grid sx={{ borderRight: 1, borderColor: 'grey.600' }} item xs={1}>
                                            <Typography align="center">
                                                <span className='stats-variables'>unit price</span>
                                                <br />
                                                <span className='stats-values-resp' >{pricePerToken}</span>
                                            </Typography>
                                        </Grid>
                                        <Grid sx={{ borderRight: 1, borderColor: 'grey.600' }} item xs={1}>
                                            <Typography align="center">
                                                <span className='stats-variables'>supply</span>
                                                <br />
                                                <span className='stats-values-resp' >{maxSupply}</span>
                                            </Typography>
                                        </Grid>

                                        <Grid sx={{ borderRight: 1, borderColor: 'grey.600' }} item xs={1}>
                                            <Typography align="center">
                                                <span className='stats-variables'>minted</span>
                                                <br />
                                                {firstCharg ?
                                                    <CircularProgress color="inherit" size={18} />
                                                    :
                                                    <span className='stats-values-resp' >{totalSupply}</span>
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item xs={1}>
                                            <Typography align="center">
                                                <span className='stats-variables'>{chain_name[network]}</span>
                                                <br />
                                                <img alt="logo" width={20} height={20} src={chain_img[network]} />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Typography>
                    </CardContent>
                </Card>

            </Box>
        </div >
    );
};

export default ArtworkBox;