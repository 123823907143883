import { useState, useEffect } from 'react';
import * as React from 'react';
import { Outlet, Link } from "react-router-dom";
import { ethers } from 'ethers';
import axios from 'axios';
import detectEthereumProvider from '@metamask/detect-provider'

import { Paper, Stack, Typography, Button } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import LinkMui from '@mui/material/Link';

import WalletIcon from '@mui/icons-material/Wallet';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import CakeIcon from '@mui/icons-material/Cake';
import MenuIcon from '@mui/icons-material/Menu';

import './App.css';

export const UserContext = React.createContext();

const hexToDecimal = hex => parseInt(hex, 16);

function App() {

  const [isMMInstalled, setIsMMInstalled] = useState(true);
  const [isConnected, setIsConnected] = useState(false);
  const [nonce, setNonce] = useState("")
  const [isSigned, setIsSigned] = useState(false);
  const [account, setAccount] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [accountNetwork, setAccountNetwork] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);

  const logo_svg = "https://res.cloudinary.com/drx6j77zq/image/upload/v1671718848/projects/placeholders/crmbl__13_-removebg-preview_yps1l3.png";
  const chain_img = { 1: "https://res.cloudinary.com/drx6j77zq/image/upload/v1667878231/projects/placeholders/ethereum_logo_nr1fla.svg", 4: "https://drive.google.com/uc?export=view&id=13LO-fowcktxlhnGv5ri6sZUQRs17-oZQ", 5: "https://res.cloudinary.com/drx6j77zq/image/upload/v1667878492/projects/placeholders/goerli.png", 11155111: "https://res.cloudinary.com/drx6j77zq/image/upload/v1667878401/projects/placeholders/sepolia.png" }
  const chain_name = { 1: "ethereum", 4: "rinkeby", 5: "goerli", 11155111: "sepolia" }
  const chain_etherscan_name = { 1: "", 4: "rinkeby.", 5: "goerli.", 11155111: "sepolia." }
  const api_url =  "https://crmbl-app.cleverapps.io" // "http://127.0.0.1:5000" // 

  function consoleVariables() {
    console.log("no :", nonce)
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenu2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
    setAnchorEl(null);
  };

  //const printToken = () => {
  //  console.log("token :", localStorage.getItem('token'))
  //}

  useEffect(() => {
    //console.log("RELOAD APP BAR")
    consoleVariables()
    const launchMM = async () => {
      const provider = await detectEthereumProvider()
      //console.log("provider :", provider)
      // provider === window.ethereum but works with mobile too
      if (provider) {
        //window.ethereum.request({ method: 'eth_accounts' }).then(accountsChanged).catch(console.error);
        window.ethereum.request({ method: 'eth_accounts' }).then(connectAccount).catch(console.error);
        window.ethereum.request({ method: 'eth_chainId' }).then(chainChanged).catch(console.error);
        window.ethereum.on("accountsChanged", accountsChanged);
        window.ethereum.on("chainChanged", chainChanged);
        setIsMMInstalled(true);
      } else {
        setIsMMInstalled(false);
      }
    }
    launchMM()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const connectAccount = (acc) => {
    //console.log("APP CONNECTACCOUNT")
    if (acc.length > 0) {
      //console.log("CONNECTACCOUNT | connected :", [ethers.utils.getAddress(acc[0])][0])
      setAccount([ethers.utils.getAddress(acc[0])][0])
      setIsConnected(true);
      const token = localStorage.getItem('token')
      //console.log("CONNECTACCOUNT | token:", token)
      if (token === "") {
        //console.log("CONNECTACCOUNT | token does not exist...")
        //getNonce([ethers.utils.getAddress(acc[0])][0])
      } else {
        //console.log("CONNECTACCOUNT | token does exist, verifying it...")
        verifyToken(token, [ethers.utils.getAddress(acc[0])][0])
      }
    } else {
      setIsProcessing(false)
      //console.log("CONNECTACCOUNT | not connected")
      setAccount(null);
      setIsConnected(false)
    }
  }

  //const getNonce = (newAccount) => {
  //  console.log("APP GETNONCE")
  //  axios.post(api_url + "/users", { walletAddress: newAccount.toString() }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
  //    setIsProcessing(false)
  //    setNonce(response.data.user.nonce)
  //    //////console.log("GETNONCE | nonce :", response.data.user.nonce)
  //  });
  //}

  const getNonceAndSign = (newAccount) => {
    //console.log("APP GETNONCEANDSIGN")
    //console.log("APP GETNONCEANDSIGN | newAccount.toString() :", newAccount.toString())
    //console.log("APP GETNONCEANDSIGN | localStorage.getItem('token') :", localStorage.getItem('token')) 
    axios.post(api_url + "/users", { walletAddress: newAccount.toString() }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
      setNonce(response.data.user.nonce)
      //console.log("GETNONCEANDSIGN | nonce :", response.data.user.nonce)
      //console.log("GETNONCEANDSIGN | signing nonce...",)
      signNonce(response.data.user.nonce, newAccount.toString())
    });
  }

  async function signNonce(nonce, publicAddress) {
    //console.log("APP SIGNNONCE")
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();
    //console.log("SIGNNONCE | signer :", signer, " | type:", typeof signer)
    //const signature = await signer.signMessage(`Hi there from crmbl! We need your signature to log you in (this won't cost you any ether).\n\nMessage ID: ${nonce}`, publicAddress);
    //console.log("SIGNNONCE | signature :", signature, " | type:", typeof signature)
    //console.log("SIGNNONCE | publicAddress :", publicAddress, " | type:", typeof publicAddress)
    signer.signMessage(`Hi there from crmbl! We need your signature to log you in (this won't cost you any ether).\n\nMessage ID: ${nonce}`, publicAddress)
      .then(signature => {
        //do whatever you want with tx
        //console.log("SIGNNONCE | signature :", signature, " | type:", typeof signature)
        //console.log("SIGNNONCE | publicAddress :", publicAddress, " | type:", typeof publicAddress)
        axios.post(api_url + "/users/verifySignature", { walletAddress: publicAddress, signature: signature }).then((response2) => {
          //console.log("SIGNNONCE |", response2)
          //console.log("SIGNNONCE |", response2.data.status)
          setIsProcessing(false)
          if (response2.data.status === "success") {
            localStorage.setItem('token', response2.data.token)
            setIsSigned(true)
          } else {
            localStorage.setItem('token', "")
            setIsSigned(false)
          }
        });
      })
      .catch(e => {
        //console.log(e.code)
        if (e.code === "ACTION_REJECTED") {
          //user rejected the transaction
          //console.log("SIGNNONCE | DENIED")
          setIsProcessing(false)
        }
      });


  }

  async function verifyToken(token, publicAddress) {
    //console.log("APP VERIFYTOKEN")
    axios.post(api_url + "/users/verifyToken", { walletAddress: publicAddress, token: token }).then((response) => {
      //console.log("VERIFYTOKEN | res :", response.data)
      setIsProcessing(false)
      if (response.data === "is valid") {
        setIsSigned(true)
      } else {
        logOut()
      }
    })
  }


  const connectHandler = async () => {
    //console.log("APP CONNECTHANDLER")
    if (window.ethereum) {
      setIsProcessing(true)
      try {
        const res = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setIsProcessing(false)
        setAccount([ethers.utils.getAddress(res[0])]);
        setIsMMInstalled(true);
        setIsConnected(true);
      } catch (err) {
        //console.error(err);
        setIsConnected(false);
        setAccount(null);
        setIsMMInstalled(true);
        setIsProcessing(false)
      }
    } else {
      setIsConnected(false);
      setIsMMInstalled(false);
      setAccount(null);
      setIsProcessing(false)
    }
  };

  const signHandler = async () => {
    //console.log("APP SIGNHANDLER")
    setIsProcessing(true)
    getNonceAndSign(account)
  }

  const accountsChanged = (newAccount) => {
    //console.log("APP ACCOUNTSCHANGED")
    localStorage.setItem('token', "")
    setIsSigned(false)
    if (newAccount.length > 0) {
      //console.log("ACCOUNTSCHANGED | account : ", ethers.utils.getAddress(newAccount[0]));
      //console.log("ACCOUNTSCHANGED | numChanges : ", numChanges);
      setAccount([ethers.utils.getAddress(newAccount[0])]);
      setIsConnected(true);
      //if (numChanges === 0) {
      //  getNonceAndSign([ethers.utils.getAddress(newAccount[0])]);
      //  numChanges = 1
      //} else {
      //  numChanges = 1
      //  getNonce([ethers.utils.getAddress(newAccount[0])]);
      //}
    } else {
      setIsProcessing(false)
      //console.log("ACCOUNTSCHANGED | account : disconnected");
      setAccount(null);
      setIsConnected(false);
    }
  };

  const chainChanged = (chainId) => {
    //console.log("APP CHAINCHANGED")
    //console.log(hexToDecimal(chainId))
    setAccountNetwork(hexToDecimal(chainId))
  };

  const logOut = async () => {
    localStorage.setItem('token', "")
    setIsProcessing(false)
    setIsSigned(false)
  }

  const signAndClose = async () => {
    signHandler();
    handleClose2();
  }





  

  return (
    <div className="App">
      <AppBar position="fixed" style={{ background: '#F7F9FD' }} elevation={0}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>

            <Link component={Link} to="/" underline="hover">
              <img alt="logo" height={45} src={logo_svg} />
            </Link>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}></Box>

            {!isMMInstalled ?
              <Typography variant="button" sx={{color:"black"}}>🦊 install metamask </Typography>
              :
              <>
                {isProcessing ?
                  <Stack justifyContent="center" direction="row" alignItems="center" gap={1}>
                    <CircularProgress style={{ 'color': 'black' }} size={20} />
                    <Typography color="black" variant="button">connecting</Typography>
                  </Stack>
                  :
                  <>
                    {isConnected ?
                      <>
                        {isSigned ?
                          <>
                            <Box sx={{display: { xs: 'flex', md: 'none' }}}>
                              <Button size="small" onClick={handleMenu} variant="contained" ><AccountCircleIcon style={{ color: "#fff" }} />&nbsp;{String(account).substring(0, 4)}...{String(account).substring(38)}</Button>
                              <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                              >
                                <MenuItem component={Link} to={"/profile/" + account} onClick={handleClose}><AccountCircleIcon style={{ color: "#000" }} />&nbsp;{String(account).substring(0, 4)}...{String(account).substring(38)}</MenuItem>
                                <MenuItem component={Link} to={"/create"} onClick={handleClose}><RocketLaunchIcon style={{ color: "#000" }} />&nbsp;Create</MenuItem>
                                <MenuItem component={LinkMui} href="https://www.crmbl.io/" target="_blank" onClick={handleClose}><CakeIcon fontSize="small" />&nbsp;&nbsp;About</MenuItem>
                                <MenuItem onClick={logOut}><LogoutIcon style={{ color: "#000" }} />&nbsp;Log Out</MenuItem>
                              </Menu>
                            </Box>

                            
                            <Button size="small" href="https://www.crmbl.io/" target="_blank" to="https://www.crmbl.io/" style={{color:"#fff"}} sx={{ marginRight: 1, display: { xs: 'none', md: 'flex' } }} variant="contained"><CakeIcon fontSize="small" />&nbsp;About</Button>
                            <Button size="small" component={Link} to={"/create"} sx={{ marginRight: 1, display: { xs: 'none', md: 'flex' } }} variant="contained"><RocketLaunchIcon fontSize="small" style={{ color: "#fff" }} />&nbsp;create</Button>
                            <Button size="small" component={Link} to={"/profile/" + account} variant="contained" sx={{ marginRight: 0, display: { xs: 'none', md: 'flex' } }} ><AccountCircleIcon fontSize="small" style={{ color: "#fff" }} />&nbsp;{String(account).substring(0, 4)}...{String(account).substring(38)}</Button>
                            <Button size="small" onClick={logOut} sx={{ marginLeft: 1, display: { xs: 'none', md: 'flex' } }} variant="outlined" color="error" ><LogoutIcon fontSize="small" />&nbsp;Log out</Button>
                            
                          </>
                          :
                          <>

                            <Box sx={{display: { xs: 'flex', md: 'none' }}}>
                              <Button size="small" onClick={handleMenu2} variant="contained" ><MenuIcon fontSize="small" style={{ color: "#fff" }} /></Button>
                              <Menu
                                id="menu-appbar2"
                                anchorEl={anchorEl2}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                                open={Boolean(anchorEl2)}
                                onClose={handleClose2}
                              >
                                <MenuItem onClick={signAndClose}><LoginIcon style={{ color: "#000" }} />&nbsp;Log in</MenuItem>
                                <MenuItem component={Link} to={"/create"} onClick={handleClose}><RocketLaunchIcon style={{ color: "#000" }} />&nbsp;Create</MenuItem>
                                <MenuItem component={LinkMui} href="https://www.crmbl.io/" target="_blank" onClick={handleClose2}><CakeIcon style={{ color: "#000" }}/>&nbsp;About</MenuItem>
                                
                              </Menu>
                            </Box>
                            
                            
                            <Button size="small" href="https://www.crmbl.io/" target="_blank" to="https://www.crmbl.io/" style={{color:"#fff"}} sx={{ marginRight: 1, display: { xs: 'none', md: 'flex' } }} variant="contained"><CakeIcon fontSize="small" />&nbsp;about</Button>
                            <Button size="small" component={Link} to={"/create"} sx={{ marginRight: 1, display: { xs: 'none', md: 'flex' } }} variant="contained"><RocketLaunchIcon fontSize="small" style={{ color: "#fff" }} />&nbsp;create</Button>
                            
                            <Button size="small" sx={{ display: { xs: 'none', md: 'flex' } }} onClick={signHandler} variant="contained"><LoginIcon fontSize="small" style={{ color: "#fff" }} />&nbsp;Log in</Button>
                            <Button size="small" disabled sx={{ marginLeft: 1, display: { xs: 'flex', md: 'flex' } }} variant="contained"><WalletIcon fontSize="small" style={{ color: "#fff" }} />&nbsp;{String(account).substring(0, 4)}...{String(account).substring(38)}</Button>
                            
                          </>
                        }
                      </>
                      :
                      <>
                      <Button size="small" href="https://www.crmbl.io/" target="_blank" to="https://www.crmbl.io/" style={{color:"#fff"}} sx={{ marginRight: 1, display: { xs: 'none', sm: 'flex' } }} variant="contained"><CakeIcon fontSize="small" />&nbsp;about</Button>
                      <Button size="small" component={Link} to={"/create"} sx={{ marginRight: 1, display: { xs: 'none', md: 'flex' } }} variant="contained"><RocketLaunchIcon fontSize="small" style={{ color: "#fff" }} />&nbsp;create</Button>
                      <Button size="small" onClick={connectHandler} variant="contained"><WalletIcon fontSize="small" style={{ color: "#fff" }} />&nbsp;connect wallet </Button>
                      </>
                    }
                  </>
                }
              </>
            }
          </Toolbar>
        </Container>
      </AppBar>
      <UserContext.Provider value={{ isMMInstalled, isConnected, isSigned, account, accountNetwork, chain_img, chain_name, chain_etherscan_name, api_url }}>
        <Outlet />
      </UserContext.Provider>
      <Paper elevation={0} sx={{ p: 7, marginTop: 2 }}>
        <Typography variant="subtitle1">all right reserved, crmbl 2022</Typography>
        {/*<Stack spacing={2}>
          <Typography variant="h6"> is mm installed : {String(isMMInstalled)} </Typography>
          <Typography variant="h6"> account logged in : {String(account).substring(0, 4)}...{String(account).substring(38)} </Typography>
          <Typography variant="h6"> is connected : {String(isConnected)} </Typography>
          <Typography variant="h6"> is signed : {String(isSigned)} </Typography>
          <Typography variant="h6"> network : {String(accountNetwork)} </Typography>
          <Button onClick={getLocalStorage} variant="contained">getLocalStorage</Button>
          <Button fullWidth component={Link} to={"/profile/" + account} variant="contained" >profile</Button>
        </Stack>
        <Button onClick={printToken} variant="contained">getLocalStorage</Button>*/}
      </Paper>
    </div>
  );
};

export default App;