import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

//import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import EditIcon from '@mui/icons-material/Edit';
import BadgeIcon from '@mui/icons-material/Badge';
import LinkIcon from '@mui/icons-material/Link';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import LoopIcon from '@mui/icons-material/Loop';
import CreditCardIcon from '@mui/icons-material/CreditCard';

import Avatar from "boring-avatars";


import { ethers } from "ethers";

//import { UserContext } from '../App';

//import CustomCountdown from './CustomCountdown';

const AdminSet = (props) => {

    //const { isMMInstalled, isConnected, account, accountNetwork } = React.useContext(UserContext);

    const { abiId, crossmintId, setCrossmintId, network, chain_name, sameNetwork, api_url, setArtwork_img, artwork_presentation, setArtwork_presentation, baseUri, setBaseUri, projectId, artwork_title, setArtwork_title, artwork_img, banner_img, setBanner_img, contract_address, abi, txLoading, setTxLoading, websiteUrl, setWebsiteUrl, twitterUrl, setTwitterUrl, instagramUrl, setInstagramUrl, openseaUrl, setOpenseaUrl, secondaryUrl, setSecondaryUrl } = props;

    
    const [baseUriTemp, setBaseUriTemp] = useState("")
    const [baseUriChanging, setBaseUriChanging] = useState(false)

    const [titleTemp, setTitleTemp] = useState("")
    const [titleChanging, setTitleChanging] = useState(false)
    const [idTemp, setIdTemp] = useState("")
    const [idChanging, setIdChanging] = useState(false)
    const [idNotUnique, setIdNotUnique] = useState(false)
    const [presentationTemp, setPresentationTemp] = useState("")
    const [presentationChanging, setPresentationChanging] = useState(false)

    const [selectedImageFile, setSelectedImageFile] = useState();
    const [isImageFilePicked, setIsImageFilePicked] = useState(false);
    const [imageFormatNotOk, setImageFormatNotOk] = useState(false);

    const [selectedBannerFile, setSelectedBannerFile] = useState();
    const [isBannerFilePicked, setIsBannerFilePicked] = useState(false);
    const [bannerFormatNotOk, setBannerFormatNotOk] = useState(false);
    const [bannerFormatNotOkMessage, setBannerFormatNotOkMessage] = useState("");

    const [crossmintIdTemp, setCrossmintIdTemp] = useState("")
    const [crossmintIdChanging, setCrossmintIdChanging] = useState(false)

    const [websiteUrlTemp, setWebsiteUrlTemp] = useState("")
    const [websiteUrlChanging, setWebsiteUrlChanging] = useState(false)
    const [twitterUrlTemp, setTwitterUrlTemp] = useState("")
    const [twitterUrlChanging, setTwitterUrlChanging] = useState(false)
    const [instagramUrlTemp, setInstagramUrlTemp] = useState("")
    const [instagramUrlChanging, setInstagramUrlChanging] = useState(false)
    const [openseaUrlTemp, setOpenseaUrlTemp] = useState("")
    const [openseaUrlChanging, setOpenseaUrlChanging] = useState(false)
    const [secondaryUrlTemp, setSecondaryUrlTemp] = useState("")
    const [secondaryUrlChanging, setSecondaryUrlChanging] = useState(false)

    const navigate = useNavigate();


    useEffect(() => {
    }, []);


    async function handleSubmitBaseUri(event) {
        try {
            event.preventDefault();
            console.log('base URI:', baseUriTemp);

            const provider = new ethers.providers.Web3Provider(window.ethereum)
            await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner();

            const SmartContract = new ethers.Contract(contract_address, abi, signer);

            const tx = await SmartContract.setBaseURI(baseUriTemp);
            console.log(`Transaction hash: ${tx.hash}`);
            setTxLoading(true)

            const interval = setInterval(() => {
                txnCheck(tx.hash).then((response) => {
                    try {
                        console.log("res : ", response.hash)
                        axios.put(api_url + "/projects/" + projectId, { baseUri: baseUriTemp }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response1) => {
                            console.log("baseUri added")
                            setBaseUri(baseUriTemp)
                            setBaseUriTemp("")
                            setTxLoading(false)
                            clearInterval(interval)
                            setBaseUriChanging(false)
                        });
                    } catch (error) {
                    }
                });
                console.log("baseUri set on going")
            }, 1000);

        } catch (error) {
            console.error(error)
        }
    }


    async function handleSubmitTitle(event) {
        try {
            event.preventDefault();
            console.log('title: ', titleTemp);
            axios.put(api_url + "/projects/" + projectId, { title: titleTemp }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                setArtwork_title(titleTemp)
                setTitleChanging(false)
            });

        } catch (error) {
            console.log(error)
        }
    }

    async function handleSubmitPresentation(event) {
        try {
            event.preventDefault();
            console.log('presentation: ', presentationTemp);
            axios.put(api_url + "/projects/" + projectId, { presentation: presentationTemp }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                setArtwork_presentation(presentationTemp)
                setPresentationChanging(false)
            });

        } catch (error) {
            console.log(error)
        }
    }

    async function handleSubmitId(event) {
        try {
            event.preventDefault();
            console.log('id: ', idTemp);
            axios.put(api_url + "/projects/setId/" + projectId, { id: idTemp }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                console.log(response)
                if (response.data.status === "fail") {
                    console.log("already exists !")
                    setIdNotUnique(true)
                } else {
                    setIdChanging(false)
                    navigate("/dashboard/" + idTemp);
                    setIdNotUnique(false)
                }

            });

        } catch (error) {
            console.log(error)
        }
    }



    const txnCheck = async (txnHash) => {

        const provider = new ethers.providers.Web3Provider(window.ethereum)
        await provider.send("eth_requestAccounts", []);
        let txn_test = await provider.getTransaction(txnHash);
        if (txn_test) {
            if (txn_test.blockNumber) {
                console.log("txn_test :", txn_test);
                return txn_test
            }
        }

    }

    const changeImageHandler = (event) => {
        console.log(event.target.files[0].type.substring(event.target.files[0].type.length - 3))
        if (event.target.files[0].type.substring(event.target.files[0].type.length - 4) === "jpeg" || event.target.files[0].type.substring(event.target.files[0].type.length - 3) === "jpg" || event.target.files[0].type.substring(event.target.files[0].type.length - 3) === "png") {
            setSelectedImageFile(event.target.files[0]);
            setIsImageFilePicked(true);
            setImageFormatNotOk(false);
        } else {
            setSelectedImageFile(null);
            setIsImageFilePicked(false);
            setImageFormatNotOk(true)
            console.log("choose a jpg or png")
        }
    };



    const handleImageSubmission = () => {
        const formData = new FormData();
        console.log(selectedImageFile)
        formData.append('file', selectedImageFile);

        try {
            axios.put(api_url + "/projects/setImg/" + projectId, formData, { headers: { 'Content-Type': selectedImageFile.type, Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                console.log(response)
                setArtwork_img(response.data.data['url'])
                setSelectedImageFile(null);
                setIsImageFilePicked(false);
                setImageFormatNotOk(false)
            });

        } catch (error) {
            console.log(error)
        }

    }

    const handleImageDelete = () => {
        try {
            axios.put(api_url + "/projects/deleteImg/" + projectId, {}, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                console.log(response)
                setArtwork_img("")
                setSelectedImageFile(null);
                setIsImageFilePicked(false);
                setImageFormatNotOk(false)
            });

        } catch (error) {
            console.log(error)
        }
    }

    const handleBannerDelete = () => {
        try {
            axios.put(api_url + "/projects/deleteBanner/" + projectId, {}, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                console.log(response)
                setBanner_img("")
                setSelectedBannerFile(null);
                setIsBannerFilePicked(false);
                setBannerFormatNotOk(false)
            });

        } catch (error) {
            console.log(error)
        }
    }


    const changeBannerHandler = (event) => {
        console.log(event.target.files[0].type.substring(event.target.files[0].type.length - 3))
        console.log(event.target.files[0])
        if (event.target.files[0].type.substring(event.target.files[0].type.length - 4) === "jpeg" || event.target.files[0].type.substring(event.target.files[0].type.length - 3) === "jpg" || event.target.files[0].type.substring(event.target.files[0].type.length - 3) === "png") {
            let img = new Image()
            img.src = window.URL.createObjectURL(event.target.files[0])
            img.onload = () => {
                if (img.width / img.height === 5 && img.width >= 500) {
                    setBannerFormatNotOk(false)
                    setSelectedBannerFile(event.target.files[0]);
                    setIsBannerFilePicked(true);
                } else {
                    setBannerFormatNotOk(true)
                    setBannerFormatNotOkMessage("We require a 5:1 image with a width greater than 500 pixels.")
                    setSelectedBannerFile(null);
                    setIsBannerFilePicked(false);
                }
            }

        } else {
            setSelectedBannerFile(null);
            setIsBannerFilePicked(false);
            setBannerFormatNotOk(true)
            setBannerFormatNotOkMessage("This format is not supported, choose a png or a jpg!")
            console.log("choose a jpg or png")
        }
    };



    const handleBannerSubmission = () => {
        const formData = new FormData();
        console.log(selectedBannerFile)
        formData.append('file', selectedBannerFile);

        try {
            axios.put(api_url + "/projects/setBanner/" + projectId, formData, { headers: { 'Content-Type': selectedBannerFile.type, Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                console.log(response)
                setBanner_img(response.data.data['url'])
                setSelectedBannerFile(null);
                setIsBannerFilePicked(false);
                setBannerFormatNotOk(false)
            });

        } catch (error) {
            console.log(error)
        }

    }

    async function handleSubmitCrossmintId(event) {
        try {
            event.preventDefault();
            console.log('crossmint id: ', instagramUrlTemp);
            axios.put(api_url + "/projects/" + projectId, { crossmintId: crossmintIdTemp }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                setCrossmintId(crossmintIdTemp)
                setCrossmintIdChanging(false)
            });

        } catch (error) {
            console.log(error)
        }
    }



    async function handleSubmitWebsiteUrl(event) {
        try {
            event.preventDefault();
            console.log('website url: ', websiteUrlTemp);
            if (websiteUrlTemp.substring(0, 4) !== "http") {
                axios.put(api_url + "/projects/" + projectId, { websiteUrl: 'https://' + websiteUrlTemp }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                    setWebsiteUrl('https://' + websiteUrlTemp)
                    setWebsiteUrlChanging(false)
                });
            } else {
                axios.put(api_url + "/projects/" + projectId, { websiteUrl: websiteUrlTemp }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                    setWebsiteUrl(websiteUrlTemp)
                    setWebsiteUrlChanging(false)
                });
            }
            
        } catch (error) {
            console.log(error)
        }
    }

    async function handleSubmitTwitterUrl(event) {
        try {
            event.preventDefault();
            console.log('twitter url: ', twitterUrlTemp);
            if (twitterUrlTemp.substring(0, 4) !== "http") {
                axios.put(api_url + "/projects/" + projectId, { twitterUrl: 'https://' + twitterUrlTemp }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                    setTwitterUrl('https://' + twitterUrlTemp)
                    setTwitterUrlChanging(false)
                });
            } else {
                axios.put(api_url + "/projects/" + projectId, { twitterUrl: twitterUrlTemp }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                    setTwitterUrl(twitterUrlTemp)
                    setTwitterUrlChanging(false)
                });
            }
            
        } catch (error) {
            console.log(error)
        }
    }

    async function handleSubmitInstagramUrl(event) {
        try {
            event.preventDefault();
            console.log('instagram url: ', instagramUrlTemp);
            if (instagramUrlTemp.substring(0, 4) !== "http") {
                axios.put(api_url + "/projects/" + projectId, { instagramUrl: 'https://' + instagramUrlTemp }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                    setInstagramUrl('https://' + instagramUrlTemp)
                    setInstagramUrlChanging(false)
                });
            }
             else {
                axios.put(api_url + "/projects/" + projectId, { instagramUrl: instagramUrlTemp }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                    setInstagramUrl(instagramUrlTemp)
                    setInstagramUrlChanging(false)
                });
             }

        } catch (error) {
            console.log(error)
        }
    }

    async function handleSubmitOpenseaUrl(event) {
        try {
            event.preventDefault();
            console.log('opensea url: ', openseaUrlTemp);
            if (openseaUrlTemp.substring(0, 4) !== "http") {
                axios.put(api_url + "/projects/" + projectId, { openseaUrl: 'https://' + openseaUrlTemp }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                    setOpenseaUrl('https://' + openseaUrlTemp)
                    setOpenseaUrlChanging(false)
                });
            } else {
                axios.put(api_url + "/projects/" + projectId, { openseaUrl: openseaUrlTemp }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                    setOpenseaUrl(openseaUrlTemp)
                    setOpenseaUrlChanging(false)
                });
            }

        } catch (error) {
            console.log(error)
        }
    }

    async function handleSubmitSecondaryUrl(event) {
        try {
            event.preventDefault();
            console.log('secondary url: ', secondaryUrlTemp);
            if (secondaryUrlTemp.substring(0, 4) !== "http") {
                axios.put(api_url + "/projects/" + projectId, { secondaryUrl: 'https://' + secondaryUrlTemp }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                    setSecondaryUrl('https://' + secondaryUrlTemp)
                    setSecondaryUrlChanging(false)
                });
            } else {
                axios.put(api_url + "/projects/" + projectId, { secondaryUrl: secondaryUrlTemp }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
                    setSecondaryUrl(secondaryUrlTemp)
                    setSecondaryUrlChanging(false)
                });
            }

        } catch (error) {
            console.log(error)
        }
    }



    return (
        <div>
            <Box>

                <Stack sx={{ marginLeft: 3, marginBottom: 3 }} direction="row" alignItems="center" gap={1}>
                    {artwork_img === "" || artwork_img === null ?
                        <Avatar
                            size={50}
                            name={projectId}
                            square
                        />
                        :
                        <img alt="logo" width={50} height={50} src={artwork_img} />
                    }

                    <Typography style={{ color: "#000" }} variant="h4">&nbsp;{artwork_title}</Typography>
                </Stack>

                <Stack sx={{ padding: 2, marginLeft: 3, marginRight: 3, marginBottom: 0, border: 0, borderRadius: 2, backgroundColor: "#E3F2FD", color: "#000" }} direction="row" alignItems="center" gap={1}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <BadgeIcon fontSize="large" />
                        <Typography variant="h3">SETTINGS</Typography>
                    </Stack>
                </Stack>


                {sameNetwork ?
                    <></>
                    :
                    <Box sx={{
                        borderRadius: '5px',
                        boxShadow: 0,
                        paddingTop: 1,
                        paddingBottom: 1,
                        paddingRight: 1,
                        paddingLeft: 1,
                        margin: 3,
                        backgroundColor: '#000',
                        color: "#fff"
                        }}>
                            <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
                                <LoopIcon style={{ color: "#fff" }} />
                                <Typography style={{ color: "#fff" }} variant="button">switch network to {chain_name[network]}</Typography>
                            </Stack>
                        </Box>
                }






                <Box sx={{
                    borderRadius: '5px',
                    boxShadow: 0,
                    paddingTop: 1,
                    paddingBottom: 1,
                    paddingRight: 1,
                    paddingLeft: 1,
                    marginLeft: 3,
                    marginRight: 3,
                    marginTop: 3,
                    backgroundColor: '#F5F5F5'
                }}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <InfoIcon style={{ color: "#000" }} />
                        <Typography style={{ color: "#000" }} variant="button">infos</Typography>
                    </Stack>

                    {txLoading ?
                        <Stack direction="row" alignItems="center" gap={1}>
                            <CircularProgress color="inherit" size={20} />
                            <Typography style={{ color: "#888" }} sx={{ fontStyle: 'italic' }} variant="button">applying changes, don't refresh your web browser</Typography>
                        </Stack>
                        :
                        <></>
                    }

                    <Grid container sx={{ margin: 3 }} direction="row" justifyContent="space-between" alignItems="center" spacing={0} columns={1}>

                        <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                            <Grid container sx={{ paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>image</Typography></Grid>
                                <Grid sx={{ borderRight: 0 }} item md={2} xs={4}>
                                    <Stack direction="row" alignItems="center" gap={1}>
                                        {artwork_img === "" ?
                                            <Avatar
                                                size={300}
                                                name={projectId}
                                                square
                                            />
                                            :
                                            <img alt="logo" width={300} height={300} src={artwork_img} />
                                        }
                                    </Stack>
                                </Grid>
                                <Grid sx={{ borderRight: 0 }} item md={2} xs={1}>
                                    <Typography align="right">
                                        <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={1}>
                                            <input
                                                accept="image/*"
                                                hidden
                                                id="icon-button-photo"
                                                onChange={changeImageHandler}
                                                type="file"
                                            />
                                            <label htmlFor="icon-button-photo">
                                                <IconButton color="default" component="span" disabled={!sameNetwork || txLoading}>
                                                    <EditIcon />
                                                </IconButton>
                                            </label>
                                            {isImageFilePicked ?
                                                <Stack direction="row" alignItems="center" gap={1}>
                                                    <Typography>{selectedImageFile.name}</Typography>
                                                    <Button disabled={!sameNetwork || imageFormatNotOk} variant="contained" onClick={handleImageSubmission}>
                                                        set
                                                    </Button>
                                                </Stack>
                                                : <></>
                                            }
                                            {artwork_img === "" ?
                                                <></>
                                                :
                                                <IconButton onClick={handleImageDelete} color="default" component="span" disabled={!sameNetwork || txLoading}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            }
                                        </Stack>
                                        {imageFormatNotOk ?
                                            <Alert severity="warning">This format is not supported, choose a png or a jpg!</Alert>
                                            :
                                            <></>
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                            <Grid container sx={{ paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>banner</Typography></Grid>
                                <Grid sx={{ borderRight: 0 }} item md={2} xs={4}>
                                    <Stack direction="row" alignItems="center" gap={1}>
                                        {banner_img === "" ?
                                            <img alt="logo" width={300} src="https://res.cloudinary.com/drx6j77zq/image/upload/v1666954663/projects/placeholders/banner.png" />
                                            :
                                            <img alt="logo" width={300} src={banner_img} />
                                        }
                                    </Stack>
                                </Grid>
                                <Grid sx={{ borderRight: 0 }} item md={2} xs={1}>
                                    <Typography align="right">
                                        <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={1}>
                                            <input
                                                accept="image/*"
                                                hidden
                                                id="icon-button-banner"
                                                onChange={changeBannerHandler}
                                                type="file"
                                            />
                                            <label htmlFor="icon-button-banner">
                                                <IconButton color="default" component="span" disabled={!sameNetwork || txLoading}>
                                                    <EditIcon />
                                                </IconButton>
                                            </label>
                                            {isBannerFilePicked ?
                                                <>
                                                    <Typography>{selectedBannerFile.name}</Typography>
                                                    <Button disabled={!sameNetwork || bannerFormatNotOk} variant="contained" onClick={handleBannerSubmission}>
                                                        set
                                                    </Button>
                                                </>
                                                : <></>
                                            }
                                            {banner_img === "" ?
                                                <></>
                                                :
                                                <IconButton onClick={handleBannerDelete} color="default" component="span" disabled={!sameNetwork || txLoading}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            }
                                        </Stack>
                                        {bannerFormatNotOk ?
                                            <Alert severity="warning">{bannerFormatNotOkMessage}</Alert>
                                            :
                                            <></>
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                            <Grid container sx={{ paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>title</Typography></Grid>
                                {!titleChanging ?
                                    <>
                                        <Grid sx={{ borderRight: 0 }} item md={3} xs={4}>
                                            <Typography variant="h4" align="left">{artwork_title}</Typography>
                                        </Grid>
                                        <Grid sx={{ borderRight: 0 }} item md={1} xs={1}>
                                            <Typography align="right">
                                                <IconButton disabled={!sameNetwork || txLoading} aria-label="edit" onClick={() => { setTitleTemp(artwork_title); setTitleChanging(true); }}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Typography>
                                        </Grid>
                                    </>
                                    :
                                    <Grid sx={{ borderRight: 0 }} item md={4} xs={4}>
                                        <form onSubmit={handleSubmitTitle}>
                                            <Grid container sx={{ marginLeft: 0, marginRight: 0, marginTop: 0 }} direction="row" justifyContent="flex-start" alignItems="top" spacing={1} columns={14}>
                                                <Grid item md={10} xs={14}>
                                                    <TextField value={titleTemp} onInput={e => setTitleTemp(e.target.value)} size="small" fullWidth id="title" label="title" variant="outlined" />
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={3} xs={14}>
                                                    <Button type="submit" size="large" fullWidth variant="contained">set</Button>
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={1} xs={14}>
                                                    <Button size="large" fullWidth variant="outlined" onClick={() => { setTitleChanging(false); }}>X</Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>


                        <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                            <Grid container sx={{ paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>id</Typography></Grid>
                                {!idChanging ?
                                    <>
                                        <Grid sx={{ borderRight: 0 }} item md={3} xs={4}>
                                            <Typography variant="h5" align="left">{projectId}</Typography>
                                        </Grid>
                                        <Grid sx={{ borderRight: 0 }} item md={1} xs={1}>
                                            <Typography align="right">
                                                <IconButton disabled={!sameNetwork || txLoading} aria-label="edit" onClick={() => { setIdTemp(projectId); setIdChanging(true); }}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Typography>
                                        </Grid>
                                    </>
                                    :
                                    <Grid sx={{ borderRight: 0 }} item md={4} xs={4}>
                                        <form onSubmit={handleSubmitId}>
                                            <Grid container sx={{ marginLeft: 0, marginRight: 0, marginTop: 0 }} direction="row" justifyContent="flex-start" alignItems="top" spacing={1} columns={14}>
                                                <Grid item md={10} xs={14}>
                                                    <TextField value={idTemp} onInput={e => setIdTemp(e.target.value)} size="small" fullWidth id="id" label="id" variant="outlined" />
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={3} xs={14}>
                                                    <Button type="submit" size="large" fullWidth variant="contained">set</Button>
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={1} xs={14}>
                                                    <Button size="large" fullWidth variant="outlined" onClick={() => { setIdChanging(false); }}>X</Button>
                                                </Grid>
                                                {idNotUnique ?
                                                    <Alert severity="warning">This id is already taken, choose another one!</Alert>
                                                    :
                                                    <></>
                                                }
                                            </Grid>
                                        </form>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>

                        <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                            <Grid container sx={{ paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="top" spacing={0} columns={5}>

                                <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>presentation</Typography></Grid>
                                {!presentationChanging ?
                                    <>
                                        <Grid sx={{ borderRight: 0 }} item md={3} xs={4}>
                                            <Typography variant="subtitle1" align="left">{artwork_presentation}</Typography>
                                        </Grid>
                                        <Grid sx={{ borderRight: 0 }} item md={1} xs={1}>
                                            <Typography align="right">
                                                <IconButton disabled={!sameNetwork || txLoading} aria-label="edit" onClick={() => { setPresentationTemp(artwork_presentation); setPresentationChanging(true); }}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Typography>
                                        </Grid>
                                    </>
                                    :
                                    <Grid sx={{ borderRight: 0 }} item md={4} xs={4}>
                                        <form onSubmit={handleSubmitPresentation}>
                                            <Grid container sx={{ marginLeft: 0, marginRight: 0, marginTop: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={1} columns={14}>
                                                <Grid item md={10} xs={14}>
                                                    <TextField multiline value={presentationTemp} onInput={e => setPresentationTemp(e.target.value)} size="small" fullWidth id="presentation" label="presentation" variant="outlined" />
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={3} xs={14}>
                                                    <Button type="submit" size="large" fullWidth variant="contained">set</Button>
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={1} xs={14}>
                                                    <Button size="large" fullWidth variant="outlined" onClick={() => { setPresentationChanging(false); }}>X</Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>

                    </Grid>
                </Box>





                <Box sx={{
                    borderRadius: '5px',
                    boxShadow: 0,
                    paddingTop: 1,
                    paddingBottom: 1,
                    paddingRight: 1,
                    paddingLeft: 1,
                    marginLeft: 3,
                    marginRight: 3,
                    marginTop: 3,
                    backgroundColor: '#F5F5F5'
                }}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <FileCopyIcon style={{ color: "#000" }} />
                        <Typography style={{ color: "#000" }} variant="button">metadata</Typography>
                    </Stack>

                    {txLoading ?
                        <Stack direction="row" alignItems="center" gap={1}>
                            <CircularProgress color="inherit" size={20} />
                            <Typography style={{ color: "#888" }} sx={{ fontStyle: 'italic' }} variant="button">applying changes, don't refresh your web browser</Typography>
                        </Stack>
                        :
                        <></>
                    }


                    <Grid container sx={{ margin: 3 }} direction="row" justifyContent="space-between" alignItems="center" spacing={0} columns={1}>

                        <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                            <Grid container sx={{ paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                <Grid sx={{ borderRight: 0 }} item md={1} xs={5}>
                                    <Typography align="left" variant="subtitle1" style={{ fontStyle: "italic" }}>base uri</Typography>
                                </Grid>
                                {!baseUriChanging ?
                                    <>
                                        <Grid sx={{ borderRight: 0 }} item md={3} xs={4}>
                                            <Typography variant="subtitle1" align="left"><span className="metadata">{baseUri}</span></Typography>
                                        </Grid>
                                        <Grid sx={{ borderRight: 0 }} item md={1} xs={1}>
                                            <Typography align="right">
                                                <IconButton disabled={!sameNetwork || txLoading} aria-label="edit" onClick={() => { setBaseUriTemp(baseUri); setBaseUriChanging(true); }}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Typography>
                                        </Grid>
                                    </>
                                    :
                                    <Grid sx={{ borderRight: 0 }} item md={4} xs={4}>
                                        <form onSubmit={handleSubmitBaseUri}>
                                            <Grid container sx={{ marginLeft: 0, marginRight: 0, marginTop: 0 }} direction="row" justifyContent="flex-start" alignItems="top" spacing={1} columns={14}>
                                                <Grid item md={10} xs={14}>
                                                    <TextField disabled={!sameNetwork || txLoading} value={baseUriTemp} onInput={e => setBaseUriTemp(e.target.value)} size="small" fullWidth id="base" label="Based URL" helperText="format: ipfs://##########/" variant="outlined" />
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={3} xs={14}>
                                                    <Button disabled={!sameNetwork || txLoading} type="submit" size="large" fullWidth variant="contained">set 🦊</Button>
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={1} xs={14}>
                                                    <Button disabled={!sameNetwork || txLoading} size="large" fullWidth variant="outlined" onClick={() => { setBaseUriChanging(false); }}>X</Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                        
                                    </Grid>
                                }
                            </Grid>

                        </Grid>
                    </Grid>
                </Box>



                {abiId === 1 ?
                <Box sx={{
                    borderRadius: '5px',
                    boxShadow: 0,
                    paddingTop: 1,
                    paddingBottom: 1,
                    paddingRight: 1,
                    paddingLeft: 1,
                    marginLeft: 3,
                    marginRight: 3,
                    marginTop: 3,
                    backgroundColor: '#F5F5F5'
                }}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <CreditCardIcon style={{ color: "#000" }} />
                        <Typography style={{ color: "#000" }} variant="button">FIAT payments</Typography>
                    </Stack>

                    {txLoading ?
                        <Stack direction="row" alignItems="center" gap={1}>
                            <CircularProgress color="inherit" size={20} />
                            <Typography style={{ color: "#888" }} sx={{ fontStyle: 'italic' }} variant="button">applying changes, don't refresh your web browser</Typography>
                        </Stack>
                        :
                        <></>
                    }


                    <Grid container sx={{ margin: 3 }} direction="row" justifyContent="space-between" alignItems="center" spacing={0} columns={1}>

                        <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                            <Grid container sx={{ paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                <Grid sx={{ borderRight: 0 }} item md={1} xs={5}>
                                    <Typography align="left" variant="subtitle1" style={{ fontStyle: "italic" }}>crossmint id</Typography>
                                </Grid>
                                {!crossmintIdChanging ?
                                    <>
                                        <Grid sx={{ borderRight: 0 }} item md={3} xs={4}>
                                            <Typography variant="subtitle1" align="left">{crossmintId}</Typography>
                                        </Grid>
                                        <Grid sx={{ borderRight: 0 }} item md={1} xs={1}>
                                            <Typography align="right">
                                                <IconButton disabled={!sameNetwork || txLoading} aria-label="edit" onClick={() => { setCrossmintIdTemp(crossmintId); setCrossmintIdChanging(true); }}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Typography>
                                        </Grid>
                                    </>
                                    :
                                    <Grid sx={{ borderRight: 0 }} item md={4} xs={4}>
                                        <form onSubmit={handleSubmitCrossmintId}>
                                            <Grid container sx={{ marginLeft: 0, marginRight: 0, marginTop: 0 }} direction="row" justifyContent="flex-start" alignItems="top" spacing={1} columns={14}>
                                                <Grid item md={10} xs={14}>
                                                    <TextField value={crossmintIdTemp} onInput={e => setCrossmintIdTemp(e.target.value)} size="small" fullWidth id="website" label="Crossmint id" variant="outlined" />
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={3} xs={14}>
                                                    <Button type="submit" size="large" fullWidth variant="contained">set</Button>
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={1} xs={14}>
                                                    <Button size="large" fullWidth variant="outlined" onClick={() => { setCrossmintIdChanging(false); }}>X</Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Grid>
                                }
                            </Grid>

                        </Grid>
                    </Grid>
                </Box>
                :
                <></>
                }






                <Box sx={{
                    borderRadius: '5px',
                    boxShadow: 0,
                    paddingTop: 1,
                    paddingBottom: 1,
                    paddingRight: 1,
                    paddingLeft: 1,
                    marginLeft: 3,
                    marginRight: 3,
                    marginTop: 3,
                    backgroundColor: '#F5F5F5'
                }}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <LinkIcon style={{ color: "#000" }} />
                        <Typography style={{ color: "#000" }} variant="button">links</Typography>
                    </Stack>

                    {txLoading ?
                        <Stack direction="row" alignItems="center" gap={1}>
                            <CircularProgress color="inherit" size={20} />
                            <Typography style={{ color: "#888" }} sx={{ fontStyle: 'italic' }} variant="button">applying changes, don't refresh your web browser</Typography>
                        </Stack>
                        :
                        <></>
                    }

                    <Grid container sx={{ margin: 3 }} direction="row" justifyContent="space-between" alignItems="center" spacing={0} columns={1}>

                        <Grid sx={{ paddingBottom: 2, borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                            <Grid container sx={{ margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>website</Typography></Grid>
                                {!websiteUrlChanging ?
                                    <>
                                        <Grid sx={{ borderRight: 0 }} item md={3} xs={4}>
                                            <Typography variant="subtitle1" align="left">{websiteUrl}</Typography>
                                        </Grid>
                                        <Grid sx={{ borderRight: 0 }} item md={1} xs={1}>
                                            <Typography align="right">
                                                <IconButton disabled={!sameNetwork || txLoading} aria-label="edit" onClick={() => { setWebsiteUrlTemp(websiteUrl); setWebsiteUrlChanging(true); }}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Typography>
                                        </Grid>
                                    </>
                                    :
                                    <Grid sx={{ borderRight: 0 }} item md={4} xs={4}>
                                        <form onSubmit={handleSubmitWebsiteUrl}>
                                            <Grid container sx={{ marginLeft: 0, marginRight: 0, marginTop: 0 }} direction="row" justifyContent="flex-start" alignItems="top" spacing={1} columns={14}>
                                                <Grid item md={10} xs={14}>
                                                    <TextField value={websiteUrlTemp} onInput={e => setWebsiteUrlTemp(e.target.value)} size="small" fullWidth id="website" label="Website URL" variant="outlined" />
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={3} xs={14}>
                                                    <Button type="submit" size="large" fullWidth variant="contained">set</Button>
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={1} xs={14}>
                                                    <Button size="large" fullWidth variant="outlined" onClick={() => { setWebsiteUrlChanging(false); }}>X</Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>



                        <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                            <Grid container sx={{ paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>twitter</Typography></Grid>
                                {!twitterUrlChanging ?
                                    <>
                                        <Grid sx={{ borderRight: 0 }} item md={3} xs={4}>
                                            <Typography variant="subtitle1" align="left">{twitterUrl}</Typography>
                                        </Grid>
                                        <Grid sx={{ borderRight: 0 }} item md={1} xs={1}>
                                            <Typography align="right">
                                                <IconButton disabled={!sameNetwork || txLoading} aria-label="edit" onClick={() => { setTwitterUrlTemp(twitterUrl); setTwitterUrlChanging(true); }}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Typography>
                                        </Grid>
                                    </>
                                    :
                                    <Grid sx={{ borderRight: 0 }} item md={4} xs={4}>
                                        <form onSubmit={handleSubmitTwitterUrl}>
                                            <Grid container sx={{ marginLeft: 0, marginRight: 0, marginTop: 0 }} direction="row" justifyContent="flex-start" alignItems="top" spacing={1} columns={14}>
                                                <Grid item md={10} xs={14}>
                                                    <TextField value={twitterUrlTemp} onInput={e => setTwitterUrlTemp(e.target.value)} size="small" fullWidth id="twitter" label="Twitter URL" variant="outlined" />
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={3} xs={14}>
                                                    <Button type="submit" size="large" fullWidth variant="contained">set</Button>
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={1} xs={14}>
                                                    <Button size="large" fullWidth variant="outlined" onClick={() => { setTwitterUrlChanging(false); }}>X</Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>

                        <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                            <Grid container sx={{ paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>instagram</Typography></Grid>
                                {!instagramUrlChanging ?
                                    <>
                                        <Grid sx={{ borderRight: 0 }} item md={3} xs={4}>
                                            <Typography variant="subtitle1" align="left">{instagramUrl}</Typography>
                                        </Grid>
                                        <Grid sx={{ borderRight: 0 }} item md={1} xs={1}>
                                            <Typography align="right">
                                                <IconButton disabled={!sameNetwork || txLoading} aria-label="edit" onClick={() => { setInstagramUrlTemp(instagramUrl); setInstagramUrlChanging(true); }}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Typography>
                                        </Grid>
                                    </>
                                    :
                                    <Grid sx={{ borderRight: 0 }} item md={4} xs={4}>
                                        <form onSubmit={handleSubmitInstagramUrl}>
                                            <Grid container sx={{ marginLeft: 0, marginRight: 0, marginTop: 0 }} direction="row" justifyContent="flex-start" alignItems="top" spacing={1} columns={14}>
                                                <Grid item md={10} xs={14}>
                                                    <TextField value={instagramUrlTemp} onInput={e => setInstagramUrlTemp(e.target.value)} size="small" fullWidth id="instagram" label="Instagram URL" variant="outlined" />
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={3} xs={14}>
                                                    <Button type="submit" size="large" fullWidth variant="contained">set</Button>
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={1} xs={14}>
                                                    <Button size="large" fullWidth variant="outlined" onClick={() => { setInstagramUrlChanging(false); }}>X</Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>

                        <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                            <Grid container sx={{ paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>opensea</Typography></Grid>
                                {!openseaUrlChanging ?
                                    <>
                                        <Grid sx={{ borderRight: 0 }} item md={3} xs={4}>
                                            <Typography variant="subtitle1" align="left">{openseaUrl}</Typography>
                                        </Grid>
                                        <Grid sx={{ borderRight: 0 }} item md={1} xs={1}>
                                            <Typography align="right">
                                                <IconButton disabled={!sameNetwork || txLoading} aria-label="edit" onClick={() => { setOpenseaUrlTemp(openseaUrl); setOpenseaUrlChanging(true); }}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Typography>
                                        </Grid>
                                    </>
                                    :
                                    <Grid sx={{ borderRight: 0 }} item md={4} xs={4}>
                                        <form onSubmit={handleSubmitOpenseaUrl}>
                                            <Grid container sx={{ marginLeft: 0, marginRight: 0, marginTop: 0 }} direction="row" justifyContent="flex-start" alignItems="top" spacing={1} columns={14}>
                                                <Grid item md={10} xs={14}>
                                                    <TextField value={openseaUrlTemp} onInput={e => setOpenseaUrlTemp(e.target.value)} size="small" fullWidth id="opensea" label="Opensea URL" variant="outlined" />
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={3} xs={14}>
                                                    <Button type="submit" size="large" fullWidth variant="contained">set</Button>
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={1} xs={14}>
                                                    <Button size="large" fullWidth variant="outlined" onClick={() => { setOpenseaUrlChanging(false); }}>X</Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>

                        <Grid sx={{ borderBottom: 0, borderTop: 0, borderColor: "#aaa" }} item md={1} xs={1}>

                            <Grid container sx={{ paddingBottom: 2, margin: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={0} columns={5}>

                                <Grid sx={{ borderRight: 0 }} item md={1} xs={5}><Typography variant="subtitle1" align="left" style={{ fontStyle: "italic" }}>other marketplace</Typography></Grid>
                                {!secondaryUrlChanging ?
                                    <>
                                        <Grid sx={{ borderRight: 0 }} item md={3} xs={4}>
                                            <Typography variant="subtitle1" align="left">{secondaryUrl}</Typography>
                                        </Grid>
                                        <Grid sx={{ borderRight: 0 }} item md={1} xs={1}>
                                            <Typography align="right">
                                                <IconButton disabled={!sameNetwork || txLoading} aria-label="edit" onClick={() => { setSecondaryUrlTemp(secondaryUrl); setSecondaryUrlChanging(true); }}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Typography>
                                        </Grid>
                                    </>
                                    :
                                    <Grid sx={{ borderRight: 0 }} item md={4} xs={4}>
                                        <form onSubmit={handleSubmitSecondaryUrl}>
                                            <Grid container sx={{ marginLeft: 0, marginRight: 0, marginTop: 0 }} direction="row" justifyContent="flex-start" alignItems="top" spacing={1} columns={14}>
                                                <Grid item md={10} xs={14}>
                                                    <TextField value={secondaryUrlTemp} onInput={e => setSecondaryUrlTemp(e.target.value)} size="small" fullWidth id="other marketplace" label="other marketplace url" variant="outlined" />
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={3} xs={14}>
                                                    <Button type="submit" size="large" fullWidth variant="contained">set</Button>
                                                </Grid>
                                                <Grid sx={{ paddingTop: 0 }} item md={1} xs={14}>
                                                    <Button size="large" fullWidth variant="outlined" onClick={() => { setSecondaryUrlChanging(false); }}>X</Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>

                    </Grid>


                </Box>


            </Box>
        </div >
    );
};

export default AdminSet;