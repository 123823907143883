import React, { useState, useEffect } from 'react';
import axios from 'axios';
//import { useParams } from 'react-router-dom';

//import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
//import { Backdrop } from '@mui/material';

import StarIcon from '@mui/icons-material/Star';
import VerifiedIcon from '@mui/icons-material/Verified';
import LoopIcon from '@mui/icons-material/Loop';
import LockIcon from '@mui/icons-material/Lock';
import WalletIcon from '@mui/icons-material/Wallet';
import DoneIcon from '@mui/icons-material/Done';

import { ethers } from "ethers";

import { UserContext } from '../App';

//import CustomCountdown from './CustomCountdown';

const PremintBox = (props) => {

    //const { isMMInstalled, isConnected, account, accountNetwork } = React.useContext(UserContext);
    const { account, isMMInstalled, isConnected, accountNetwork } = React.useContext(UserContext);

    const { abiId, settotalSupply, projectId, api_url, setNumAvailableToMint, txLoading, setTxLoading, presaleIsOver, chain_img, chain_name, contract_address, abi, presaleIsActive, numAvailableToMint, onThePreList, pricePerToken, network } = props;

    const [totalPre, setTotalPre] = useState(numAvailableToMint * pricePerToken);
    const [quantityPre, setQuantityPre] = useState(numAvailableToMint);

    useEffect(() => {
        setTotalPre(Math.round(quantityPre * pricePerToken * 10000) / 10000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quantityPre]);

    function addQuantityPre() {
        if (quantityPre < numAvailableToMint) {
            setQuantityPre(quantityPre + 1)
        }
    }

    function addQuantityPreMax() {
        setQuantityPre(numAvailableToMint)
    }

    function removeQuantityPre() {
        if (quantityPre > 1) {
            setQuantityPre(quantityPre - 1)
        }
    }

    async function premint() {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();

        const SmartContract = new ethers.Contract(contract_address, abi, signer);

        //console.log("quantityPre :", quantityPre)
        //console.log("totalPre :", totalPre)
        if (quantityPre > 0) {
            if (abiId === 1) {
                const tx = await SmartContract.preSaleMint(String(account), quantityPre, { value: ethers.utils.parseEther(totalPre.toString()) });
                setTxLoading(true)
            //console.log(`Transaction hash: ${tx.hash}`);

            const interval = setInterval(() => {
                txnCheck(tx.hash).then((response) => {
                    try {
                        console.log("res : ", response.hash)
                        axios.get(api_url + "/getContractValues/" + projectId + "/" + String(account)).then((response2) => {
                            setNumAvailableToMint(response2.data.data.numAvailableToMint)
                            settotalSupply(response2.data.data.totalSupply)
                            setTxLoading(false)
                            setQuantityPre(0)
                            setTotalPre(0)
                            clearInterval(interval)
                        });
                    } catch(error) {
                        //console.error(error)
                    }
                });
                console.log("presale on going")
            }, 1000);
            } else {
                const tx = await SmartContract.preSaleMint(quantityPre, { value: ethers.utils.parseEther(totalPre.toString()) });
                setTxLoading(true)
            //console.log(`Transaction hash: ${tx.hash}`);

            const interval = setInterval(() => {
                txnCheck(tx.hash).then((response) => {
                    try {
                        console.log("res : ", response.hash)
                        axios.get(api_url + "/getContractValues/" + projectId + "/" + String(account)).then((response2) => {
                            setNumAvailableToMint(response2.data.data.numAvailableToMint)
                            settotalSupply(response2.data.data.totalSupply)
                            setTxLoading(false)
                            setQuantityPre(0)
                            setTotalPre(0)
                            clearInterval(interval)
                        });
                    } catch(error) {
                        //console.error(error)
                    }
                });
                console.log("presale on going")
            }, 1000);
            }
            

        } else {
            setTxLoading(false)
        }
        
    }

    const txnCheck = async (txnHash) => {

        const provider = new ethers.providers.Web3Provider(window.ethereum)
        await provider.send("eth_requestAccounts", []);
        let txn_test = await provider.getTransaction(txnHash);
        if (txn_test) {
            if (txn_test.blockNumber) {
                //console.log("txn_test :", txn_test);
                return txn_test
            }
        }
    
    }

    return (
        <div>
            {presaleIsOver ?
                <Box
                    className="phase-box"
                    sx={{
                        boxShadow: 2
                    }}>
                    <Stack justifyContent="space-between" direction="row" alignItems="center" gap={1}>
                        <Stack justifyContent="left" direction="row" alignItems="center" gap={1}>
                            <StarIcon style={{ color: "#757575", fontSize: 20 }} />
                            <Typography style={{ color: "#757575" }} variant="subtitle2">PRESALE IS OVER</Typography>
                        </Stack>
                        <DoneIcon style={{ color: "#757575", fontSize: 20 }} />
                    </Stack>

                </Box>
                :
                <Box
                    className="phase-box"
                    sx={{
                        boxShadow: 2
                    }}>

                    <Stack justifyContent="left" direction="row" alignItems="center" gap={1}>
                        {presaleIsActive ?
                            <>
                                <StarIcon style={{ color: "#F9A825", fontSize: "30" }} />
                                <Typography style={{ color: "#F9A825" }} variant="h5">PRESALE IS ON</Typography>
                            </>
                            :
                            <>
                                <StarIcon style={{ color: "#000" }} />
                                <Typography style={{ color: "#000" }} variant="h6">PRESALE IS COMING</Typography>
                            </>
                        }
                    </Stack>

                    {txLoading ?
                        <Stack direction="row" alignItems="center" gap={1}>
                            <CircularProgress color="inherit" size={20} />
                            <Typography style={{ color: "#888" }} sx={{ fontStyle: 'italic' }} variant="button">minting, don't refresh your web browser</Typography>
                        </Stack>
                        :
                        <></>
                    }

                    <Box className='web3-box' sx={{
                        width: '100%',
                        borderRadius: '5px',
                        boxShadow: 0,
                        paddingTop: 1,
                        paddingBottom: 1,
                        paddingLeft: 0,
                        paddingRight: 0,
                        marginBottom: 3,
                        marginTop: 2
                    }}>
                        <Stack justifyContent="left" direction="row" alignItems="center" gap={1}>
                            {isMMInstalled ?
                                <>
                                    {isConnected ?
                                        <>
                                            {network === accountNetwork ?
                                                <>
                                                    {onThePreList ?
                                                        <>
                                                            <VerifiedIcon style={{ color: "#fff" }} />
                                                            <Typography style={{ color: "#fff" }} variant="button">you are on the whitelist</Typography>
                                                        </>
                                                        :
                                                        <>
                                                            <LockIcon style={{ color: "#fff" }} />
                                                            <Typography style={{ color: "#fff" }} variant="button">you are not on the whitelist</Typography>
                                                        </>
                                                    }
                                                </>
                                                :
                                                <>
                                                    <LoopIcon style={{ color: "#fff" }} />
                                                    <Typography style={{ color: "#fff" }} variant="button">switch network to {chain_name[network]}</Typography>
                                                </>
                                            }
                                        </>
                                        :
                                        <>
                                            <WalletIcon style={{ color: "#fff" }} />
                                            <Typography style={{ color: "#fff" }} variant="button">wallet is not connected</Typography>
                                        </>
                                    }
                                </>
                                :
                                <>
                                    <Typography style={{ color: "#fff" }} variant="button">🦊 install metamask</Typography>
                                </>
                            }
                        </Stack>
                    </Box>

                    {onThePreList & (network === accountNetwork) ?
                        <>
                            <Box
                                sx={{
                                    width: '100%',
                                    marginBottom: 1
                                }}>
                                <Grid container sx={{ alignItems: "center" }} direction="row" justifyContent="space-between" alignItems="flex-start" spacing={1} columns={6}>
                                    {presaleIsActive ?
                                        <>
                                            <Grid style={{ color: "#000" }} sx={{ borderColor: 'grey.900' }} item md={1} xs={6}>
                                                 
                                                <Typography align="center">
                                                    <span className='stats-variables-pre'>available for you</span>
                                                    <br />
                                                    {txLoading ?
                                                        <span className='stats-values-pre' ><LockIcon /></span>
                                                        :
                                                        <span className='stats-values-pre' >{numAvailableToMint}</span>
                                                    }
                                                </Typography>
                                                
                                            </Grid>
                                            <Grid sx={{ borderTop: {xs: 1, md: 0}, borderLeft: {xs: 0, md: 1}, borderColor: {xs: 'grey.400', md: 'grey.400'} }} item md={1} xs={6}>
                                                <Typography align="center">
                                                    <span className='stats-variables-pre'>quantity</span>
                                                    <br />
                                                    {txLoading ?
                                                        <span className='stats-values-pre' ><LockIcon /></span>
                                                        :
                                                        <span className='stats-values-pre' >{quantityPre}</span>
                                                    }
                                                </Typography>
                                            </Grid>
                                            <Grid item md={1} xs={6}>
                                                <Typography align="center">
                                                    <span className='stats-variables-pre'>total</span>
                                                    <br />
                                                    {txLoading ?
                                                        <span className='stats-values-pre' ><LockIcon /></span>
                                                        :
                                                        <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
                                                            <img alt="logo" width={20} height={20} src={chain_img[network]} />
                                                            <span className='stats-values-pre' >{totalPre}</span>
                                                        </Stack>
                                                    }        
                                                </Typography>
                                            </Grid>
                                            <Grid item md={3} xs={6}>
                                                <Typography align="left">
                                                    <Grid container sx={{ marginTop: 0 }} justifyContent="flex-start" alignItems="center" spacing={1} columns={13}>
                                                        <Grid item md={2} xs={13}>
                                                            <Button disabled={txLoading} fullWidth size="medium" variant="outlined" onClick={removeQuantityPre}>-</Button>
                                                        </Grid>
                                                        <Grid item md={2} xs={13}>
                                                            <Button disabled={txLoading} fullWidth size="medium" variant="outlined" onClick={addQuantityPre}>+</Button>
                                                        </Grid>
                                                        <Grid item md={2} xs={13}>
                                                            <Button disabled={txLoading} fullWidth size="medium" variant="outlined" onClick={addQuantityPreMax}>+ +</Button>
                                                        </Grid>
                                                        <Grid item md={7} xs={13}>
                                                            <Button disabled={txLoading} fullWidth size="medium" variant="contained" onClick={premint}>mint {quantityPre}</Button>
                                                        </Grid>
                                                    </Grid>
                                                </Typography>
                                            </Grid>
                                        </>
                                        :
                                        <>
                                            <Grid item md={1} xs={6}>
                                                <Typography align="center" style={{ color: "#888" }}>
                                                    <span className='stats-variables-pre'>available for you</span>
                                                    <br />
                                                    <span className='stats-values-pre' >{numAvailableToMint}</span>
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ borderTop: {xs: 1, md: 0}, borderLeft: {xs: 0, md: 1}, borderColor: {xs: 'grey.400', md: 'grey.400'} }} item md={1} xs={6}>
                                                <Typography align="center" style={{ color: "#888" }}>
                                                    <span className='stats-variables-pre'>quantity</span>
                                                    <br />
                                                    <span className='stats-values-pre' ><LockIcon /></span>
                                                </Typography>
                                            </Grid>
                                            <Grid item md={1} xs={6}>
                                                <Typography align="center" style={{ color: "#888" }}>
                                                    <span className='stats-variables-pre'>total</span>
                                                    <br />
                                                    <span className='stats-values-pre' ><LockIcon /></span>
                                                </Typography>
                                            </Grid>
                                            <Grid item md={3} xs={6}>
                                                <Button disabled style={{ width: '100%', color: 'black', borderColor: 'black' }} size="medium" variant="contained">
                                                    <Stack style={{ color: "#888" }} direction="row" alignItems="center" gap={1}>
                                                        <LockIcon />
                                                        <Typography variant="button">mint</Typography>
                                                    </Stack>
                                                </Button>
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                            </Box>
                        </>
                        :
                        <>
                        </>
                    }
                </Box>
            }
        </div >
    );
};

export default PremintBox;