import React, { useState, useEffect } from 'react';
import axios from 'axios';
//import { useParams } from 'react-router-dom';

//import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

import CircleIcon from '@mui/icons-material/Circle';
import LockIcon from '@mui/icons-material/Lock';
import LoopIcon from '@mui/icons-material/Loop';
import WalletIcon from '@mui/icons-material/Wallet';
import DoneIcon from '@mui/icons-material/Done';

import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";

import { ethers } from "ethers";

import { UserContext } from '../App';

//import CustomCountdown from './CustomCountdown';

const MintBox = (props) => {

    //const { isMMInstalled, isConnected, account, accountNetwork } = React.useContext(UserContext);
    const { account, isMMInstalled, isConnected, accountNetwork } = React.useContext(UserContext);

    const { crossmintId, abiId, api_url, projectId, maxPublicMint, alreadyPublicMinted, setAlreadyPublicMinted, settotalSupply, setTxLoading, txLoading, presaleIsOver, saleIsOver, chain_img, chain_name, contract_address, abi, saleIsActive, pricePerToken, network } = props;

    const [total, setTotal] = useState(0.00);
    const [quantity, setQuantity] = useState(0);
    const [environment, setEnvironment] = useState("production")

    useEffect(() => {
        setTotal(Math.round(quantity * pricePerToken * 10000) / 10000);
        if (network === 1) {
            setEnvironment("production")
        } else {
            setEnvironment("staging")
        }
        //console.log("maxPublicMint :", maxPublicMint)
        //console.log("alreadyPublicMinted :", alreadyPublicMinted)
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quantity]);


    function addQuantity() {
        if (quantity < (maxPublicMint - alreadyPublicMinted)) {
            setQuantity(quantity + 1)
        }
    }

    function addQuantityMax() {
        setQuantity(maxPublicMint - alreadyPublicMinted)
    }

    function removeQuantity() {
        if (quantity > 1) {
            setQuantity(quantity - 1)
        }
    }

    async function mint() {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();

        const SmartContract = new ethers.Contract(contract_address, abi, signer);
        //const name = await SmartContract.name();
        //const symbol = await SmartContract.symbol();
        //console.log("name: ", name)
        //console.log("symbol: ", symbol)
        //console.log("quantity :", quantity)
        //console.log("total :", total)

        if (quantity > 0) {
            if (abiId === 1) {
                const tx = await SmartContract.mint(String(account), quantity, { value: ethers.utils.parseEther(total.toString()) });
                setTxLoading(true)
            //console.log(`Transaction hash: ${tx.hash}`);

            const interval = setInterval(() => {
                txnCheck(tx.hash).then((response) => {
                    try {
                        console.log("res : ", response.hash)
                        axios.get(api_url + "/getContractValues/" + projectId + "/" + String(account)).then((response2) => {
                            setAlreadyPublicMinted(response2.data.data.alreadyPublicMinted);
                            settotalSupply(response2.data.data.totalSupply)
                            setTxLoading(false)
                            setQuantity(0)
                            setTotal(0)
                            clearInterval(interval)
                        });
                    } catch(error) {
                        //console.error(error)
                    }
                });
                console.log("sale on going")
            }, 1000);
            } else {
                const tx = await SmartContract.mint(quantity, { value: ethers.utils.parseEther(total.toString()) });
                setTxLoading(true)
            //console.log(`Transaction hash: ${tx.hash}`);

            const interval = setInterval(() => {
                txnCheck(tx.hash).then((response) => {
                    try {
                        console.log("res : ", response.hash)
                        axios.get(api_url + "/getContractValues/" + projectId + "/" + String(account)).then((response2) => {
                            setAlreadyPublicMinted(response2.data.data.alreadyPublicMinted);
                            settotalSupply(response2.data.data.totalSupply)
                            setTxLoading(false)
                            setQuantity(0)
                            setTotal(0)
                            clearInterval(interval)
                        });
                    } catch(error) {
                        //console.error(error)
                    }
                });
                console.log("sale on going")
            }, 1000);
            }
            

        } else {
            setTxLoading(false)
        }
    }

    const txnCheck = async (txnHash) => {

        const provider = new ethers.providers.Web3Provider(window.ethereum)
        await provider.send("eth_requestAccounts", []);
        let txn_test = await provider.getTransaction(txnHash);
        if (txn_test) {
            if (txn_test.blockNumber) {
                //console.log("txn_test :", txn_test);
                return txn_test
            }
        }
    
    }


    return (
        <div>
            {presaleIsOver & !saleIsOver ?
                <Box
                    className="phase-box"
                    sx={{
                        boxShadow: 2
                    }}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        {saleIsActive ?
                            <>
                                <CircleIcon style={{ color: "#F9A825" }} />
                                <Typography style={{ color: "#F9A825" }} variant="h5">SALE IS ON</Typography>
                            </>
                            :
                            <>
                                <CircleIcon style={{ color: "#000" }} />
                                <Typography style={{ color: "#000" }} variant="h6">SALE IS COMING</Typography>
                            </>
                        }
                    </Stack>

                    {txLoading ?
                        <Stack direction="row" alignItems="center" gap={1}>
                            <CircularProgress color="inherit" size={20} />
                            <Typography style={{ color: "#888" }} sx={{ fontStyle: 'italic' }} variant="button">minting, don't refresh your web browser</Typography>
                        </Stack>
                        :
                        <></>
                    }

                    {isMMInstalled ?
                        <>
                            {isConnected ?
                                <>
                                    {network === accountNetwork ?
                                        <>
                                        </>
                                        :
                                        <Box className='web3-box' sx={{
                                            width: '100%',
                                            borderRadius: '5px',
                                            boxShadow: 0,
                                            paddingTop: 1,
                                            paddingBottom: 1,
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                            marginBottom: 0,
                                            marginTop: 2
                                        }}>
                                            <Stack justifyContent="left" direction="row" alignItems="center" gap={1}>
                                                <LoopIcon style={{ color: "#fff" }} />
                                                <Typography style={{ color: "#fff" }} variant="button">switch network to {chain_name[network]}</Typography>
                                            </Stack>
                                        </Box>
                                    }
                                </>
                                :
                                <Box className='web3-box' sx={{
                                    width: '100%',
                                    borderRadius: '5px',
                                    boxShadow: 0,
                                    paddingTop: 1,
                                    paddingBottom: 1,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    marginBottom: 0,
                                    marginTop: 2
                                }}>
                                    <Stack justifyContent="left" direction="row" alignItems="center" gap={1}>
                                        <WalletIcon style={{ color: "#fff" }} />
                                        <Typography style={{ color: "#fff" }} variant="button">wallet is not connected</Typography>
                                    </Stack>
                                </Box>
                            }
                        </>
                        :
                        <Box className='web3-box' sx={{
                            width: '100%',
                            borderRadius: '5px',
                            boxShadow: 0,
                            paddingTop: 1,
                            paddingBottom: 1,
                            paddingLeft: 0,
                            paddingRight: 0,
                            marginBottom: 0,
                            marginTop: 2
                        }}>
                            <Typography style={{ color: "#fff" }} variant="button">🦊 install metamask</Typography>
                        </Box>
                    }

                    <Box
                        sx={{
                            width: '100%',
                            marginTop: 2,
                            marginBottom: 1
                        }}>
                        <Grid container sx={{ alignItems: "center" }} direction="row" justifyContent="space-between" alignItems="flex-start" spacing={1} columns={6}>
                            {saleIsActive ?
                                <>
                                    {isConnected && (accountNetwork === network) ?
                                        <>
                                            <Grid sx={{ borderBottom: {xs: 1, md: 0}, borderRight: {xs: 0, md: 1}, borderColor: {xs: 'grey.400', md: 'grey.400'} }} item md={1} xs={6}>
                                                <Typography align="center">
                                                    <span className='stats-variables-pre'>mint limit</span>
                                                    <br />
                                                    {txLoading ?
                                                        <span className='stats-values-pre' ><LockIcon /></span>
                                                        :
                                                        <span className='stats-values-pre' >{maxPublicMint - alreadyPublicMinted}</span>
                                                    }
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ borderRight: 0, borderColor: 'grey.900' }} item md={1} xs={6}>
                                                <Typography align="center">
                                                    <span className='stats-variables-pre'>quantity</span>
                                                    <br />
                                                    {txLoading ?
                                                        <span className='stats-values-pre' ><LockIcon /></span>
                                                        :
                                                        <span className='stats-values-pre' >{quantity}</span>
                                                    }
                                                </Typography>
                                            </Grid>
                                            <Grid item md={1} xs={6}>
                                                <Typography align="center">
                                                    <span className='stats-variables-pre'>total price</span>
                                                    <br />
                                                    {txLoading ?
                                                        <span className='stats-values-pre' ><LockIcon /></span>
                                                        :
                                                        <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
                                                            <img alt="logo" width={20} height={20} src={chain_img[network]} />
                                                            <span className='stats-values-pre' >{total}</span>
                                                        </Stack>
                                                    }
                                                </Typography>
                                            </Grid>
                                            <Grid item md={3} xs={6}>
                                                <Typography align="left">
                                                    <Grid container sx={{ marginTop: 0 }} justifyContent="flex-start" alignItems="center" spacing={1} columns={13}>
                                                        <Grid item md={2} xs={13}>
                                                            <Button disabled={txLoading} fullWidth size="medium" variant="outlined" onClick={removeQuantity}>-</Button>
                                                        </Grid>
                                                        <Grid item md={2} xs={13}>
                                                            <Button disabled={txLoading} fullWidth size="medium" variant="outlined" onClick={addQuantity}>+</Button>
                                                        </Grid>
                                                        <Grid item md={2} xs={13}>
                                                            <Button disabled={txLoading} fullWidth size="medium" variant="outlined" onClick={addQuantityMax}>+ +</Button>
                                                        </Grid>
                                                        <Grid item md={7} xs={13}>
                                                            <Button disabled={txLoading} fullWidth size="large" variant="contained" onClick={mint}>mint</Button>
                                                        </Grid>
                                                    </Grid>
                                                </Typography>
                                            </Grid>
                                            {abiId === 1 ?
                                                <>
                                                    <Grid sx={{ borderBottom: {xs: 1, md: 0}, borderRight: {xs: 0, md: 1}, borderColor: {xs: 'grey.400', md: 'grey.400'} }} item md={3} xs={6}></Grid>
                                                    <Grid item md={3} xs={6}>
                                                        <Grid container sx={{ marginTop: 0 }} justifyContent="flex-start" alignItems="center" spacing={1} columns={13}>
                                                            <Grid item md={6} xs={13}></Grid>
                                                            <Grid item md={7} xs={13}>
                                                                <CrossmintPayButton
                                                                    clientId={crossmintId}
                                                                    mintConfig={{"type":"erc-721","totalPrice": '' + total,"numberOfTokens": quantity}}
                                                                    environment={environment}
                                                                    className="xmint-btn"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                                :
                                                <></>
                                            }
                                        </>
                                        :
                                        <>
                                            {abiId === 1 ?
                                            <>
                                                <Grid sx={{ borderBottom: {xs: 1, md: 0}, borderRight: {xs: 0, md: 1}, borderColor: {xs: 'grey.400', md: 'grey.400'} }} item md={1} xs={6}>
                                                    <Typography align="center">
                                                        <span className='stats-variables-pre'>mint limit</span>
                                                        <br />
                                                        {txLoading ?
                                                            <span className='stats-values-pre' ><LockIcon /></span>
                                                            :
                                                            <span className='stats-values-pre' >{maxPublicMint - alreadyPublicMinted}</span>
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid sx={{ borderRight: 0, borderColor: 'grey.900' }} item md={1} xs={6}>
                                                    <Typography align="center">
                                                        <span className='stats-variables-pre'>quantity</span>
                                                        <br />
                                                        {txLoading ?
                                                            <span className='stats-values-pre' ><LockIcon /></span>
                                                            :
                                                            <span className='stats-values-pre' >{quantity}</span>
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={1} xs={6}>
                                                    <Typography align="center">
                                                        <span className='stats-variables-pre'>total price</span>
                                                        <br />
                                                        {txLoading ?
                                                            <span className='stats-values-pre' ><LockIcon /></span>
                                                            :
                                                            <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
                                                                <img alt="logo" width={20} height={20} src={chain_img[network]} />
                                                                <span className='stats-values-pre' >{total}</span>
                                                            </Stack>
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={3} xs={6}>
                                                    <Typography align="left">
                                                        <Grid container sx={{ marginTop: 0 }} justifyContent="flex-start" alignItems="center" spacing={1} columns={13}>
                                                            <Grid item md={2} xs={13}>
                                                                <Button disabled={txLoading} fullWidth size="medium" variant="outlined" onClick={removeQuantity}>-</Button>
                                                            </Grid>
                                                            <Grid item md={2} xs={13}>
                                                                <Button disabled={txLoading} fullWidth size="medium" variant="outlined" onClick={addQuantity}>+</Button>
                                                            </Grid>
                                                            <Grid item md={2} xs={13}>
                                                                <Button disabled={txLoading} fullWidth size="medium" variant="outlined" onClick={addQuantityMax}>+ +</Button>
                                                            </Grid>
                                                            <Grid item md={7} xs={13}>
                                                            <Button disabled fullWidth size="large" variant="contained" >mint</Button>
                                                                
                                                            </Grid>
                                                        </Grid>
                                                    </Typography>
                                                </Grid>
                                                <Grid sx={{ borderBottom: {xs: 1, md: 0}, borderRight: {xs: 0, md: 1}, borderColor: {xs: 'grey.400', md: 'grey.400'} }} item md={3} xs={6}></Grid>
                                                    <Grid item md={3} xs={6}>
                                                        <Grid container sx={{ marginTop: 0 }} justifyContent="flex-start" alignItems="center" spacing={1} columns={13}>
                                                            <Grid item md={6} xs={13}></Grid>
                                                            <Grid item md={7} xs={13}>
                                                                <CrossmintPayButton
                                                                    clientId={crossmintId}
                                                                    mintConfig={{"type":"erc-721","totalPrice": '' + total,"numberOfTokens": quantity}}
                                                                    environment={environment}
                                                                    className="xmint-btn"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                            </>
                                            :
                                            <>
                                                <Grid sx={{ borderBottom: {xs: 1, md: 0}, borderRight: {xs: 0, md: 1}, borderColor: {xs: 'grey.400', md: 'grey.400'} }} item md={1} xs={6}>
                                                    <Typography align="center" style={{ color: "#888" }}>
                                                        <span className='stats-variables-pre'>mint limit</span>
                                                        <br />
                                                        <span className='stats-values-pre' >{maxPublicMint - alreadyPublicMinted}</span>
                                                    </Typography>
                                                </Grid>
                                                <Grid sx={{ borderRight: 0, borderColor: 'grey.900' }} item md={1} xs={6}>
                                                    <Typography align="center" style={{ color: "#888" }}>
                                                        <span className='stats-variables-pre'>quantity</span>
                                                        <br />
                                                        <span className='stats-values-pre' ><LockIcon /></span>
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={1} xs={6}>
                                                    <Typography align="center" style={{ color: "#888" }}>
                                                        <span className='stats-variables-pre'>total price</span>
                                                        <br />
                                                        <span className='stats-values-pre' ><LockIcon /></span>
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={3} xs={6}>
                                                    <Typography align="left">
                                                        <Button disabled style={{ width: '100%', color: 'black', borderColor: 'black' }} size="medium" variant="contained">
                                                            <Stack style={{ color: "#888" }} direction="row" alignItems="center" gap={1}>
                                                                <LockIcon />
                                                                <Typography variant="button">mint</Typography>
                                                            </Stack>
                                                        </Button>
                                                    </Typography>
                                                </Grid>
                                            </>
                                            }
                                        </>
                                    } 
                                </>
                                :
                                <>
                                    <Grid sx={{ borderBottom: {xs: 1, md: 0}, borderRight: {xs: 0, md: 1}, borderColor: {xs: 'grey.400', md: 'grey.400'} }} item md={1} xs={6}>
                                        <Typography align="center" style={{ color: "#888" }}>
                                            <span className='stats-variables-pre'>mint limit</span>
                                            <br />
                                            <span className='stats-values-pre' >{maxPublicMint - alreadyPublicMinted}</span>
                                        </Typography>
                                    </Grid>
                                    <Grid sx={{ borderRight: 0, borderColor: 'grey.900' }} item md={1} xs={6}>
                                        <Typography align="center" style={{ color: "#888" }}>
                                            <span className='stats-variables-pre'>quantity</span>
                                            <br />
                                            <span className='stats-values-pre' ><LockIcon /></span>
                                        </Typography>
                                    </Grid>
                                    <Grid item md={1} xs={6}>
                                        <Typography align="center" style={{ color: "#888" }}>
                                            <span className='stats-variables-pre'>total price</span>
                                            <br />
                                            <span className='stats-values-pre' ><LockIcon /></span>
                                        </Typography>
                                    </Grid>
                                    <Grid item md={3} xs={6}>
                                        <Typography align="left">
                                            <Button disabled style={{ width: '100%', color: 'black', borderColor: 'black' }} size="medium" variant="contained">
                                                <Stack style={{ color: "#888" }} direction="row" alignItems="center" gap={1}>
                                                    <LockIcon />
                                                    <Typography variant="button">mint</Typography>
                                                </Stack>
                                            </Button>
                                        </Typography>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Box>
                </Box>
                :
                <Box
                    className="phase-box"
                    sx={{
                        boxShadow: 2
                    }}>

                    {saleIsOver ?
                        <Stack justifyContent="space-between" direction="row" alignItems="center" gap={1}>
                            <Stack justifyContent="left" direction="row" alignItems="center" gap={1}>
                                <CircleIcon style={{ color: "#757575", fontSize: 20 }} />
                                <Typography style={{ color: "#757575" }} variant="subtitle2">SALE IS OVER</Typography>
                            </Stack>
                            <DoneIcon style={{ color: "#757575", fontSize: 20 }} />
                        </Stack>
                        :
                        <Stack justifyContent="left" direction="row" alignItems="center" gap={1}>
                            <CircleIcon style={{ color: "#757575", fontSize: 20 }} />
                            <Typography style={{ color: "#757575" }} variant="subtitle2">SALE HAS NOT STARTED YET</Typography>
                        </Stack>
                    }

                </Box>
            }
        </div >
    );
};

export default MintBox;