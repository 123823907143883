import React from 'react';

import { Typography, Stack } from '@mui/material';

import TravelExploreIcon from '@mui/icons-material/TravelExplore';

import './styles/Home.css';

function NotFound() {

    return (
        <header className="App-header">
            <Stack sx={{display: { xs: 'none', md: 'flex' }}} direction="row" alignItems="center" gap={1}>
                <TravelExploreIcon sx={{ fontSize: 200 }}/>
                <Typography variant="h5" align="left">
                    Oops... This content is not found!
                </Typography>
            </Stack>
            <Stack sx={{display: { xs: 'flex', md: 'none' }}} direction="column" alignItems="center" gap={1}>
                <TravelExploreIcon sx={{ fontSize: 200 }}/>
                <Typography variant="h5" align="center">
                    Oops... This content is not found!
                </Typography>
            </Stack>
        </header>
    );
}

export default NotFound;