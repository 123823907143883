import React from 'react';

import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
//import Avatar from '@mui/material/Avatar';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Avatar from "boring-avatars";


import StarIcon from '@mui/icons-material/Star';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


//import CustomCountdown from './CustomCountdown';

const FeaturedBox = (props) => {

    const { pricePerToken, maxSupply, chain_name, network, chain_img, projectId, artwork_img, artwork_title, artist_name } = props;
    const featured_project_path = "/mint/" + projectId;

    return (
        <Box className="artwork-box"
            sx={{
                width: '90.5%',
                borderRadius: '5px',
                boxShadow: 0,
                paddingTop: 10,
                paddingBottom: 0,
                marginTop: 0,
                direction:"column",
                alignItems: "center",
                justifyContent: "center"
            }}
            style={{background: 'linear-gradient(to bottom, #fff, #fff, #fff)'}}>
                
                <Grid container spacing={5} sx={{ paddingTop: 9, paddingBottom: 1, paddingLeft: 0, justifyContent: "center", borderRadius: '8px', alignItems: "flex-end", display: { xs: 'none', md: 'flex' } }} >
                <Grid >
                    <Typography align="left">
                        <Chip
                            icon={<StarIcon />}
                            label="HIGHLIGHT"
                            color="primary"
                            size="medium"
                        />
                    </Typography>
                    <Card sx={{
                            width: '350px',
                            height: '350px',
                            borderRadius: '5px',
                            display: "flex",
                            alignItems: "center",
                            boxShadow: 7,
                            marginTop: 1
                        }}>
                        {artwork_img === "" || artwork_img === null ?
                            <Avatar
                                size={400}
                                name={projectId}
                                square
                            />
                            :
                            <CardMedia
                                component="img"
                                image={artwork_img}
                                alt="artwork img"
                                sx={{
                                    width: '350px',
                                    height: '350px'
                                }}
                            />
                        }
                    </Card>
                </Grid>
                <Grid
                    item
                    xs
                    column={1}>
                    <Card sx={{
                        borderRadius: '5px',
                        boxShadow: 0,
                        display: "flex",
                        alignItems: "flex-end",
                        backgroundColor: 'transparent',
                    }}>
                        <CardContent sx={{
                            minWidth: '35vw',
                            display: 'row',
                            alignItems: 'flex-end',
                            justifyContent: 'left',
                        }}>
                            <Typography align="left" sx={{marginBottom : 1}}>
                                    
                                <h1 className="artwork-title">{artwork_title}</h1>
                                <h2 className="artwork-title"><span className="by-artist">by</span> {artist_name}</h2>
                                
                                    <Box sx={{ border: 1, borderColor: 'grey.600', borderRadius: 2 }}>
                                        <Box
                                            sx={{
                                                width: '100%',
                                            }}>
                                            <Grid sx={{ paddingTop: 2, paddingBottom: 2 }} container direction="row" justifyContent="flex-start" alignItems="center" spacing={1} columns={3}>
                                                <Grid sx={{ borderRight: 1, borderColor: 'grey.600' }} item md={1} xs={3}>
                                                    <Typography align="center">
                                                        <span className='stats-variables'>unit price</span>
                                                        <br />
                                                        <span className='stats-values' >{pricePerToken}</span>
                                                    </Typography>
                                                </Grid>
                                                <Grid sx={{ borderRight: 1, borderColor: 'grey.600' }} item md={1} xs={3}>
                                                    <Typography align="center">
                                                        <span className='stats-variables'>supply</span>
                                                        <br />
                                                        <span className='stats-values' >{maxSupply}</span>
                                                    </Typography>
                                                </Grid>

                                                
                                                <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item md={1} xs={3}>
                                                    <Typography align="center">
                                                        <span className='stats-variables'>{chain_name[network]}</span>
                                                        <br />
                                                        <img alt="logo" width={30} height={30} src={chain_img[network]} />
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Typography>
                                <Link to={featured_project_path} className="small-menu-link">
                                    <Button size="medium" style={{color: "#fff", background: "#000"}} startIcon={<ArrowForwardIcon />} variant="contained" fullWidth>view drop </Button>
                                </Link>
                            </CardContent>
                        </Card>
                    </Grid>
            </Grid>


            

            

            <Box sx={{minWidth: '70vw', paddingBottom: 1, display: { xs: 'flex', md: 'none' }}}>
                <Typography align="left">
                    <Chip
                        icon={<StarIcon />}
                        label="HIGHLIGHT"
                        color="primary"
                        size="medium"
                    />
                </Typography>
            </Box>
            
            <Card sx={{
                    width: '70vw',
                    height: '70vw',
                    borderRadius: '5px',
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: 7,
                    display: { xs: 'flex', md: 'none' }
                }}>
                    {artwork_img === "" || artwork_img === null ?
                        <Avatar
                            size={800}
                            name={projectId}
                            square
                        />
                        :
                        <CardMedia
                            component="img"
                            image={artwork_img}
                            alt="artwork img"
                            sx={{
                                width: '70vw',
                                height: '70vw'
                            }}
                        />
                    }
                </Card>


                <Card sx={{
                    borderRadius: '5px',
                    boxShadow: 0,
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: 'transparent',
                    display: { xs: 'flex', md: 'none' }
                }}>
                    <CardContent sx={{
                        minWidth: '75vw',
                        display: 'row',
                        alignItems: 'flex-end',
                        justifyContent: 'center',
                    }}>
                        <Typography align="left" sx={{paddingBottom: 2}}>
                            <h1 className="artwork-title">
                                {artwork_title}
                                
                            </h1>
                            <h2 className="artwork-title"><span className="by-artist">by</span> {artist_name}</h2>
                            
                            <Box sx={{ border: 1, borderColor: 'grey.600', borderRadius: 2 }}>
                                <Box
                                    sx={{
                                        width: '100%',
                                    }}>
                                    <Grid sx={{ paddingTop: 2, paddingBottom: 2 }} container direction="row" justifyContent="flex-start" alignItems="center" spacing={1} columns={3}>
                                        <Grid sx={{ borderRight: 1, borderColor: 'grey.600' }} item xs={1}>
                                            <Typography align="center">
                                                <span className='stats-variables'>unit price</span>
                                                <br />
                                                <span className='stats-values-resp' >{pricePerToken}</span>
                                            </Typography>
                                        </Grid>
                                        <Grid sx={{ borderRight: 1, borderColor: 'grey.600' }} item xs={1}>
                                            <Typography align="center">
                                                <span className='stats-variables'>supply</span>
                                                <br />
                                                <span className='stats-values-resp' >{maxSupply}</span>
                                            </Typography>
                                        </Grid>
                                        <Grid sx={{ borderRight: 0, borderColor: 'grey.600' }} item xs={1}>
                                            <Typography align="center">
                                                <span className='stats-variables'>{chain_name[network]}</span>
                                                <br />
                                                <img alt="logo" width={20} height={20} src={chain_img[network]} />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Typography>
                        <Link to={featured_project_path} className="small-menu-link">
                            <Button size="medium" style={{color: "#fff", background: "#000"}} startIcon={<ArrowForwardIcon />} variant="contained" fullWidth>view drop </Button>
                        </Link>
                    </CardContent>
                </Card>
        </Box>
    );
};



export default FeaturedBox;