import React, { useState, useEffect } from 'react';
import axios from 'axios';
//import { useParams } from 'react-router-dom';

//import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

import RefreshIcon from '@mui/icons-material/Refresh';
import InfoIcon from '@mui/icons-material/Info';
import GetAppIcon from '@mui/icons-material/GetApp';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import SavingsIcon from '@mui/icons-material/Savings';
import CopyrightIcon from '@mui/icons-material/Copyright';
import LoopIcon from '@mui/icons-material/Loop';

import Avatar from "boring-avatars";

import { ethers } from "ethers";

import { UserContext } from '../App';

//import CustomCountdown from './CustomCountdown';

const AdminOwner = (props) => {

    //const { isMMInstalled, isConnected, account, accountNetwork } = React.useContext(UserContext);
    const { account } = React.useContext(UserContext);
    const { chain_name, sameNetwork, fee, api_url, tokenIds, setTokenIds, totalSupply, maxSupply, reservedAmount, setReservedAmount, setTotalSupply, balance, setBalance, chain_img, projectId, artwork_title, artwork_img, contract_address, abi, txLoading, setTxLoading, network, setMintData } = props;

    const [quantityRes, setQuantityRes] = useState(0);

    const [addressToGift, setAddressToGift] = useState("")
    const [tokenIdToGift, setTokenIdToGift] = useState("")

    

    useEffect(() => {
    }, []);

    


    async function handleSubmitGift(event) {
        try {
            event.preventDefault();
            console.log('addressToGift:', addressToGift);
            console.log('tokenIdToGift:', tokenIdToGift);

            const provider = new ethers.providers.Web3Provider(window.ethereum)
            await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner();

            const SmartContract = new ethers.Contract(contract_address, abi, signer);

            const tx = await SmartContract["safeTransferFrom(address,address,uint256)"](String(account), addressToGift, tokenIdToGift);
            console.log(`Transaction hash: ${tx.hash}`);
            setTxLoading(true)

            const interval = setInterval(() => {
                txnCheck(tx.hash).then((response) => {
                    try {
                        console.log("res : ", response.hash)
                        axios.get(api_url + "/getTokenIdsOwner/" + projectId, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response7) => {
                            setTokenIds(response7.data.data)
                            console.log("Gifted")
                            setAddressToGift("")
                            setTokenIdToGift("")
                            setTxLoading(false)
                            clearInterval(interval)
                        });

                    } catch (error) {
                    }
                });
                console.log("Airdrop set on going")
            }, 1000);



        } catch (error) {
            console.error(error)
        }
    }

    function addQuantityRes() {
        if (quantityRes < (maxSupply - totalSupply)) {
            setQuantityRes(quantityRes + 1)
        }
    }

    function removeQuantityRes() {
        if (quantityRes > 1) {
            setQuantityRes(quantityRes - 1)
        }
    }

    async function reserve() {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();

        const SmartContract = new ethers.Contract(contract_address, abi, signer);
        console.log("quantity :", quantityRes)
        console.log("total :", 0)
        const tx = await SmartContract.reserve(quantityRes);
        console.log(`Transaction hash: ${tx.hash}`);
        setTxLoading(true)

        const interval = setInterval(() => {
            txnCheck(tx.hash).then((response) => {
                try {
                    console.log("res : ", response.hash)
                    axios.get(api_url + "/getTotalSupply/" + projectId).then((response1) => {
                        setTotalSupply(response1.data.data.totalSupply)
                        putReserved()
                        setTxLoading(false)
                        clearInterval(interval)
                        axios.get(api_url + "/getTokenIdsOwner/" + projectId, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response7) => {
                            setTokenIds(response7.data.data)
                            axios.get(api_url + "/getMintEvents/" + projectId, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response4) => {
                                setMintData(response4.data.data)
                                console.log("mint data :", response4)
                            });
                        }); 
                    });



                } catch (error) {
                    //console.error(error)
                }
            });
            console.log("reserve on going")
        }, 1000);
    }

    async function putReserved() {

        axios.put(api_url + "/projects/" + projectId, { reservedAmount: quantityRes + reservedAmount }, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
            console.log(response.data)
            setReservedAmount(quantityRes + reservedAmount)
        });
    }

    async function getIdsOwner() {
        setTxLoading(true)
        axios.get(api_url + "/getTokenIdsOwner/" + projectId, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response7) => {
            setTokenIds(response7.data.data)
            setTxLoading(false)
        })
    }

    async function withdraw() {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();

        const SmartContract = new ethers.Contract(contract_address, abi, signer);

        const tx = await SmartContract.withdraw();
        console.log(`Transaction hash: ${tx.hash}`);
        setTxLoading(true)

        const interval = setInterval(() => {
            txnCheck(tx.hash).then((response) => {
                try {
                    console.log("res : ", response.hash)
                    axios.get(api_url + "/getBalance/" + projectId, { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response1) => {
                        setBalance(response1.data.data)
                        console.log("balance : ", response1.data.data)
                        
                        setTxLoading(false)
                        clearInterval(interval)
                    });
                } catch (error) {
                    //console.error(error)
                }
            });
            console.log("withdraw on going")
        }, 1000);
    }

    const txnCheck = async (txnHash) => {

        const provider = new ethers.providers.Web3Provider(window.ethereum)
        await provider.send("eth_requestAccounts", []);
        let txn_test = await provider.getTransaction(txnHash);
        if (txn_test) {
            if (txn_test.blockNumber) {
                console.log("txn_test :", txn_test);
                return txn_test
            }
        }

    }


    return (
        <div>
            <Box>

                <Stack sx={{ marginLeft: 3, marginBottom: 3 }} direction="row" alignItems="center" gap={1}>
                    {artwork_img === "" || artwork_img === null ?
                        <Avatar
                            size={50}
                            name={projectId}
                            square
                        />
                        :
                        <img alt="logo" width={50} height={50} src={artwork_img} />
                    }
                    <Typography style={{ color: "#000" }} variant="h4">&nbsp;{artwork_title}</Typography>
                </Stack>

                <Stack sx={{ padding: 2, marginLeft: 3, marginRight: 3, marginBottom: 0, border:0, borderRadius: 2, backgroundColor: "#E3F2FD", color: "#000" }} direction="row" alignItems="center" gap={1}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <CopyrightIcon fontSize="large" />
                        <Typography variant="h3">OWNER PANEL</Typography>
                    </Stack>
                </Stack>

                {sameNetwork ?
                    <></>
                    :
                    <Box sx={{
                        borderRadius: '5px',
                        boxShadow: 0,
                        paddingTop: 1,
                        paddingBottom: 1,
                        paddingRight: 1,
                        paddingLeft: 1,
                        margin: 3,
                        backgroundColor: '#000',
                        color: "#fff"
                        }}>
                            <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
                                <LoopIcon style={{ color: "#fff" }} />
                                <Typography style={{ color: "#fff" }} variant="button">switch network to {chain_name[network]}</Typography>
                            </Stack>
                        </Box>
                }




                <Box sx={{
                    borderRadius: '5px',
                    boxShadow: 0,
                    paddingTop: 1,
                    paddingBottom: 1,
                    paddingRight: 1,
                    paddingLeft: 1,
                    marginLeft: 3,
                    marginRight: 3,
                    marginTop: 3,
                    backgroundColor: '#F5F5F5'
                }}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <GetAppIcon style={{ color: "#000" }} />
                        <Typography style={{ color: "#000" }} variant="button">reserve mint</Typography>
                        <Tooltip title="The reserve mint allows you to mint as many nfts as you want for free (exclud. gas fees)." placement="right">
                            <InfoIcon style={{ color: "#888" }} fontSize="small" />
                        </Tooltip>
                    </Stack>

                    {txLoading ?
                        <Stack direction="row" alignItems="center" gap={1}>
                            <CircularProgress color="inherit" size={20} />
                            <Typography style={{ color: "#888" }} sx={{ fontStyle: 'italic' }} variant="button">applying changes, don't refresh your web browser</Typography>
                        </Stack>
                        :
                        <></>
                    }

                    <Grid container sx={{ marginTop: 3, marginLeft: 3, marginRight: 3, marginBottom: 3, alignItems: "center" }} direction="row" justifyContent="space-between" alignItems="flex-start" spacing={1} columns={6}>
                        <Grid sx={{ borderRight: 1, borderColor: 'grey.900' }} item md={1} xs={6}>
                            <Typography align="center">
                                <span className='stats-variables-pre'>remaining nfts</span>
                                <br />
                                <span className='stats-values-pre' >{maxSupply - totalSupply}</span>
                            </Typography>
                        </Grid>
                        <Grid sx={{ borderRight: 0, borderColor: 'grey.900' }} item md={1} xs={6}>

                            <Typography align="center">
                                <span className='stats-variables-pre'>quantity</span>
                                <br />
                                <span className='stats-values-pre' >{quantityRes}</span>
                            </Typography>

                        </Grid>
                        <Grid item md={1} xs={6}>

                            <Typography align="center">
                                <span className='stats-variables-pre'>total price</span>
                                <br />
                                <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
                                    <img alt="logo" width={20} height={20} src={chain_img[network]} />
                                    <span className='stats-values-pre' >0.00</span>
                                </Stack>
                            </Typography>

                        </Grid>
                        <Grid item md={3} xs={6}>

                            <Typography align="left">
                                <Grid container sx={{ marginTop: 0 }} justifyContent="flex-start" alignItems="center" spacing={1} columns={13}>
                                    <Grid item md={3} xs={13}>
                                        <Button disabled={!sameNetwork || txLoading} style={{ width: '100%' }} size="large" variant="contained" onClick={removeQuantityRes}>-</Button>
                                    </Grid>
                                    <Grid item md={3} xs={13}>
                                        <Button disabled={!sameNetwork || txLoading} style={{ width: '100%' }} size="large" variant="contained" onClick={addQuantityRes}>+</Button>
                                    </Grid>
                                    <Grid item md={7} xs={13}>
                                        <Button disabled={!sameNetwork || txLoading} style={{ width: '100%' }} size="large" variant="contained" onClick={reserve}>mint 🦊</Button>
                                    </Grid>
                                </Grid>
                            </Typography>

                        </Grid>
                    </Grid>
                </Box>


                <Box sx={{
                    borderRadius: '5px',
                    boxShadow: 0,
                    paddingTop: 1,
                    paddingBottom: 1,
                    paddingRight: 1,
                    paddingLeft: 1,
                    marginLeft: 3,
                    marginRight: 3,
                    marginTop: 3,
                    backgroundColor: '#F5F5F5'
                }}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <VolunteerActivismIcon style={{ color: "#000" }} />
                        <Typography style={{ color: "#000" }} variant="button">airdrop</Typography>
                        <Tooltip title="Refresh the list containing the nft ids that you own, and send them to wallets (influencers, teammates, ...)" placement="right">
                            <InfoIcon style={{ color: "#888" }} fontSize="small" />
                        </Tooltip>
                    </Stack>

                    {txLoading ?
                        <Stack direction="row" alignItems="center" gap={1}>
                            <CircularProgress color="inherit" size={20} />
                            <Typography style={{ color: "#888" }} sx={{ fontStyle: 'italic' }} variant="button">applying changes, don't refresh your web browser</Typography>
                        </Stack>
                        :
                        <></>
                    }

                    <Typography variant="subtitle2" align="left" sx={{ marginTop: 3, marginLeft: 3, marginRight: 3 }}>
                        Owner token IDs: <Button size="small" align="left" variant="text" disabled={txLoading} onClick={getIdsOwner} startIcon={<RefreshIcon />}>Refresh list</Button>
                    </Typography>

                    {tokenIds.length > 0 ?
                        <Typography align="left" sx={{ marginTop: 0, marginLeft: 3, marginRight: 3 }}>
                            {tokenIds.map(id =>
                                <Chip key={id} color="default" size="medium" label={id} />
                            )}
                        </Typography>
                        :
                        <></>
                    }
                    

                    <form onSubmit={handleSubmitGift}>
                        <Grid container sx={{ margin: 3 }} direction="row" justifyContent="space-between" alignItems="top" spacing={1} columns={14}>
                            <Grid item md={8} xs={14}>
                                <TextField disabled={!sameNetwork || txLoading || tokenIds.length === 0} value={addressToGift} onInput={e => setAddressToGift(e.target.value)} multiline size="small" fullWidth id="address" label="wallet address" helperText="format: 0x..." variant="outlined" />
                            </Grid>
                            <Grid item md={2} xs={14}>
                                <TextField disabled={!sameNetwork || txLoading || tokenIds.length === 0} value={tokenIdToGift} onInput={e => setTokenIdToGift(e.target.value)} size="small" fullWidth id="outlined-basic" label="token id" helperText="ex: 348" variant="outlined" />
                            </Grid>
                            <Grid sx={{ paddingTop: 0 }} item md={4} xs={14}>
                                <Button disabled={!sameNetwork || txLoading || tokenIds.length === 0} type="submit" size="large" fullWidth variant="contained">airdrop 🦊</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>



                <Box sx={{
                    borderRadius: '5px',
                    boxShadow: 0,
                    paddingTop: 1,
                    paddingBottom: 1,
                    paddingRight: 1,
                    paddingLeft: 1,
                    marginLeft: 3,
                    marginRight: 3,
                    marginTop: 3,
                    backgroundColor: '#F5F5F5'
                }}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <SavingsIcon style={{ color: "#000" }} />
                        <Typography style={{ color: "#000" }} variant="button">withdraw</Typography>
                        <Tooltip title="Withdraw the amount collected directly on your wallet (crmbl's share will be automatically sent to crmbl's wallet)." placement="right">
                            <InfoIcon style={{ color: "#888" }} fontSize="small" />
                        </Tooltip>
                    </Stack>

                    {txLoading ?
                        <Stack direction="row" alignItems="center" gap={1}>
                            <CircularProgress color="inherit" size={20} />
                            <Typography style={{ color: "#888" }} sx={{ fontStyle: 'italic' }} variant="button">applying changes, don't refresh your web browser</Typography>
                        </Stack>
                        :
                        <></>
                    }

                    <Grid container sx={{ marginTop: 0, marginRight: 3, marginLeft: 3, marginBottom: 3 }} justifyContent="flex-start" alignItems="center" spacing={1} columns={13}>
                        <Grid item md={3} xs={13}>
                            <Typography align="center">
                                <span className='stats-variables-pre'>total</span>
                                <br />
                                <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
                                    <img alt="logo" width={20} height={20} src={chain_img[network]} />
                                    <span className='stats-values-pre' >{balance}</span>
                                </Stack>
                            </Typography>
                        </Grid>
                        <Grid item md={3} xs={13}>
                            <Typography align="center">
                                <span className='stats-variables-pre'>crmbl's share</span>
                                <br />
                                <span className='stats-values-pre' >- {fee/1000}%</span>
                            </Typography>
                        </Grid>
                        <Grid item md={7} xs={13}>
                            <Button disabled={!sameNetwork || txLoading} style={{ width: '100%' }} size="large" variant="contained" onClick={withdraw}>withdraw 🦊</Button>
                        </Grid>
                    </Grid>
                </Box>



                




            </Box>
        </div >
    );
};

export default AdminOwner;