import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

import NotFoundCompo from '../components/NotFoundCompo';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';



import './styles/Home.css';

import { UserContext } from '../App';


function ReviewList() {

    const { api_url, account, isSigned } = React.useContext(UserContext);

    const [access, setAccess] = useState(false)
    const [listUsers, setListUsers] = useState([])


    useEffect(() => {
        console.log(api_url + "/users/" + account)

        axios.get(api_url + "/usersReviewList", { headers: { Authorization: `${localStorage.getItem('token')}` } }).then((response) => {
            console.log(response)
            if (response.data.status === "success") {
                setAccess(true)
                setListUsers(response.data.users)
            } else {
                setAccess(false)
                setListUsers([])
            }
        })

    }, [account, api_url, isSigned]);

    return (
        <div className="App-body">
            {access ?
                <>
                    <Typography sx={{ paddingTop: 15 }} variant="h4" align="left">Review list</Typography>
                    <Box container sx={{ width: "90vw", marginLeft: 3, marginRight: 3, marginTop: 0, marginBottom: 0, borderBottom: 0, borderRight: 0, borderLeft: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                        <Grid container sx={{ marginLeft: 3, marginRight: 3, marginTop: 0, marginBottom: 0, borderBottom: 0, borderRight: 0, borderLeft: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={1} columns={20}>
                            <Grid sx={{ paddingLeft: 2, borderRight: 0}} item md={8} xs={20}>
                                <Typography align="left" variant="h6"><span className="metadata">id</span></Typography>
                            </Grid>
                            <Grid sx={{paddingLeft: 2, borderRight: 0}} item md={4} xs={20}>
                                <Typography align="left" variant="h6">real name</Typography>
                            </Grid>
                            <Grid sx={{paddingLeft: 2, borderRight: 0}} item md={5} xs={20}>
                                <Typography align="left" variant="h6">email</Typography>
                            </Grid>
                            <Grid sx={{paddingLeft: 2}} item md={3} xs={20}>
                                <Typography align="left" variant="h6">lien</Typography>
                            </Grid>
                            
                        </Grid>
                    </Box>
                    <Box container sx={{ width: "90vw", marginLeft: 3, marginRight: 3, marginTop: 0, marginBottom: 2, borderBottom: 0, borderRight: 0, borderLeft: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                        {listUsers.map((element) => (
                            <Grid container sx={{ marginLeft: 3, marginRight: 3, marginTop: 0, marginBottom: 0, borderBottom: 0, borderRight: 0, borderLeft: 0 }} direction="row" justifyContent="flex-start" alignItems="center" spacing={1} columns={20}>
                                <Grid sx={{ paddingLeft: 2, borderRight: 0}} item md={8} xs={20}>
                                    <Typography align="left" variant="subtitle2"><span className="metadata">{element.id}</span></Typography>
                                </Grid>
                                <Grid sx={{paddingLeft: 2, borderRight: 0}} item md={4} xs={20}>
                                    <Typography align="left" variant="subtitle2">{element.realName}</Typography>
                                </Grid>
                                <Grid sx={{paddingLeft: 2, borderRight: 0}} item md={5} xs={20}>
                                    <Typography align="left" variant="subtitle2">{element.email}</Typography>
                                </Grid>
                                <Grid sx={{paddingLeft: 2}} item md={3} xs={20}>
                                    <Typography align="left" variant="subtitle2"><Link to={"/profile/" + element.id}>profile</Link></Typography>
                                </Grid>
                            </Grid>
                        ))}
                    </Box>
                </>

                :
                <NotFoundCompo />
            }
        </div>
    );
}

export default ReviewList;